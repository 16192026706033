import { inject, Pipe, PipeTransform } from "@angular/core";
import { GeneralStoreService } from "../stores/general-store.service";
import { filter, take } from "rxjs";
import { ErrorService } from "../services/error.service";

@Pipe({
  name: "languageIdToName",
  standalone: true
})

export class LanguageIdToNamePipe implements PipeTransform {

  private generalStore = inject(GeneralStoreService);
  private errorService = inject(ErrorService);

  constructor() {
  }

  transform(languageId: number): string {
    let result = "";
    // this.generalStore.languages$.pipe(
    //   filter(languages => languages.length > 0),
    //   take(1)
    // ).subscribe(
    //   (languages) => {
    const userLanguage = this.generalStore.languages().find(language => +language.id === +languageId);
    if (userLanguage?.name) {
      result = userLanguage.name;
    }
    // this.errorService.setXperienceMessage(new XperienceErrorMessage(true, 'dan', 'Language is not valid.'))
    return result;
  }
  //   }
  // );
}
