import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LogoPipe } from '../../pipes/logo.pipe';
import { CardForgotPasswordComponent } from '../../cards/card-forgot-password/card-forgot-password.component';
import { NgStyle } from '@angular/common';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { CardForgotPincodeComponent } from '../../cards/card-forgot-pincode/card-forgot-pincode.component';

@Component({
  selector: 'app-forgot-pincode-page',
  templateUrl: './forgot-pincode-page.component.html',
  styleUrls: ['./forgot-pincode-page.component.scss'],
  standalone: true,
  imports: [NgStyle, CardForgotPasswordComponent, LogoPipe, CardForgotPincodeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPincodePageComponent {
  private backgroundImageStore = inject(BackgroundImageStoreService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage('background-192tv.png');
  }
}
