<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }">
    <div class="form-label flex-layout">
      <label
        for="{{ id }}"
        [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
        >{{ label }}</label
      >
      <ng-content></ng-content>
    </div>
    <input
      id="{{ id }}"
      type="password"
      type="{{ showPassword ? 'text' : 'password' }}"
      [formControl]="control | getAsFormControl"
      [attr.placeholder]="showPlaceholder ? placeholder + '&#8230;' : null" />
    <button
      type="button"
      tabindex="-1"
      class="is-transparent password-toggle"
      (click)="showPassword = !showPassword">
      <span [ngClass]="showPassword ? 'mci-eye-close-solid' : 'mci-eye-open-solid'"></span>
    </button>
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }">
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{ getErrorMessage() }}</p>
  </div>
</ng-container>
