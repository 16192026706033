import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { FormControlComponent } from "../form-control.component";
import { GetFormControlPipe } from "../../../pipes/get-form-control.pipe";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";

@Component({
  selector: "shared-form-email",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe
  ],
  templateUrl: "./form-email.component.html",
  styleUrls: ["./form-email.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEmailComponent extends FormControlComponent implements OnInit {

  // private validatorEmail: ValidatorFn = Validators.email;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.control.addValidators([
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]);
    // this.additionalValidators.push(this.validatorEmail);
  }

  override getErrorMessage(): string {
    if (this.control.hasError("email")) {
      return "Email format is invalid.";
    }
    if (this.control.hasError("pattern")) {
      return "Email format is invalid.";
    }
    return super.getErrorMessage();
  }
}
