import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { SubscriptionInterface } from '../../models/subscription.model';
import { CrwStoreService } from '../../stores/crw-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { RouterLink } from '@angular/router';
import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { BeautifyDatePipe } from '../../pipes/beautify-date.pipe';

interface CardSubscriptionsData {
  crwFlowSection: CrwFlowSection;
  subscriptions: SubscriptionInterface[];
  userSubscriptions: SubscriptionInterface[];
  validSubscriptions: SubscriptionInterface[];
  invalidSubscriptions: SubscriptionInterface[];
  currentSubscription: SubscriptionInterface | null;
}

@Component({
  selector: 'app-card-subscription',
  templateUrl: './card-subscription.component.html',
  styleUrls: ['./card-subscription.component.scss'],
  standalone: true,
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgIf, RouterLink, NgClass, AsyncPipe, ReactiveFormsModule, SubmitComponent, ErrorMessagesComponent, BeautifyDatePipe],
})
export class CardSubscriptionComponent implements OnInit {
  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private crwStore = inject(CrwStoreService);

  cardSubscriptionsData$: Observable<CardSubscriptionsData>;

  constructor() {}

  ngOnInit(): void {
    this.cardSubscriptionsData$ = combineLatest([
      this.crwStore.crwSection$,
      this.generalStore.subscriptions$,
      this.userAccountStore.userAccountSubscriptions$,
      this.userAccountStore.validSubscriptions$,
      this.userAccountStore.invalidSubscriptions$,
      this.userAccountStore.currentSubscription$,
    ]).pipe(
      map(([crwFlowSection, subscriptions, userSubscriptions, validSubscriptions, invalidSubscriptions, currentSubscription]) => {
        return {
          crwFlowSection,
          subscriptions,
          userSubscriptions,
          validSubscriptions,
          invalidSubscriptions,
          currentSubscription,
        };
      }),
    );
  }
}
