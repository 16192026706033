import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateEpgitemIsPast',
  standalone: true,
})
export class DateEpgitemIsPastPipe implements PipeTransform {
  transform(startDate: string, ...args: unknown[]): boolean {
    const now = new Date();
    const startDateObject: Date = new Date(startDate);
    // console.log(now.getTime(), startDateObject.getTime(), startDateObject.getTime() <= now.getTime());
    return startDateObject.getTime() <= now.getTime();
  }
}
