import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { ProfilesStoreService } from "../../stores/profiles-store.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { AccountInterface } from "../../models/account.model";
import { CardEmailComponent } from "../../cards/card-email/card-email.component";
import { CardPasswordComponent } from "../../cards/card-password/card-password.component";
import { CardPairingComponent } from "../../cards/card-pairing/card-pairing.component";
import { CardAccountComponent } from "../../cards/card-account/card-account.component";
import { SideMenuComponent } from "../../components/side-menu/side-menu.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { CardAccountDeleteComponent } from "../../cards/card-account-delete/card-account-delete.component";

interface AccountData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
}

@Component({
  selector: "app-account-page",
  templateUrl: "./account-page.component.html",
  styleUrls: ["./account-page.component.scss"],
  standalone: true,
  imports: [
    NgIf, SideMenuComponent, CardAccountComponent, CardPairingComponent, CardPasswordComponent, CardEmailComponent,
    AsyncPipe,
    CardAccountDeleteComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AccountPageComponent implements OnInit {

  accountData$: Observable<AccountData>;

  showDeleteAccountCard: WritableSignal<boolean> = signal(false);

  private profilesStore = inject(ProfilesStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  constructor() {
  }

  ngOnInit(): void {
    this.accountData$ = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([isLoggedIn, userAccount]) => {
          return {
            isLoggedIn, userAccount
          };
        }
      )
    );
  }
  showDeleteCard(e: boolean): void {
    this.showDeleteAccountCard.set(e)
  }
}
