import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-username',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetAsFormControlPipe],
  templateUrl: './form-username.component.html',
  styleUrls: ['./form-username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormUsernameComponent extends FormControlComponent implements OnInit {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.shouldValidate) {
      this.control.addValidators([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9@_.-]+$/i),
        RxwebValidators.compose({
          validators: [RxwebValidators.minLength({ value: 5 }), RxwebValidators.maxLength({ value: 24 })],
        }),
      ]);
    } else {
      this.control.addValidators([Validators.required]);
    }
  }

  override getErrorMessage(): string {
    if (this.control.hasError('pattern')) {
      return 'A username can only contain alpha-numeric characters and _, ., and -.';
    }
    if (this.control.hasError('minLength')) {
      return 'A username should contain at least 5 characters.';
    }
    if (this.control.hasError('maxLength')) {
      return 'A username should contain less than 24 characters.';
    }
    return super.getErrorMessage();
  }
}
