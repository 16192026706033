import { Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { UserAccountService } from "../../services/user-account.service";
import { NgClass, NgIf } from "@angular/common";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { ApplicationEvent, ApplicationService } from "../../services/application.service";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { FormPasswordComponent } from "../../components/forms/components/control/form-password/form-password.component";
import {
  FormPasswordConfirmComponent
} from "../../components/forms/components/control/form-password-confirm/form-password-confirm.component";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { take } from "rxjs";
import { Q90Response, Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";

@Component({
  selector: "app-card-password",
  templateUrl: "./card-password.component.html",
  styleUrls: ["./card-password.component.scss"],
  standalone: true,
  imports: [
    NgIf, NgClass, ReactiveFormsModule, ErrorMessagesComponent, SubmitComponent, FormPasswordComponent,
    FormPasswordConfirmComponent, GetFormControlPipe
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ]
})

export class CardPasswordComponent extends FormComponent implements OnInit {

  editMode: WritableSignal<boolean> = signal(false);

  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);

  override formGroup = this.formBuilder.group({
    passwordChange: this.formBuilder.group({
      password: this.formBuilder.control<string>(""),
      passwordConfirm: this.formBuilder.control<string>("")
    })
  });

  constructor() {
    super();
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const passwordChangeControls = this.formGroup.get("passwordChange") as FormGroup;
    if (this.submittable(passwordChangeControls)) {
      this.submitting.set(true);
      this.userAccountService.changePassword(passwordChangeControls.value.password).pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.applicationStore.setApplicationEvent(ApplicationEvent.UserAccountUpdate);
            this.setFormMessage(this.translations.getTranslation(this.translations.FORM_CHANGES_SAVE_SUCCESS), Q90ResponseLevels.Success);
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
        complete: () => {
          this.formGroup.markAsPristine();
        }
      });
    }
  }
}
