import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { ProfilesService } from "../../services/profiles.service";
import { UserAccountService } from "../../services/user-account.service";
import { combineLatest, map, Observable, take } from "rxjs";
import { GeneralStoreService } from "../../stores/general-store.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { AuthenticationStoreService } from "../../stores/authentication-store.service";
import { OptionCollection, toOptionCollection } from "../../shared/interfaces/option-collection";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { AutocompleteOffDirective } from "../../components/forms/directives/autocomplete-off.directive";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { FormAvatarComponent } from "../../components/forms/components/control/form-avatar/form-avatar.component";
import { FormCheckboxComponent } from "../../components/forms/components/control/form-checkbox/form-checkbox.component";
import { FormInputComponent } from "../../components/forms/components/control/form-input/form-input.component";
import { FormPincodeComponent } from "../../components/forms/components/control/form-pincode/form-pincode.component";
import { FormSelectComponent } from "../../components/forms/components/control/form-select/form-select.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { XperienceResponse } from "../../models/xperience-response";
import { Q90ResponseLevels } from "../../models/q90-response";
import { ProfilesStoreService } from "../../stores/profiles-store.service";
import {
  FormInputHiddenComponent
} from "../../components/forms/components/control/form-input-hidden/form-input-hidden.component";

@Component({
  selector: "app-card-create-profile",
  templateUrl: "./card-create-profile.component.html",
  styleUrls: ["./card-create-profile.component.scss"],
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, RouterLink, AsyncPipe, AutocompleteOffDirective, ErrorMessagesComponent,
    FormAvatarComponent, FormCheckboxComponent, FormInputComponent, FormPincodeComponent, FormSelectComponent,
    FormsModule, GetFormControlPipe, ReactiveFormsModule, SubmitComponent, FormInputHiddenComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardCreateProfileComponent extends FormComponent implements OnInit {

  public avatar!: File;
  public avatarUrl: string | null = null;

  private generalStore = inject(GeneralStoreService);
  private profilesService = inject(ProfilesService);
  private router = inject(Router);

  languages: OptionCollection[] = [];

  override formGroup = this.formBuilder.group({
    profile: this.formBuilder.group({
      props: this.formBuilder.group(
        {
          profileName: this.formBuilder.control<string>(""),
          // languageId: this.formBuilder.control<number | null>(null),
          kidsMode: this.formBuilder.control<boolean>(false),
          accessCode: this.formBuilder.control<number | null>(null),
          hasAccessCode: this.formBuilder.control<boolean>(false)
        }
      )
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.languages = toOptionCollection(this.generalStore.languages(), "name", "id");
  }

  toProfiles(e: Event): void {
    this.router.navigate([`profiles`]).then(
      (result) => {
        return false;
      }
    ).catch(error => {
      this.errorService.setError(new Error("Unable to view profiles"));
    });
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const profilePropsControls = this.formGroup.get("profile.props") as FormGroup;
    const accessCodeControl = this.formGroup.get("profile.props.accessCode") as FormControl;
    if (this.submittable(profilePropsControls)) {
      this.submitting.set(true);

      // Probably need a separate checkbox to disable accessCode use for a profile...
      if (!accessCodeControl.pristine) {
        // this.formGroup.get("profile.props.isAccessCodeChanged").setValue(true);
        if (accessCodeControl.value) {
          this.formGroup.get("profile.props.hasAccessCode").setValue(true);
        }
      }
      this.profilesService.createProfile(profilePropsControls.value).pipe(
        take(1)
      ).subscribe({
        next: (response) => {
          if (XperienceResponse.isSuccess(response)) {
            this.profilesService.fetchProfileData();
            this.router.navigate([`profiles`]);
          } else {
            const error = new XperienceResponse().deserialize(response);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
        error: (err) => {
          this.submitting.set(false);
          this.setErrorMessage(err);
        },
        complete: () => {
          this.submitting.set(false);
        }
      });
    }
  }
}
