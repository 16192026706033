import {AuthenticationStoreService} from "../stores/authentication-store.service";
import {inject} from "@angular/core";
import {ApplicationService} from "../services/application.service";
import {environment} from "../../environments/environment";

export function isTimeToRefreshToken(): boolean {
  const applicationService = inject(ApplicationService);
  const authenticationStore = inject(AuthenticationStoreService);
  // appService.debug("Token: checking if time to refresh...");
  let result = false;
  const d = new Date();
  // Create date limit margin X minutes before token expiry.
  // Get token expiry from user object in storage, not local (browser) storage.
  const tokenExpiry = authenticationStore.getTokenExpiry();
  if (tokenExpiry) {
    const limit = new Date(tokenExpiry);

    if (d.getTime() > limit.getTime()) {
      applicationService.debug("Time to refresh...");
      result = true;
    }
  }
  return result;
}
