<ng-container *ngIf="faqPageData$ | async as faqPageData">
  <div class="grid grid-cols-6 gap-x-vertical-base grow">
    <section class="col-span-6 md:col-span-1">
      <app-side-menu-help />
    </section>
    <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
      <section class="col-span-6">
        <h2 i18n>Veel Gestelde Vragen</h2>
        <div class="card">
          @if (faqPageData.faqs.length) {
            @for (item of faqPageData.faqs; track item.id) {
              <div
                (click)="setActiveFaq($event, item)"
                id="faq-{{ item.id }}"
                class="faq-entry"
                [ngClass]="{ 'is-active': this.activeFaq()?.id === item.id }">
                <div class="faq-question grid grid-cols-2">
                  <h4 class="my-0">{{ item.question }}</h4>
                  <p class="my-0">
                    <span [ngClass]="{ 'mci-arrowhead-up': this.activeFaq()?.id === item.id }"></span>
                    <span [ngClass]="{ 'mci-arrowhead-down': this.activeFaq()?.id !== item.id }"></span>
                  </p>
                </div>
                <div class="answer-wrapper">
                  <div class="faq-answer">
                    <p
                      class="mb-0"
                      [innerHTML]="item.answer"></p>
                    <div
                      class="flex gap-x-std-half"
                      *ngIf="item.categories.length">
                      @for (cat of item.categories; track cat.id) {
                        <p class="category">{{ cat.category }}</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="grid place-items-center">
              <shared-spinner />
            </div>
          }
        </div>
      </section>
    </div>
  </div>
</ng-container>
