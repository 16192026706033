import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { filter, iif, of, switchMap, tap } from "rxjs";
import { ProfilesStoreService } from "../stores/profiles-store.service";
import { ApplicationService } from "../services/application.service";
import { UserAccountStoreService } from "../stores/user-account-store.service";

/**
 * Guard specifically for TV or home page.
 *
 * Checks for one of the following:
 * 1) No user is logged-in (for the "take a tour" option)
 * 2) A user us logged-in AND has also successfully logged in with a user account profile
 *
 * @param route
 * @param state
 */
export const pageTvGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const userAccountStore = inject(UserAccountStoreService);
  const profilesStore = inject(ProfilesStoreService);
  const applicationService = inject(ApplicationService);
  return userAccountStore.isLoggedIn$.pipe(
    tap((profile) => applicationService.debug(`${route} using guard pageTvGuard`)),
    switchMap((isLoggedIn) =>
      iif(
        () => !isLoggedIn,
        of(true),
        profilesStore.userActiveProfile$.pipe(
          switchMap(
            (activeProfile) => iif(
              () => activeProfile === null,
              of(router.createUrlTree(["/profiles"])),
              of(true)
            ))
        )
      )
    )
  );
};
