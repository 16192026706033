import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { UserAccountService } from "../../services/user-account.service";
import { NgClass } from "@angular/common";
import { RouterLink } from "@angular/router";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { AutocompleteOffDirective } from "../../components/forms/directives/autocomplete-off.directive";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { FormEmailComponent } from "../../components/forms/components/control/form-email/form-email.component";
import {
  FormEmailConfirmComponent
} from "../../components/forms/components/control/form-email-confirm/form-email-confirm.component";
import { FormPasswordComponent } from "../../components/forms/components/control/form-password/form-password.component";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { take } from "rxjs";
import { Q90Response, Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";

@Component({
  selector: "app-card-forgot-password",
  templateUrl: "./card-forgot-password.component.html",
  styleUrls: ["./card-forgot-password.component.scss"],
  standalone: true,
  imports: [
    RouterLink, NgClass, AutocompleteOffDirective, ErrorMessagesComponent, FormEmailComponent,
    FormEmailConfirmComponent, FormPasswordComponent, GetFormControlPipe, ReactiveFormsModule, ReactiveFormsModule,
    SubmitComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardForgotPasswordComponent extends FormComponent implements OnInit {

  private userAccountService = inject(UserAccountService);

  override formGroup = this.formBuilder.group({
    forgotPassword: this.formBuilder.group({
      emailAddress: this.formBuilder.control<string>("", [Validators.required])
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const forgotPasswordControls = this.formGroup.get("forgotPassword") as FormGroup;
    if (this.submittable(forgotPasswordControls)) {
      this.submitting.set(true);
      this.userAccountService.requestPasswordReset(forgotPasswordControls.value.emailAddress)
        .pipe(
          take(1)
        ).subscribe(res => {
          this.submitting.set(false);
          // if (XperienceResponse.isSuccess(res)) {
            this.setFormMessage(this.translations.getTranslation(this.translations.FORM_PASSWORD_RESET_SENT), Q90ResponseLevels.Success);
          // } else {
          //   const error = new XperienceResponse().deserialize(res);
          //   error.setLevel(Q90ResponseLevels.Danger);
          //   this.errorMessagesStore.setErrorMessage(error);
          // }
        });
    }
  }
}
