<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="data && data.message">
    <div
      *ngIf="showClose"
      class="{{ styleClass }} error-message {{ level() }} grid-xp grid-cols-2 left-heavy align-center">
      <p>{{ data.message.message }}</p>
      <p
        class="show-pointer"
        (click)="closeMessage($event)">
        <span class="mci-close"></span>
      </p>
    </div>
    <div
      *ngIf="!showClose"
      class="{{ styleClass }} error-message {{ level() }} grid-xp grid-cols-1 align-center">
      <p>{{ data.message.message }}</p>
    </div>
  </ng-container>
</ng-container>
