import { NgClass, AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { CardForgotPasswordComponent } from '../../cards/card-forgot-password/card-forgot-password.component';
import { LoaderComponent } from '../../components/loader/loader.component';
import { LogoComponent } from '../../components/logo/logo.component';
import { LogoPipe } from '../../pipes/logo.pipe';
import { ApplicationService } from '../../services/application.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ErrorService } from '../../services/error.service';
import { GeneralService } from '../../services/general.service';
import { LiveTimeService } from '../../services/live-time.service';
import { ProfilesService } from '../../services/profiles.service';
import { UserAccountService } from '../../services/user-account.service';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { TopbarComponent } from '../../components/topbar/topbar.component';

interface AppComponentData {
  darkMode: boolean;
  isLoggedIn: boolean;
  backgroundImage: string;
}

@Component({
  selector: 'app-layout-topbar-with-background',
  standalone: true,
  imports: [
    TopbarComponent,
    LoaderComponent,
    NgClass,
    AsyncPipe,
    RouterOutlet,
    NgIf,
    CardForgotPasswordComponent,
    LogoPipe,
    NgStyle,
    LogoComponent
  ],
  providers: [
    { provide: BackgroundImageStoreService }
  ],
  templateUrl: './layout-topbar-with-background.component.html',
  styleUrl: './layout-topbar-with-background.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LayoutTopbarWithBackgroundComponent implements OnInit {

  appComponentData$!: Observable<AppComponentData>;
  public router = inject(Router);

  private backgroundImageStore = inject(BackgroundImageStoreService);
  private authenticationService = inject(AuthenticationService);
  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private generalService = inject(GeneralService);
  private generalStore = inject(GeneralStoreService);
  private liveTimeService = inject(LiveTimeService);
  private profilesService = inject(ProfilesService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);

  constructor() {
    const bgImg = new Image();
    bgImg.src = "assets/images/background-192tv.png";
  }

  ngOnInit(): void {

    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
    this.appComponentData$ = combineLatest([
      this.generalStore.darkMode$,
      this.userAccountStore.isLoggedIn$,
      this.backgroundImageStore.backgroundImage$,
    ]).pipe(
      map(
        ([
          darkMode,
          isLoggedIn,
          backgroundImage,
        ]) => {
          return {
            darkMode,
            isLoggedIn,
            backgroundImage,
          };
        }
      )
    );
  }

}
