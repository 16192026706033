import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription, filter, takeUntil, Observable, forkJoin, map, catchError, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { Q90ResponseData } from '../interfaces/q90-response';
import { AccountInterface } from '../models/account.model';
import { SubscriptionInterface } from '../models/subscription.model';
import { XperienceResponse } from '../models/xperience-response';
import { ApplicationEvent } from './application.service';
import { PrimeableService } from './primeable.service';
import { FrontendCookieStorageService } from './storage/frontend-cookie-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService extends PrimeableService implements OnDestroy {
  protected eventSubscription!: Subscription;

  constructor() {
    super();
    this.eventSubscription = this.applicationStore.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed),
      )
      .subscribe((setting) => {
        if (setting === ApplicationEvent.UserAccountUpdate) {
          this.fetchUserAccountData();
        }
      });
  }

  override ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  protected prime(): void { }

  protected authenticated(): void {
    // Again, this is because of the language switching that's needed.
    // Once a user is authenticated and tokens are set, get the user account
    // and the languageId and switch the language, or reload the application
    this.getUserAccount()
      .pipe(take(1))
      .subscribe({
        next: (userAccount) => {
          if (XperienceResponse.isSuccess(userAccount)) {
            const userLanguage = this.generalStore.getLanguageById(userAccount.data.languageId);
            this.applicationService.switchLanguage(userLanguage);
          }
        },
      });
  }

  protected refresh(): void {
    // this.fetchUserAccountData();
  }

  protected logout(): void {
    this.applicationService.switchLanguage(null);
  }

  private fetchUserAccountData() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<Q90ResponseData<AccountInterface> | Q90ResponseData<SubscriptionInterface[]> | Q90ResponseData<boolean>>[] = [];
    observablesArray.push(this.getUserAccount());
    observablesArray.push(this.getUserSubscriptions());
    // observablesArray.push(this.isLoggedIn());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);

        const accountData = response[0].data as AccountInterface;
        this.userAccountStore.setUserAccount(accountData);

        const subscriptionsData = response[1].data as SubscriptionInterface[];
        this.userAccountStore.setUserAccountSubscriptions(subscriptionsData);

        // const isLoggedInData = response[2].data as boolean;
        // this.userAccountStore.setIsLoggedIn(isLoggedInData);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  register(account: Partial<AccountInterface>): Observable<Q90ResponseData<AccountInterface>> {
    return this.http
      .post<Q90ResponseData<AccountInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/register`,
        {
          ...account,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  deleteAccount(): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getUserAccount(): Observable<Q90ResponseData<AccountInterface>> {
    return this.http
      .get<Q90ResponseData<AccountInterface>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (XperienceResponse.isSuccess(res)) {
            const userLanguage = this.generalStore.languages().find((lang) => lang.id === res.data.languageId);
            if (userLanguage) {
              this.userAccountStore.userAccountLanguage.set(userLanguage);
            }
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getUserSubscriptions(): Observable<Q90ResponseData<SubscriptionInterface[]>> {
    return this.http
      .get<Q90ResponseData<SubscriptionInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/subscriptions`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  updateAccount(userAccount: Partial<AccountInterface>): Observable<any> {
    // Update appears to fail on backend without accountSettings set...
    userAccount.accountSettings = [];
    return this.http
      .put<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/update`,
        {
          ...userAccount,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  changeEmail(email: string, password: string): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/update/email`,
        {
          email: email,
          password: password,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  activateAccount(activationKey: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/activation/activate`,
        {
          activationKey: activationKey,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          // if (!XperienceResponse.isSuccess(res)) {
          //   this.errorService.setError(new XperienceResponse().deserialize(res));
          // }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  resendActivationKey(username: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/activation/key/resend`,
        {
          username: username,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  setupTfa(): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/authentication/twofactor/setup`,
        {},
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  finishSetupTfa(pinCode: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/authentication/twofactor/setup/finish`,
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  disableTfa(pinCode: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/authentication/twofactor/disable`,
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  regenerateTfaRecoveryCodes(pinCode: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/authentication/twofactor/recoverycodes/regenerate`,
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/password/reset/request`,
        {
          email: email,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          // if (!XperienceResponse.isSuccess(res)) {
          //   this.errorService.setError(new XperienceResponse().deserialize(res));
          // }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  resetPassword(resetKey: string, newPassword: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/password/reset/complete`,
        {
          resetKey: resetKey,
          newPassword: newPassword,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  changePassword(newPassword: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/password/change`,
        {
          newPassword: newPassword,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  isLoggedIn(): Observable<Q90ResponseData<boolean>> {
    return this.http
      .get<Q90ResponseData<boolean>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/isloggedin`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          this.userAccountStore.isLoggedIn.set(res.data);
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  pairDevice(token: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/pairingcode`,
        {
          token: token,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
