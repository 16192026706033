import { Injectable } from '@angular/core';
import { FrontendStorageService } from './frontend-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FrontendCookieStorageService extends FrontendStorageService {

  constructor() {
    super();
  }

  setItem(name: string, setting: string, isExpiresValue = false): void {
    // HttpOnly flag ???
    // document.cookie = `${name}=${setting}; path=/; HttpOnly;`;
    document.cookie = `${name}=${((isExpiresValue) ? this.toUTCString(setting) : setting)}; path=/;`;
  }

  getItem(name: string): string | null {
    const result = this.getCookieValue(name);
    return ((result) ? result : null);
  }

  removeItem(name: string): void {
    const result = this.getCookie(name);
    if (result) {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    }
  }

  clear(): void {
    localStorage.clear();
  }

  private toUTCString(date: string) {
    return new Date(date).toUTCString()
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  private getCookie(name: string): string | undefined {
    const decoded = decodeURIComponent(document.cookie);
    return decoded
      .split("; ")
      .find((row) => row.startsWith((name + "=")));
  }

  private getCookieValue(name: string): string | undefined {
    // Bit tricky with the splits, refreshToken tends to end in an "=" character...
    const decoded = decodeURIComponent(document.cookie);
    return decoded
      .split("; ")
      .find((row) => row.startsWith((name + "=")))
      ?.split((name + "="))[1];
  }
}
