import { Component, EventEmitter, inject, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { EpgService } from '../../services/epg.service';
import { DateBetweenDatesPipe } from '../../pipes/date-between-dates.pipe';
import { BeautifyDatePipe } from '../../pipes/beautify-date.pipe';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { combineLatest, map, Observable, take } from 'rxjs';
import { LiveTimeStoreService } from '../../stores/live-time-store.service';
import { DateEpgitemIsPastPipe } from '../../pipes/date-epgitem-is-past.pipe';
import { DateEpgitemIsFuturePipe } from '../../pipes/date-epgitem-is-future.pipe';
import { DateEpgitemIsLivePipe } from '../../pipes/date-epgitem-is-live.pipe';
import { XperienceResponse } from '../../models/xperience-response';
import { ErrorService } from '../../services/error.service';
import { Q90ResponseLevels } from '../../models/q90-response';

interface ItemCardData {
  liveTimeSeconds: number;
  liveDate: Date;
}

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle, RouterLink, NgClass, NgFor, DatePipe, BeautifyDatePipe, DateBetweenDatesPipe, AsyncPipe, DateEpgitemIsPastPipe, DateEpgitemIsFuturePipe, DateEpgitemIsLivePipe, RouterLink],
})
export class ItemCardComponent implements OnInit {
  private epgService = inject(EpgService);
  private errorService = inject(ErrorService);
  private liveTimeStore = inject(LiveTimeStoreService);

  @Input() channelId: number;
  @Input() itemId: number;
  @Input() itemStartDate: string;
  @Output() callbackActive = new EventEmitter<boolean>();

  itemCardData$: Observable<ItemCardData>;
  itemLoading: Boolean = false;
  epgLoading: Boolean = false;

  constructor() {
    this.startDate = new Date();
    this.startDate.setMinutes(0);
    this.startDate.setSeconds(0);
    this.startDateParam = this.epgService.getAsIsoString(this.startDate, false);
  }

  public startDate: Date;
  public startDateParam: string;
  item = null;
  broadcastsPrevious = true;
  channelStreamId: number = 0;

  ngOnInit() {
    this.itemLoading = true;
    this.epgLoading = true;
    if (this.itemStartDate) {
      const paramStartDate = new Date(this.itemStartDate);
      const dateInRange = this.epgService.checkDateInCurrentDateRange(paramStartDate);

      if (dateInRange !== -1) {
        this.startDate = paramStartDate;
        this.startDateParam = this.epgService.getAsIsoString(this.startDate, false);
      }
    }
    this.getItem();
    this.getEpg();

    this.itemCardData$ = combineLatest([this.liveTimeStore.liveTimeSeconds$, this.liveTimeStore.liveDate$]).pipe(
      map(([liveTimeSeconds, liveDate]) => {
        return {
          liveTimeSeconds,
          liveDate,
        };
      }),
    );
  }

  private getItem() {
    this.epgService
      .getEpgItem(this.itemId, this.startDateParam)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.item = res.data;
            this.itemLoading = false;
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorService.setError(error);
          }
        },
        error: (error) => {
          this.errorService.setError(error);
        },
      });
  }

  getEpg() {
    this.epgService
      .getChannelEpg(this.channelId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.channelStreamId = res.data.channelId;
            this.epgLoading = false;
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorService.setError(error);
          }
        },
        error: (error) => {
          this.errorService.setError(error);
        },
      });
  }

  cb() {
    this.callbackActive.emit(true);
  }
}
