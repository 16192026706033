import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { SpinnerComponent } from "../spinner/spinner.component";


@Component({
  selector: "core-avatar",
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: "./avatar.component.html",
  styleUrl: "./avatar.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() avatarSrc: string | null = null;
  @Input() size: "sm" | "md" | "lg" = "sm";
  @Input() negotiateFormat: boolean = true;
  @Input() withLink: boolean = true;
  @Input() loading: boolean = false;

  public router = inject(Router);
  public userAccountStore = inject(UserAccountStoreService);

  spinnerClass!: string;

  constructor() {
  }

  ngOnInit(): void {
    this.spinnerClass = "spinner-white-" + this.size;
  }
}
