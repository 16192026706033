<div class="card orange-bg text-center">
  <h3 i18n>Oeps.</h3>
  <p i18n>Er lijkt een fout te zijn opgetreden.</p>
  <ng-container *ngIf="errorData$ | async as errorData">
    @if (errorData.error) {
      <p class="mt-0">
        <strong>
          {{ errorData.error!.message }}
        </strong>
      </p>
    } @else {
      <p
        i18n
        class="mt-0">
        Onbekende fout.
      </p>
    }
  </ng-container>
  <ng-template #unknownError>
    <p i18n>Onbekende fout.</p>
  </ng-template>
  <h4>
    <a
      i18n
      [routerLink]="'/tv'"
      >Terug naar Xperience</a
    >
  </h4>
</div>
