import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appIntersectionObserver]",
  standalone: true
})
export class IntersectionObserverDirective implements OnInit {

  @Input() rootMargin: string = "0px";
  @Input() threshold: number = 0;

  options: Record<string, any>;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.options = {
      root: this.el.nativeElement,
      rootMargin: this.rootMargin,
      threshold: this.threshold
    };

  }
}
