import { Injectable } from '@angular/core';
import { SubscriptionInterface } from '../models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionStoreService {

  constructor() { }

  getValidSubscriptions(subscriptions: SubscriptionInterface[]): SubscriptionInterface[] {
    const now = new Date();
    const result = subscriptions.filter(subscription => this.subscriptionIsValid(subscription))
    return result;
  }

  getInvalidSubscriptions(subscriptions: SubscriptionInterface[]): SubscriptionInterface[] {
    const now = new Date();
    const result = subscriptions.filter(subscription => this.subscriptionIsInvalid(subscription))
    return result;
  }

  subscriptionIsValid(subscription: SubscriptionInterface): boolean {
    const now = new Date();
    const start = new Date(subscription.startDate);
    const end = new Date(subscription.endDate);
    return (start.getTime() <= now.getTime() && now.getTime() < end.getTime());
  }

  subscriptionIsInvalid(subscription: SubscriptionInterface): boolean {
    const now = new Date();
    const start = new Date(subscription.startDate);
    const end = new Date(subscription.endDate);
    return (end.getTime() < now.getTime() || start.getTime() > now.getTime());
  }

  getCurrentSubscription(subscriptions: SubscriptionInterface[]): SubscriptionInterface | null {
    const validSubscriptions = this.getValidSubscriptions(subscriptions);
    if (validSubscriptions.length > 0) {
      return validSubscriptions[0]
    } else {
      return null;
    }
  }
}
