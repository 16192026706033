<ng-container *ngIf="cardSubscriptionsData$ | async as cardSubscriptionsData">
  <div class="card">
    <header>
      <h3 i18n>Mijn abonnement</h3>
    </header>

    <ng-container *ngIf="cardSubscriptionsData.validSubscriptions.length < 1">
      <p
        i18n
        class="subtitle">
        U heeft nog geen abonnementen.
      </p>
      <div class="button-bar align-left">
        <button
          i18n
          class="is-colored"
          routerLink="/crw/subscription">
          Bekijk abonnementen
        </button>
      </div>
    </ng-container>

    <section
      class="sub"
      *ngFor="let sub of cardSubscriptionsData.validSubscriptions">
      <ng-container *ngIf="sub">
        <div class="details">
          <h5>
            {{ sub.title }}
          </h5>
          <div class="detail">
            <p i18n>Start datum: {{ sub.startDate | beautifyDate }} | Eind datum: {{ sub.endDate | beautifyDate }}</p>
          </div>
          <div class="detail">
            <h5 i18n>Prijs:</h5>
            <p *ngIf="sub.costs">
              € {{ sub.costs }} /
              {{ sub.periodUnit }}
            </p>
            <p
              *ngIf="!sub.costs"
              i18n>
              Gratis
            </p>
          </div>
          <div class="detail">
            <h5 i18n>automatisch</h5>
            <p>
              {{ sub.isRunning ? "yes" : "no" }}
            </p>
          </div>

          <div class="button-bar align-left">
            <button
              i18n
              routerLink="/crw/subscription"
              class="is-colored">
              Wijzig uw abonnement
            </button>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>
