import { inject, Injectable } from '@angular/core';
import { combineLatest, debounceTime, distinctUntilChanged, filter, map, Observable, pairwise, take, takeUntil } from 'rxjs';
import { DestroyService } from './destroy.service';
import { environment } from '../../environments/environment';
import { ApplicationStoreService } from '../stores/application-store.service';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageInterface } from '../models/language.model';
import { FrontendCookieStorageService } from './storage/frontend-cookie-storage.service';
import { GeneralStoreService } from '../stores/general-store.service';
import { Location } from '@angular/common';

export enum ApplicationEvent {
  Prime = "PRIME",
  Login = "LOGIN",
  Logout = "LOGOUT",
  RefreshToken = "REFRESH_TOKEN",
  UserAccountUpdate = "USER_ACCOUNT_UPDATE",
  Idle = "IDLE",
}

export enum LocalStorageKeys {
  CookieDisclaimer = 'cookieDisclaimer',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private destroyed = inject(DestroyService);
  private router = inject(Router);
  private applicationStore = inject(ApplicationStoreService);
  private generalStore = inject(GeneralStoreService);
  private cookieStorageService = inject(FrontendCookieStorageService);

  constructor(private location: Location) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(
      (routerEvent) => {
        var navEnd = (routerEvent as NavigationEnd);

        // Blacklist
        if (
          !navEnd.url.startsWith('/login')
          && !navEnd.url.startsWith('/profiles')
          && !navEnd.url.startsWith('/logout')
          && !navEnd.url.startsWith('/crw')
        ) {
          this.applicationStore.setLastUrl(navEnd.url);
        }

      });
  }

  /**
   * Tests an array of boolean Observables for at least one true value.
   *
   * Method is named getAppLoadingStatus because it combines two observables from
   * this service which broadcast
   * 1) when token is being refreshed and
   * 2) when a PrimeableService is priming.
   *
   * You can add other boolean based Observables to array to be tested.
   *
   * @param obs Observable<boolean>[]
   * @returns Observable<boolean>
   */
  getAppLoadingStatus(obs: Observable<boolean>[] = []): Observable<boolean> {
    return combineLatest([this.applicationStore.primeablePriming$, this.applicationStore.refreshingToken$, ...obs]).pipe(
      distinctUntilChanged(),
      debounceTime(100),
      map((values) => {
        const test = values.find((v) => v === true);
        const result = !!test;
        return result;
      }),
    );
  }

  /**
   * Doing this pairwise since the last url is the page you are on.
   * We want the one before it. Observable emits pairwise, the last value
   * emitted and the one before it. The one before, the second value in the pair,
   * is the previous page.
   * 
   * The ReplaySubject used here is actually "primed" in constructor of ApplicationStore.
   * Why? Seemed the easiest way to get desired behavior: that the cancel button on CRW
   * pages returns to last page before entering CRW pages area. If you enter end up in
   * CRW section with no values buffered in ReplaySubject, the cancel button will not
   * react since the observable emits nothing. Experimented with isEmpty, defaultIfEmpty,
   * startWith RxJS operators, with no success. Priming with two values seems to work.
   * 
   * @param getPreviousUrl 
   */
  returnToPreviousPage(): void {
    this.applicationStore.previousUrl$.pipe(
      pairwise(),
      take(1),
    ).subscribe(
      (lastTwoUrls) => {
        this.debug(lastTwoUrls);
        this.router.navigate([lastTwoUrls[1]]).then(r => { });
      });
  }

  smartHistoryBack() {
    let sameEnv = false;
    this.applicationStore.previousUrl$.pipe(take(1)).subscribe((lastUrl) => {
      sameEnv = true;
      if (lastUrl) {
        this.location.back();
      } else {
        this.router.navigate(['/']).then((r) => { });
      }
    });
    if (!sameEnv) {
      this.router.navigate(['/']).then((r) => { });
    }
  }

  switchLanguage(newLanguage: LanguageInterface | null): void {
    if (newLanguage) {
      this.cookieStorageService.setItem('language', newLanguage.isO639_1_Code);
    } else {
      this.cookieStorageService.setItem('language', this.generalStore.defaultLanguage().isO639_1_Code);
    }
    // Reload application to load appropriate correct set of translations
    window.location.reload();
  }

  debug(msg: any, level = 1) {
    if (environment.debug) {
      console.log(msg);
    }
  }
}
