<ng-container *ngIf="layoutAccountData$ | async as layoutAccountData; else loading">
  <app-topbar />
  <main class="scroll-container">
    <router-outlet />
  </main>
  <shared-cookie-disclaimer *ngIf="!cookieDisclaimerService.cookieDisclaimerAgreed()" />
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
