import { Injectable } from '@angular/core';
import { FrontendStorageService } from './frontend-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FrontendLocalStorageService extends FrontendStorageService {

  constructor() {
    super();
  }

  setItem(name: string, setting: string): void {
    localStorage.setItem(name, setting);
  }
  getItem(name: string): string | null {
    return localStorage.getItem(name);
  }
  removeItem(name: string): void {
    localStorage.removeItem(name);
  }
  clear(): void {
    localStorage.clear();
  }
}
