import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { AccountInterface } from '../../models/account.model';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { ErrorService } from '../../services/error.service';
import { UserAccountService } from '../../services/user-account.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';

interface HelpPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
}
@Component({
  selector: 'app-side-menu-extra',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './side-menu-extra.component.html',
  styleUrl: './side-menu-extra.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SideMenuExtraComponent implements OnInit {
  crwSection = CrwFlowSection;
  helpPageData$!: Observable<HelpPageData>;

  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);

  ngOnInit(): void {
    this.helpPageData$ = combineLatest(
      [
        this.userAccountStore.isLoggedIn$,
        this.userAccountStore.userAccount$,
      ]
    ).pipe(
      map(([isLoggedIn, userAccount]) => {
        return {
          isLoggedIn,
          userAccount,
        };
      }),
    );
  }

}
