import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AccountInterface } from '../../models/account.model';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { LogoComponent } from '../../components/logo/logo.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { CardSelectionComponent } from '../../cards/card-selection/card-selection.component';
import { CardSubscriptionsComponent } from '../../cards/card-subscriptions/card-subscriptions.component';
import { CardRegisterComponent } from '../../cards/card-register/card-register.component';
import { CardPaymentMethodsComponent } from '../../cards/card-payment-methods/card-payment-methods.component';
import { CardOverviewComponent } from '../../cards/card-overview/card-overview.component';
import { CardShoppingCartComponent } from '../../cards/card-shopping-cart/card-shopping-cart.component';
import { CardVoucherComponent } from '../../cards/card-voucher/card-voucher.component';
import { LogoPipe } from '../../pipes/logo.pipe';
import { CardAccountComponent } from '../../cards/card-account/card-account.component';
import { CardEmailComponent } from '../../cards/card-email/card-email.component';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { CardPasswordComponent } from '../../cards/card-password/card-password.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { SideMenuCrwComponent } from '../../components/side-menu-crw/side-menu-crw.component';
import { combineLatest, map, Observable } from 'rxjs';
import { UserAccountService } from '../../services/user-account.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { ErrorService } from '../../services/error.service';
import { CrwStoreService } from '../../stores/crw-store.service';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { ProfileInterface } from '../../models/profile.model';
import { SubscriptionInterface } from '../../models/subscription.model';
import { DestroyService } from '../../services/destroy.service';
import { ApplicationService } from '../../services/application.service';
import { ApplicationStoreService } from '../../stores/application-store.service';

export enum CrwFlowSection {
  Account = 'account',
  Overview = 'overview',
  Payment = 'payment',
  Selection = 'selection',
  Subscription = 'subscription',
}

interface CrwPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
  userAccountSubscriptions: SubscriptionInterface[];
  currentSubscription: SubscriptionInterface;
  userActiveProfile: ProfileInterface;
  crwFlowSection: CrwFlowSection;
}

@Component({
  selector: 'app-crw-page',
  templateUrl: './crw-page.component.html',
  styleUrls: ['./crw-page.component.scss'],
  standalone: true,
  imports: [
    LogoComponent,
    NgIf,
    RouterLinkActive,
    RouterLink,
    NgClass,
    CardSelectionComponent,
    CardSubscriptionsComponent,
    CardRegisterComponent,
    CardPaymentMethodsComponent,
    CardOverviewComponent,
    CardShoppingCartComponent,
    CardVoucherComponent,
    AsyncPipe,
    LogoPipe,
    CardAccountComponent,
    CardEmailComponent,
    CardPairingComponent,
    CardPasswordComponent,
    SideMenuComponent,
    SideMenuCrwComponent,
    RouterOutlet,
    AvatarComponent,
  ],
})
export class CrwPageComponent implements OnInit {
  crwPageData$!: Observable<CrwPageData>;
  shoppingCartItems: any[] = [];

  protected applicationService = inject(ApplicationService);
  protected userAccountService = inject(UserAccountService);
  protected userAccountStore = inject(UserAccountStoreService);
  protected profilesStore = inject(ProfilesStoreService);
  protected applicationStoreService = inject(ApplicationStoreService);
  protected crwStore = inject(CrwStoreService);
  protected errorService = inject(ErrorService);
  protected destroyed = inject(DestroyService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);

  constructor() { }

  ngOnInit(): void {
    this.crwPageData$ = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$,
      this.userAccountStore.userAccountSubscriptions$,
      this.userAccountStore.currentSubscription$,
      this.profilesStore.userActiveProfile$,
      this.crwStore.crwSection$,
    ]).pipe(
      map(([isLoggedIn, userAccount, userAccountSubscriptions, currentSubscription, userActiveProfile, crwFlowSection]) => {
        return {
          isLoggedIn,
          userAccount,
          userAccountSubscriptions,
          currentSubscription,
          userActiveProfile,
          crwFlowSection,
        };
      }),
    );
  }

  toPreviousPage(): void {
    // this.router.navigate(['/']);
    // this.applicationService.smartHistoryBack();
    this.applicationService.returnToPreviousPage();
  }
}
