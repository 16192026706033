import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'centsToPrice',
    standalone: true
})
export class CentsToPricePipe implements PipeTransform {

  transform(cents): unknown {

    let negative = false;

    if (cents < 0) {
      negative = true;
      cents = cents * -1;
    }

    return (negative ? "- € " : "€ ") + (cents / 100).toFixed(2);
  }

}
