import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { ProfilesStoreService } from "../../stores/profiles-store.service";
import { ProfileInterface } from "../../models/profile.model";
import { LogoPipe } from "../../pipes/logo.pipe";
import { CardCreateProfileComponent } from "../../cards/card-create-profile/card-create-profile.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { LogoComponent } from "../../components/logo/logo.component";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

interface CreateProfileData {
  profiles: ProfileInterface[];
}

@Component({
  selector: "app-create-profile-page",
  templateUrl: "./create-profile-page.component.html",
  styleUrls: ["./create-profile-page.component.scss"],
  standalone: true,
  imports: [LogoComponent, NgIf, CardCreateProfileComponent, AsyncPipe, LogoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CreateProfilePageComponent implements OnInit {

  maxNumberOfProfiles: number = environment.maxNumberOfProfiles;

  private profilesStore = inject(ProfilesStoreService);
  private router = inject(Router);

  createProfileData$: Observable<CreateProfileData>;

  constructor() {
  }

  ngOnInit() {
    this.createProfileData$ = combineLatest([
      this.profilesStore.userProfiles$
    ]).pipe(
      map(
        ([profiles]) => {
          if (profiles.length && profiles.length >= this.maxNumberOfProfiles) {
            this.router.navigate(['/profiles']);
          }
          return {
            profiles
          };
        }
      )
    );

  }

}
