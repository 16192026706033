<ng-container *ngIf="navigationHeaderData$ | async as navigationHeaderData">
  <div
    class="modal"
    [ngClass]="{ closing: closing }">
    <div
      class="background"
      (click)="closeModalOnClick($event)"
      (keyup)="closeModalOnEscape($event)"
      aria-label="Close modal"
      tabindex="0"
      role="button"></div>
    <div
      [ngClass]="{ 'center-y': centerVertically }"
      class="content">
      <button
        i18n
        type="button"
        aria-label="Close modal"
        *ngIf="withCloseButton"
        class="close"
        (click)="closeModalOnClick($event)">
        <span class="mci-close"></span>
        Sluiten
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
