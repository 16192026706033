import { AbstractControl, ValidatorFn } from "@angular/forms";

export class PasswordValidator {
  static passwordFormat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const password = control.value;

      // Check for required characters
      if (!/[A-Z]/.test(password)) return { 'noUpper': true };
      if (!/[a-z]/.test(password)) return { 'noLower': true };
      if (!/\d/.test(password)) return { 'noDigit': true };
      if (!/[^A-Za-z0-9]/.test(password)) return { 'noSpecialChar': true };
      if (!(password.length >= 8 && password.length <= 24)) return { 'invalidLength': true };

      // If all checks pass, return null
      return null;
    };
  }

  static matchPassword(passwordToMatch: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const password = control.value;
      const matchedPassword = passwordToMatch.value;

      if (password !== matchedPassword) {
        return { 'mismatchedPasswords': true };
      }

      return null;
    };
  }
}
