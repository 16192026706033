<ng-container *ngIf="crwFlowData$ | async as crwFlowData">
  <div class="card">
    <div class="header">
      <h3 i18n class="title">Winkelwagen</h3>
    </div>

    <ng-container *ngIf="crwFlowData.crwSubCart.length == 0">
      <p i18n class="subtitle">Er zit nog niks in uw winkelwagen.</p>
    </ng-container>

    <ng-container *ngIf="crwFlowData.crwSubCart.length > 0">
      <div class="details">
        <div class="detail" *ngFor="let item of crwFlowData.crwSubCart; index as i">
          <h5>
            <span class="old-price" *ngIf="crwFlowData.crwShadowSub"> €{{ crwFlowData.crwShadowSub?.costs }} </span>
            €{{ item?.costs }}
          </h5>
          <p>{{ item?.name }}</p>
          <button (click)="remove(item)" class="close icon mci-close is-pri is-transparent"></button>
        </div>
      </div>

      <div class="divider"></div>

      <div class="total">
        <h5 i18n>Totaal: {{ total }}</h5>
        <!--      <div-->
        <!--        class="btn is-colored"-->
        <!--        [ngClass]="{-->
        <!--          'is-disabled': crwFlow.payment.selectedPaymentMethodCode == ''-->
        <!--        }"-->
        <!--        (click)="pay()"-->
        <!--      >-->
        <!--        <h5>Pay</h5>-->
        <!--      </div>-->
      </div>
    </ng-container>
  </div>
</ng-container>
