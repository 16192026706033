import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UserAccountService } from "../../services/user-account.service";
import { ApplicationEvent, ApplicationService } from "../../services/application.service";
import { GeneralStoreService } from "../../stores/general-store.service";
import { combineLatest, map, Observable, take } from "rxjs";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { AccountInterface } from "../../models/account.model";
import { LanguageIdToNamePipe } from "../../pipes/language-id-to-name.pipe";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { FormInputComponent } from "../../components/forms/components/control/form-input/form-input.component";
import { FormSelectComponent } from "../../components/forms/components/control/form-select/form-select.component";
import { OptionCollection, toOptionCollection } from "../../shared/interfaces/option-collection";
import { Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";
import { FormCheckboxComponent } from "../../components/forms/components/control/form-checkbox/form-checkbox.component";
import {
  FormInputHiddenComponent
} from "../../components/forms/components/control/form-input-hidden/form-input-hidden.component";
import { FrontendCookieStorageService } from "../../services/storage/frontend-cookie-storage.service";

interface CardAccountData {
  userAccount: AccountInterface;
}

@Component({
  selector: "app-card-account",
  templateUrl: "./card-account.component.html",
  styleUrls: ["./card-account.component.scss"],
  standalone: true,
  imports: [
    NgIf, NgClass, NgFor, AsyncPipe, LanguageIdToNamePipe, ErrorMessagesComponent, FormInputComponent,
    FormSelectComponent, FormsModule, GetFormControlPipe, ReactiveFormsModule, RouterLink, SubmitComponent,
    FormCheckboxComponent, FormInputHiddenComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardAccountComponent extends FormComponent implements OnInit {

  @Output() deleteModeRequested = new EventEmitter<boolean>();

  editMode: WritableSignal<boolean> = signal(false);

  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private generalStore = inject(GeneralStoreService);

  cardAccountData$: Observable<CardAccountData>;

  private userAccount: AccountInterface;
  languages: OptionCollection[] = [];

  override formGroup = this.formBuilder.group({
    account: this.formBuilder.group({
      firstName: this.formBuilder.control<string>(""),
      lastName: this.formBuilder.control<string>(""),
      languageId: this.formBuilder.control<number>(0),
      newsLetter: this.formBuilder.control<boolean>(false)
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.languages = toOptionCollection(this.generalStore.languages(), "name", "id");
    this.cardAccountData$ = combineLatest([
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([userAccount]) => {
          this.formGroup.get("account").patchValue(userAccount);
          this.formGroup.updateValueAndValidity();
          return {
            userAccount
          };
        }
      )
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const accountControls = this.formGroup.get("account") as FormGroup;
    if (this.submittable(accountControls)) {
      this.submitting.set(true);
      this.userAccountService.updateAccount(accountControls.value).pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            // Was there a language change?
            if (this.formGroup.get('account.languageId').touched) {
              const newLanguage = this.generalStore.getLanguageById(+this.formGroup.get('account.languageId').value);
              this.userAccountStore.userAccountLanguage.set(newLanguage);
              this.applicationService.switchLanguage(newLanguage);
            } else {
              this.applicationStore.setApplicationEvent(ApplicationEvent.UserAccountUpdate);
              this.setFormMessage(this.translations.getTranslation(this.translations.FORM_CHANGES_SAVE_SUCCESS), Q90ResponseLevels.Success);
            }
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
        complete: () => {
          this.formGroup.markAsPristine();
        }
      });
    }
  }
}
