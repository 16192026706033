import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ProfilesService } from '../../services/profiles.service';
import { forkJoin, Observable, take } from 'rxjs';
import { GeneralStoreService } from '../../stores/general-store.service';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ProfileInterface } from '../../models/profile.model';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { Q90ResponseLevels } from '../../models/q90-response';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { FormSelectComponent } from '../../components/forms/components/control/form-select/form-select.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { OptionCollection, toOptionCollection } from '../../shared/interfaces/option-collection';
import { FormPincodeComponent } from '../../components/forms/components/control/form-pincode/form-pincode.component';
import { FormCheckboxComponent } from '../../components/forms/components/control/form-checkbox/form-checkbox.component';
import { FormAvatarComponent } from '../../components/forms/components/control/form-avatar/form-avatar.component';
import { AutocompleteOffDirective } from '../../components/forms/directives/autocomplete-off.directive';
import { FormPincodeConfirmComponent } from '../../components/forms/components/control/form-pincode-confirm/form-pincode-confirm.component';
import { Q90ResponseData } from '../../interfaces/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { FormInputHiddenComponent } from '../../components/forms/components/control/form-input-hidden/form-input-hidden.component';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { ApplicationEvent, ApplicationService } from "../../services/application.service";
import { SpinnerComponent } from "../../components/spinner/spinner.component";
@Component({
  selector: 'app-card-edit-profile',
  templateUrl: './card-edit-profile.component.html',
  styleUrls: ['./card-edit-profile.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgFor,
    NgClass,
    RouterLink,
    AsyncPipe,
    ErrorMessagesComponent,
    FormInputComponent,
    FormSelectComponent,
    GetFormControlPipe,
    ReactiveFormsModule,
    SubmitComponent,
    FormPincodeComponent,
    FormPincodeConfirmComponent,
    FormCheckboxComponent,
    FormAvatarComponent,
    AutocompleteOffDirective,
    FormInputHiddenComponent,
    SpinnerComponent
  ],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEditProfileComponent extends FormComponent implements OnInit {
  public avatar!: File;
  public avatarUrl: string | null = null;

  private profileId: number;
  profileLoaded: WritableSignal<boolean> = signal(false);
  profile: WritableSignal<ProfileInterface | null> = signal(null);

  private applicationService = inject(ApplicationService);
  private appStore = inject(ApplicationStoreService);
  private generalStore = inject(GeneralStoreService);
  private profilesService = inject(ProfilesService);
  private profilesStore = inject(ProfilesStoreService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  languages: OptionCollection[] = [];

  override formGroup = this.formBuilder.group({
    profile: this.formBuilder.group({
      props: this.formBuilder.group({
        profileName: this.formBuilder.control<string>('', [Validators.required]),
        // languageId: this.formBuilder.control<number | null>(null, [Validators.required]),
        accessCode: this.formBuilder.control<number | null>(null),
        hasAccessCode: this.formBuilder.control<boolean>(false),
        isAccessCodeChanged: this.formBuilder.control<boolean>(false),
        kidsMode: this.formBuilder.control<boolean>(false),
      }),
      avatar: this.formBuilder.control<string>(''),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.route.params.pipe(take(1)).subscribe({
      next: (params) => {
        this.profileId = +params['profileId'];
      },
      complete: () => {
        this.getProfile();
      },
    });

    this.languages = toOptionCollection(this.generalStore.languages(), 'name', 'id');
  }

  getProfile() {
    this.profilesService
      .getProfile(this.profileId)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (XperienceResponse.isSuccess(result)) {
            this.profile.set(result.data);
          }
        },
        complete: () => {
          this.profileLoaded.set(true);
          this.formGroup.get('profile.props').patchValue(this.profile());
          this.formGroup.updateValueAndValidity();
        },
      });
  }

  toProfiles(e: Event): void {
    this.router
      .navigate([`profiles`])
      .then((result) => {
        // router navigate returns a promise, success with navigation...
      })
      .catch((error) => {
        this.errorService.setError(new Error('Unable to view profiles'));
      });
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    // Two separate calls are needed to upload profile data
    const profilePropsControls = this.formGroup.get('profile.props') as FormGroup;
    const accessCodeControl = this.formGroup.get('profile.props.accessCode') as FormControl;
    const avatarControls = this.formGroup.get('profile.avatar') as FormControl;
    if (profilePropsControls.valid && avatarControls.valid && (!profilePropsControls.pristine || !avatarControls.pristine)) {
      this.submitting.set(true);

      // Probably need a separate checkbox to disable accessCode.use for a profile..
      if (!accessCodeControl.pristine) {
        this.formGroup.get('profile.props.isAccessCodeChanged').setValue(true);
        if (accessCodeControl.value) {
          this.formGroup.get('profile.props.hasAccessCode').setValue(true);
        }
      }

      // Not all profile fields are editable via form right now, so let editable fields overwrite values already in profile
      const combined: ProfileInterface = { ...this.profile(), ...profilePropsControls.value };

      // Prepare avatar upload if changed
      const avatarData = new FormData();
      if (!avatarControls.pristine) {
        avatarData.append('uploadFile', this.avatar, this.avatar.name);
      }

      // Collect calls to be forkJoined...
      const observablesArray: Observable<Q90ResponseData<ProfileInterface> | Q90ResponseData<any>>[] = [];
      observablesArray.push(this.profilesService.editProfile(combined));
      if (!avatarControls.pristine) {
        observablesArray.push(this.profilesService.uploadAvatar(avatarData, this.profile()));
      }
      const combinedObservable$ = forkJoin([...observablesArray]);

      combinedObservable$.pipe(take(1)).subscribe({
        next: (response) => {
          const profileUpdateResponse = response[0] as Q90ResponseData<ProfileInterface>;
          if (XperienceResponse.isSuccess(profileUpdateResponse)) {
            // Was there a profile language change?
            // if (this.formGroup.get('profile.props.languageId').touched) {
            //   const newLanguage = this.generalStore.getLanguageById(+this.formGroup.get('profile.props.languageId').value);
            //   this.profilesStore.profileLanguage.set(newLanguage);
            //   this.applicationService.switchLanguage(newLanguage);
            // } else {
            this.appStore.setApplicationEvent(ApplicationEvent.UserAccountUpdate);
            // }
          } else {
            const error = new XperienceResponse().deserialize(profileUpdateResponse);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }

          if (!avatarControls.pristine) {
            const avatarUpdateResponse = response[1] as Q90ResponseData<any>;
            if (avatarUpdateResponse.statusCode === 200) {
              avatarControls.reset();
              this.profilesService.fetchProfileData();
            } else {
              const error = new XperienceResponse().deserialize(avatarUpdateResponse);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          }
        },
        error: (err) => {
          this.submitting.set(false);
          this.setErrorMessage(err);
        },
        complete: () => {
          this.submitting.set(false);
          this.formGroup.markAsPristine();
        },
      });
    }
  }

  setUploadFiles(e: FileList): void {
    this.avatar = e[0] as File;
  }
}
