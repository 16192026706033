import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, filter, forkJoin, map, Observable, Subscription, take, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProfilesStoreService } from '../stores/profiles-store.service';
import { PrimeableService } from './primeable.service';
import { ProfileInterface } from '../models/profile.model';
import { Q90ResponseData } from '../interfaces/q90-response';
import { ApplicationEvent } from './application.service';
import { XperienceResponse } from '../models/xperience-response';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService extends PrimeableService {
  protected profilesStore = inject(ProfilesStoreService);

  protected eventSubscription!: Subscription;

  constructor() {
    super();
    this.eventSubscription = this.applicationStore.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed),
      )
      .subscribe((setting) => {
        if (setting === ApplicationEvent.UserAccountUpdate) {
          this.fetchProfileData();
        }
      });
  }

  override ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  protected prime(): void {}

  protected authenticated(): void {}

  protected refresh(): void {
    // this.fetchProfileData();
  }

  protected logout(): void {
    this.profilesStore.setUserProfiles([]);
    this.profilesStore.setUserActiveProfile(null);
    this.profilesStore.profileLanguage.set(null);
  }

  fetchProfileData() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<Q90ResponseData<ProfileInterface[]> | Q90ResponseData<ProfileInterface>>[] = [];
    observablesArray.push(this.getProfiles());
    observablesArray.push(this.getActiveProfile());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);

        const profilesData = response[0].data as ProfileInterface[];
        this.profilesStore.setUserProfiles(profilesData);

        const activeProfileData = response[1].data as ProfileInterface;
        this.profilesStore.setUserActiveProfile(activeProfileData);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  getActiveProfile(): Observable<Q90ResponseData<ProfileInterface>> {
    return this.http
      .get<Q90ResponseData<ProfileInterface>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (XperienceResponse.isSuccess(res)) {
            const profileLanguage = this.generalStore.languages().find((lang) => lang.id === res.data.languageId);
            if (profileLanguage) {
              this.profilesStore.profileLanguage.set(profileLanguage);
            }
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getProfile(profileId: number): Observable<Q90ResponseData<ProfileInterface>> {
    return this.http
      .get<Q90ResponseData<ProfileInterface>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/${profileId}`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getProfiles(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/list`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  loginProfile(profileId: number, accessCode: string = ''): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/login`,
        {
          profileId: profileId,
          accessCode: accessCode,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          // if (!XperienceResponse.isSuccess(res)) {
          //   this.errorService.setError(new XperienceResponse().deserialize(res));
          // }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  logoutProfile(profileId: number): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/logout/${profileId}`,
        {},
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  editProfile(profile: Partial<ProfileInterface>): Observable<Q90ResponseData<ProfileInterface>> {
    return this.http
      .put<Q90ResponseData<ProfileInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/${profile.profileId}/edit`,
        {
          ...profile,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  createProfile(profile: Partial<ProfileInterface>): Observable<Q90ResponseData<ProfileInterface>> {
    return this.http
      .post<Q90ResponseData<ProfileInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/add`,
        {
          ...profile,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  swapMainProfile(currentMainProfileId: number, newMainProfileId: number): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/main/swap`,
        {
          currentMainProfileId: currentMainProfileId,
          newMainProfileId: newMainProfileId,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  deleteProfile(profileId: number): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/${profileId}/delete`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getReminders(channelId: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/reminder?mainproductChannelId=${channelId}`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  setReminder(epgId: number, startTime: string, endTime: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/reminder`,
        {
          epgId: epgId,
          startTime: startTime,
          endTime: endTime,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  removeReminder(reminderId: number): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/reminder/${reminderId}`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  uploadAvatar(formData: FormData, profile: ProfileInterface) {
    return this.http
      .post<any>(`${environment.apiEndpointRoot}${environment.apiEndpointVersion}/profile/${profile.profileId}/edit/avatar`, formData, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  requestPincodeReset(profileId: number) {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/profile/${profileId}/accescode/request`,
        {},
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
  resetPincode(resetKey: string, newPincode: number): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/profile/accescode/request/complete`,
        {
          resetKey: resetKey,
          accessCode: newPincode,
        },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
