import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { catchError, forkJoin, map, Observable, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { Q90ResponseData } from '../interfaces/q90-response';
import { ApplicationSettingInterface } from '../models/application-setting.model';
import { ContentTemplateInterface } from '../models/content-template.model';
import { FaqCategoryInterface } from '../models/faq-category.model';
import { FaqInterface } from '../models/faq.model';
import { LanguageInterface } from '../models/language.model';
import { PaymentMethodInterface } from '../models/payment-method.model';
import { SubscriptionInterface } from '../models/subscription.model';
import { PrimeableService } from './primeable.service';
import { BrandInterface } from '../models/brand.model';
import { XperienceResponse } from '../models/xperience-response';
import { EmailContactInterface } from '../interfaces/email-contact.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralService extends PrimeableService implements OnDestroy {

  protected eventSubscription!: Subscription;

  override ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  protected prime() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      | Q90ResponseData<BrandInterface[]>
      | Q90ResponseData<FaqInterface[]>
      | Q90ResponseData<FaqCategoryInterface[]>
      | Q90ResponseData<ContentTemplateInterface[]>
      | Q90ResponseData<SubscriptionInterface[]>
      | Q90ResponseData<PaymentMethodInterface[]>
    >[] = [];
    observablesArray.push(this.getBrands());
    observablesArray.push(this.getFaq());
    observablesArray.push(this.getFaqCategories());
    observablesArray.push(this.getContentTemplates());
    observablesArray.push(this.getSubscriptions());
    observablesArray.push(this.getPaymentMethods());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);

        // Process brands data response
        const brandsData = response[0].data as BrandInterface[];
        this.generalStore.setBrands(brandsData);

        // Process faqs data response
        const faqData = response[1].data as FaqInterface[];
        this.generalStore.setFaqs(faqData);

        // Process faq categories data response
        const categoriesData = response[2].data as FaqCategoryInterface[];
        this.generalStore.setFaqCategories(categoriesData);

        // Process content templates data response
        const templatesData = response[3].data as ContentTemplateInterface[];
        this.generalStore.setContentTemplates(templatesData);

        // Process subscriptions data response
        const subscriptionsData = response[4].data as SubscriptionInterface[];
        if (subscriptionsData)
          this.generalStore.setSubscriptions(subscriptionsData);

        // Process payment methods data response
        const methodsData = response[5].data as PaymentMethodInterface[];
        this.generalStore.setPaymentMethods(methodsData);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected authenticated(): void { }

  protected refresh(): void { }

  protected logout(): void { }

  getLanguages(): Observable<Q90ResponseData<LanguageInterface[]>> {
    return this.http
      .get<Q90ResponseData<LanguageInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/general/languages`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getDefaultBrand(): Observable<Q90ResponseData<BrandInterface>> {
    return this.http
      .get<Q90ResponseData<BrandInterface>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/banner/brands/default`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getBrands(): Observable<Q90ResponseData<BrandInterface[]>> {
    return this.http
      .get<Q90ResponseData<BrandInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/banner/brands`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getApplicationSettings(brandId: number): Observable<Q90ResponseData<ApplicationSettingInterface[]>> {
    return this.http
      .get<Q90ResponseData<ApplicationSettingInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/banner/brands/${brandId}/settings`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getFaq(): Observable<Q90ResponseData<FaqInterface[]>> {
    return this.http
      .get<Q90ResponseData<FaqInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/general/faq`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getFaqCategories(): Observable<Q90ResponseData<FaqCategoryInterface[]>> {
    return this.http
      .get<Q90ResponseData<FaqCategoryInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/general/faq/categories`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getContentTemplates(): Observable<Q90ResponseData<ContentTemplateInterface[]>> {
    return this.http
      .get<Q90ResponseData<ContentTemplateInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/general/contenttemplate`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  getSubscriptions(): Observable<Q90ResponseData<SubscriptionInterface[]>> {
    return this.http
      .get<Q90ResponseData<SubscriptionInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/channels/subscriptions`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          // if (!XperienceResponse.isSuccess(res)) {
          //   this.errorService.setError(new XperienceResponse().deserialize(res));
          // }
          return res;
        }),
        // catchError((err) => {
        //   throw err;
        // }),
      );
  }

  getPaymentMethods(): Observable<Q90ResponseData<PaymentMethodInterface[]>> {
    return this.http
      .get<Q90ResponseData<PaymentMethodInterface[]>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/transactions/payment/methods`, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      })
      .pipe(
        map((res) => {
          if (!XperienceResponse.isSuccess(res)) {
            this.errorService.setError(new XperienceResponse().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  sendContact(contact: EmailContactInterface): Observable<Q90ResponseData<any>> {
    return this.http.post<Q90ResponseData<any>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/general/contact`,
      {
        ...contact
      },
      {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }
}
