import { Component, inject } from '@angular/core';
import { CardLoginComponent } from '../../cards/card-login/card-login.component';
import { CommonModule } from '@angular/common';
import { LogoPipe } from '../../pipes/logo.pipe';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountStoreService } from '../../stores/user-account-store.service';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule, CardLoginComponent, LogoPipe],
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  private userAccountStore = inject(UserAccountStoreService);
  private backgroundImageStore = inject(BackgroundImageStoreService);
  private router = inject(Router);
  isActivated = false;

  constructor(private route: ActivatedRoute) {
    this.isActivated = this.route.snapshot.queryParams['accountActivated'];
    this.backgroundImageStore.setBackgroundImage('background-192tv.png');
  }

  // login(e: boolean): void {
  //   if (e) this.router.navigate(['/tv']);
  // }
}
