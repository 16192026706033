<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <h3 i18n>Uw selectie</h3>
      <p i18n>U heeft nog geen toestemming om dit item af te spelen. Kies een van de volgende opties om te kunnen kijken.</p>
    </header>
    <fieldset formGroupName="selection">
      <shared-form-radio-group
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_ENABLED"
        [formGroup]="formGroup"
        [options]="options"
        [control]="formGroup | getFormControl: 'selection.option'"></shared-form-radio-group>
    </fieldset>
    <footer>
      <div class="button-bar align-right">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_NEXT"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>

<!--<div id="card-{{ id }}"-->
<!--     class="card">-->

<!--  <div class="header">-->
<!--    <h3 class="title">Your Selection</h3>-->
<!--  </div>-->

<!--  <p class="subtitle">You don't have permission to watch this item yet. Pick one of the options in-->
<!--    order to watch this item.</p>-->

<!--  <form id="form-{{ id }}"-->
<!--        class="is-checklist"-->
<!--        (change)="setSelection()">-->

<!--    <div class="field">-->
<!--      <h5>Register</h5>-->
<!--      <div class="input-radio">-->
<!--        <input name="selection"-->
<!--               value="register"-->
<!--               class="input"-->
<!--               type="radio">-->
<!--        <div class="icon mci-check-circle-solid"></div>-->
<!--      </div>-->
<!--      <p>Free, SD quality only</p>-->
<!--    </div>-->

<!--    <div class="field">-->
<!--      <h5>Buy or Rent</h5>-->
<!--      <div class="input-radio">-->
<!--        <input name="selection"-->
<!--               value="tvod"-->
<!--               class="input"-->
<!--               type="radio">-->
<!--        <div class="icon mci-check-circle-solid"></div>-->
<!--      </div>-->
<!--      <p>From € 1,99</p>-->
<!--    </div>-->

<!--    <div class="field">-->
<!--      <h5>Subscription</h5>-->
<!--      <div class="input-radio">-->
<!--        <input name="selection"-->
<!--               value="svod"-->
<!--               class="input"-->
<!--               type="radio">-->
<!--        <div class="icon mci-check-circle-solid"></div>-->
<!--      </div>-->
<!--      <p>From € 4,99</p>-->
<!--    </div>-->

<!--  </form>-->

<!--<div class="buttons">-->
<!--  <div class="btn is-colored">-->
<!--    <h5>Next</h5>-->
<!--  </div>-->
<!--</div>-->

<!--</div>-->
