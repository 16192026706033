import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Router, RouterOutlet } from "@angular/router";
import { LoaderComponent } from "../../components/loader/loader.component";
import { AsyncPipe, NgClass, NgIf, NgStyle } from "@angular/common";
import { LogoPipe } from "../../pipes/logo.pipe";
import { LogoComponent } from "../../components/logo/logo.component";
import { BackgroundImageStoreService } from "../../stores/background-image-store.service";
import { ApplicationStoreService } from "../../stores/application-store.service";

interface AppComponentData {
  // darkMode: boolean;
  priming: boolean;
}

@Component({
  selector: "app-layout-with-background",
  standalone: true,
  imports: [
    LoaderComponent,
    NgClass,
    AsyncPipe,
    RouterOutlet,
    NgIf,
    LogoPipe,
    NgStyle,
    LogoComponent
  ],
  providers: [
    { provide: BackgroundImageStoreService }
  ],
  templateUrl: "./layout-with-background.component.html",
  styleUrl: "./layout-with-background.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LayoutWithBackgroundComponent implements OnInit {

  backgroundImage: Observable<string>;

  appComponentData$!: Observable<AppComponentData>;
  public router = inject(Router);

  private backgroundImageStore = inject(BackgroundImageStoreService);
  // private generalStore = inject(GeneralStoreService);
  private applicationStore = inject(ApplicationStoreService);

  constructor() {
    // preloading effect
    const bgImg = new Image();
    bgImg.src = "assets/images/background-192tv.png";
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }

  ngOnInit(): void {
    this.backgroundImage = this.backgroundImageStore.backgroundImage$;
    this.appComponentData$ = combineLatest([
      // this.generalStore.darkMode$,
      this.applicationStore.primeablePriming$,
    ]).pipe(
      map(
        ([
          // darkMode,
          priming,
        ]) => {
          return {
            // darkMode,
            priming,
          };
        }
      )
    );
  }
}
