import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { CrwFlowSection, CrwPageComponent } from "../crw-page/crw-page.component";
import { CardRegisterComponent } from "../../cards/card-register/card-register.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { CardLoginComponent } from "../../cards/card-login/card-login.component";
import { CardAccountYourComponent } from "../../cards/card-account-your/card-account-your.component";
import { CardSubscriptionYourComponent } from "../../cards/card-subscription-your/card-subscription-your.component";

@Component({
  selector: "app-crw-account",
  standalone: true,
  imports: [
    CardRegisterComponent,
    CardLoginComponent,
    CardAccountYourComponent,
    CardSubscriptionYourComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: "./crw-account.component.html",
  styleUrl: "./crw-account.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrwAccountComponent extends CrwPageComponent implements OnInit {

  showCrwLogin: WritableSignal<boolean> = signal(false);

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwStore.setCrwFlow(CrwFlowSection.Account);
  }

  showLogin(e: boolean): void {
    this.showCrwLogin.set(e);
  }

  // Not sure this method is actually called by child component
  login(e: boolean): void {
    this.router.navigate(['/crw/subscription']);
  }
}
