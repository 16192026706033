import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CrwStoreService } from '../../stores/crw-store.service';
import { combineLatest, map, Observable, take } from 'rxjs';
import { UserAccountService } from '../../services/user-account.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutocompleteOffDirective } from '../../components/forms/directives/autocomplete-off.directive';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormEmailComponent } from '../../components/forms/components/control/form-email/form-email.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { RouterLink } from '@angular/router';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { FormEmailConfirmComponent } from '../../components/forms/components/control/form-email-confirm/form-email-confirm.component';
import { FormPasswordComponent } from '../../components/forms/components/control/form-password/form-password.component';
import { FormPasswordConfirmComponent } from '../../components/forms/components/control/form-password-confirm/form-password-confirm.component';
import { FormUsernameComponent } from '../../components/forms/components/control/form-username/form-username.component';
import { Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { FormSelectComponent } from '../../components/forms/components/control/form-select/form-select.component';
import { OptionCollection, toOptionCollection } from '../../shared/interfaces/option-collection';
import { GeneralStoreService } from '../../stores/general-store.service';
import { CardRegisterActivateComponent } from '../card-register-activate/card-register-activate.component';
import { UserAccountStoreService } from '../../stores/user-account-store.service';

interface CrwFlowData {
  crwFlowSection: CrwFlowSection;
}

@Component({
  selector: 'app-card-register',
  templateUrl: './card-register.component.html',
  styleUrls: ['./card-register.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    AutocompleteOffDirective,
    ErrorMessagesComponent,
    FormInputComponent,
    FormEmailComponent,
    FormEmailConfirmComponent,
    GetFormControlPipe,
    ReactiveFormsModule,
    RouterLink,
    SubmitComponent,
    FormPasswordComponent,
    FormPasswordConfirmComponent,
    FormUsernameComponent,
    FormSelectComponent,
    CardRegisterActivateComponent,
  ],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardRegisterComponent extends FormComponent implements OnInit {
  @Output() onShowLogin = new EventEmitter<boolean>();

  private crwStore = inject(CrwStoreService);

  crwFlowData$!: Observable<CrwFlowData>;
  accountCreated: WritableSignal<boolean> = signal(false);
  languages: OptionCollection[] = [];

  private userAccountService = inject(UserAccountService);
  private userAccountStoreService = inject(UserAccountStoreService);
  private generalStore = inject(GeneralStoreService);

  override formGroup = this.formBuilder.group({
    register: this.formBuilder.group({
      username: this.formBuilder.control<string>('', [Validators.required]),
      email: this.formBuilder.control<string>('', [Validators.required]),
      emailAddressConfirm: this.formBuilder.control<string>('', [Validators.required]),
      password: this.formBuilder.control<string>('', [Validators.required]),
      passwordConfirm: this.formBuilder.control<string>('', [Validators.required]),
      firstName: this.formBuilder.control<string>('', [Validators.required]),
      lastName: this.formBuilder.control<string>('', [Validators.required]),
      languageId: this.formBuilder.control<number | null>(null, [Validators.required]),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.languages = toOptionCollection(this.generalStore.languages(), 'name', 'id');
    const preSelectedLanguage = this.generalStore.getLanguageByCode('nl-NL');
    this.formGroup.get('register.languageId').setValue(preSelectedLanguage.id);
    this.crwFlowData$ = combineLatest([
      this.crwStore.crwSection$,
      this.userAccountStoreService.createAccountData$,
    ]).pipe(
      map(([crwFlowSection, createAccountData]) => {
        const registerControls = this.formGroup.get('register') as FormGroup;
        if (createAccountData) registerControls.setValue(createAccountData.value);
        return {
          crwFlowSection,
        };
      }),
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const registerControls = this.formGroup.get('register') as FormGroup;
    if (this.submittable(registerControls)) {
      this.submitting.set(true);
      this.userAccountService
        .register(registerControls.value)
        .pipe(take(1))
        .subscribe((res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.accountCreated.set(true);
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Success);
            this.errorMessagesStore.setErrorMessage(error);
            this.userAccountStoreService.setCreateAccountData(null);
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        });
    }
  }

  ngOnDestroy() {
    const registerControls = this.formGroup.get('register') as FormGroup;
    this.userAccountStoreService.setCreateAccountData(registerControls);
  }
}
