import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CrwFlowSection } from '../pages/crw-page/crw-page.component';
import { PurchaseData, SubscriptionInterface } from '../models/subscription.model';
import { PaymentMethodInterface } from '../models/payment-method.model';

@Injectable({
  providedIn: 'root',
})
export class CrwStoreService {
  private crwSectionStore = new BehaviorSubject<CrwFlowSection>(CrwFlowSection.Subscription);
  crwSection$: Observable<CrwFlowSection> = this.crwSectionStore.asObservable();

  private crwSubStore = new BehaviorSubject<SubscriptionInterface>(null);
  crwSub$: Observable<SubscriptionInterface> = this.crwSubStore.asObservable();

  private crwShadowSubStore = new BehaviorSubject<SubscriptionInterface>(null);
  crwShadowSub$: Observable<SubscriptionInterface> = this.crwShadowSubStore.asObservable();

  private crwSubCartStore = new BehaviorSubject<SubscriptionInterface[]>([]);
  crwSubCart$: Observable<SubscriptionInterface[]> = this.crwSubCartStore.asObservable();

  private crwPaymentMethodStore = new BehaviorSubject<PaymentMethodInterface | null>(null);
  crwPaymentMethod$: Observable<PaymentMethodInterface | null> = this.crwPaymentMethodStore.asObservable();

  private crwPaymentResultStore = new BehaviorSubject<PurchaseData>(null);
  crwPaymentResult$: Observable<PurchaseData> = this.crwPaymentResultStore.asObservable();

  constructor() {
    if (localStorage.getItem('crwSub')) this.crwSubStore.next(JSON.parse(localStorage.getItem('crwSub')));
    if (localStorage.getItem('crwSubCart')) this.crwSubCartStore.next(JSON.parse(localStorage.getItem('crwSubCart')));
    if (localStorage.getItem('crwPaymentMethod')) this.crwPaymentMethodStore.next(JSON.parse(localStorage.getItem('crwPaymentMethod')));
  }

  setCrwFlow(setting: CrwFlowSection): void {
    this.crwSectionStore.next(setting);
  }

  setSubscription(sub: SubscriptionInterface | null): void {
    localStorage.setItem('crwSub', JSON.stringify(sub));
    this.crwSubStore.next(sub);
  }

  setCrwShadowSub(sub: SubscriptionInterface): void {
    this.crwShadowSubStore.next(sub);
  }

  setCrwSubCart(sub: SubscriptionInterface): void {
    // Add to cart
    // const subVal = this.crwSubCartStore.value.concat(sub);

    // Replace cart
    const subVal = [sub];
    localStorage.setItem('crwSubCart', JSON.stringify(subVal));
    this.crwSubCartStore.next(subVal);
    // this.setCrwShadowSub(null);
  }

  clearCart(): void {
    this.crwSubCartStore.next([]);
  }

  removeFromCrwSubCart(item: SubscriptionInterface): void {
    this.crwSubCartStore.pipe(
      take(1)
    ).subscribe(
      (result) => {
        const filtered = result.filter(s => s.productId !== item.productId);
        this.crwSubCartStore.next(filtered);
        localStorage.setItem('crwSubCart', JSON.stringify(filtered));
        // this.setCrwShadowSub(null);
      }
    )
  }

  setCrwPaymentMethod(paymentMethod: PaymentMethodInterface): void {
    this.crwPaymentMethodStore.next(paymentMethod);
    localStorage.setItem('crwPaymentMethod', JSON.stringify(paymentMethod));
  }

  setCrwPaymentResult(paymentResult: PurchaseData): void {
    this.crwPaymentResultStore.next(paymentResult);
  }
}
