import { HttpClient } from "@angular/common/http";
import { inject, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, filter, Observable, Subscription, takeUntil } from "rxjs";
import { DestroyService } from "./destroy.service";
import { ApplicationEvent, ApplicationService } from "./application.service";
import { ErrorService } from "./error.service";
import { ApplicationStoreService } from "../stores/application-store.service";
import { GeneralStoreService } from "../stores/general-store.service";
import { AuthenticationStoreService } from "../stores/authentication-store.service";
import { UserAccountStoreService } from "../stores/user-account-store.service";

export enum PrimeableServiceState {
  Primed = "PRIMED",
  Stale = "STALE",
}

@Injectable({
  providedIn: "root"
})
export abstract class PrimeableService implements OnDestroy {

  protected applicationService = inject(ApplicationService);
  protected applicationStore = inject(ApplicationStoreService);
  protected authenticationStore = inject(AuthenticationStoreService);
  protected generalStore = inject(GeneralStoreService);
  protected userAccountStore = inject(UserAccountStoreService);
  protected destroyed = inject(DestroyService);
  protected http = inject(HttpClient);
  protected errorService = inject(ErrorService);

  private queryingPrimeableServiceStore = new BehaviorSubject<boolean>(false);
  queryingPrimeableService$: Observable<boolean> = this.queryingPrimeableServiceStore.asObservable();

  private primeableServiceStateStore = new BehaviorSubject<PrimeableServiceState>(PrimeableServiceState.Stale);
  primeableServiceState$: Observable<PrimeableServiceState> = this.primeableServiceStateStore.asObservable();

  private readonly applicationEventSubscription!: Subscription;

  protected constructor() {
    // Listen for Application Events. Application events can trigger primeable service methods...
    this.applicationEventSubscription = this.applicationStore.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed)
      )
      .subscribe((setting) => {
        this.applicationService.debug(
          `${this.constructor.name}: application event: ${setting}`
        );
        if (setting === ApplicationEvent.Prime) {
          this.prime();
        }
        if (setting === ApplicationEvent.Login) {
          this.authenticated();
        }
        if (setting === ApplicationEvent.RefreshToken) {
          this.refresh();
        }
        if (setting === ApplicationEvent.Logout) {
          this.logout();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.applicationEventSubscription) {
      this.applicationEventSubscription.unsubscribe();
    }
  }

  setQueryingPrimeableService(setting: boolean) {
    this.queryingPrimeableServiceStore.next(setting);
    //hmmmm....
    this.applicationStore.setPrimeablePriming(setting);
  }

  setPrimeableServiceState(setting: PrimeableServiceState) {
    this.primeableServiceStateStore.next(setting);
  }

  /**
   * The following abstract methods are called in all services that extend PrimeableService
   * when a corresponding ApplicationEvent is broadcasted via the applicationEvent$ store.
   * Generally, the code in these methods involve api calls to the backend at specific times
   * in the application; for instance, pulling in lists from the backend to 'prime' or configure
   * bits of the application in the front-end.
   *
   * prime: When the ApplicationEvent.Prime is broadcasted services that extend PrimeableService
   * will call this method. Generally, it configures general application-wide lists. These lists are generally
   * non-sensitive info and should be callable, in the case of apis calls; in other words, no authenticated
   * user needs to be available for the call to be called. Generally, called when application starts-up.
   */
  protected abstract prime(): void;

  /**
   * authenticated: When the ApplicationEvent.login is broadcasted services that extend PrimeableService
   * will call this method. Generally, it configures general application-wide lists. These lists are generally
   * behind apis calls which require an authenticated user for the call to be called. Generally, called when a
   * user logs in and is autghenticated.
   */
  protected abstract authenticated(): void;

  /**
   * refresh: When the ApplicationEvent.RefreshToken is broadcasted services that extend PrimeableService
   * will call this method. Generally, it (re)configures general application-wide lists. Generally, is called
   * when the browser refresh button is clicked.
   */
  protected abstract refresh(): void;

  /**
   * logout: When the ApplicationEvent.Logout is broadcasted services that extend PrimeableService
   * will call this method. Generally, it can be used to tear down application bits. Generally, is called
   * when a user logs out.
   */
  protected abstract logout(): void;
}
