<ng-container *ngIf="logoData$ | async as logoData">
  <div
    id="logo-wrapper"
    [ngClass]="size">
    <a routerLink="/tv">
      <img
        alt="logo"
        src="{{ 'light' | logo }}"
        *ngIf="!logoData.darkMode" />
      <img
        alt="logo"
        src="{{ 'dark' | logo }}"
        *ngIf="logoData.darkMode"
    /></a>
  </div>
</ng-container>
