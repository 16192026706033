import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, Input, InputSignal, Output, signal, WritableSignal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProfilesService } from '../../services/profiles.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { FormPincodeComponent } from '../../components/forms/components/control/form-pincode/form-pincode.component';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormUsernameComponent } from '../../components/forms/components/control/form-username/form-username.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { ProfileInterface } from '../../models/profile.model';
import { take } from 'rxjs';
import { Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { ProfilesStoreService } from '../../stores/profiles-store.service';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgStyle, NgIf, RouterLink, FormPincodeComponent, FormUsernameComponent, GetFormControlPipe, ErrorMessagesComponent, SubmitComponent, AvatarComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProfileComponent extends FormComponent {
  private profilesService = inject(ProfilesService);
  private profilesStore = inject(ProfilesStoreService);
  private router = inject(Router);

  @Input({ required: true }) profile: ProfileInterface;
  @Input({ required: true, alias: 'accessMode' }) set _accessMode(accessMode: ProfileInterface | null) {
    this.accessMode.set(accessMode);
  }
  @Output() profileDeleted = new EventEmitter<boolean>();
  @Output() changeAccessMode = new EventEmitter<ProfileInterface | null>();

  activeProfile: InputSignal<boolean> = input(false);
  accessMode = signal<ProfileInterface | null>(null);
  editMode: InputSignal<ProfileInterface | null> = input(null);

  deleteMode: WritableSignal<boolean> = signal(false);

  override formGroup = this.formBuilder.group({
    profileLogin: this.formBuilder.group({
      accessCode: this.formBuilder.control<string>(''),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    if (this.profile.hasAccessCode) {
      this.formGroup.get('profileLogin.accessCode').addValidators([Validators.required]);
    }
  }

  deleteProfile() {
    this.profilesService
      .deleteProfile(this.profile.profileId)
      .pipe(take(1))
      .subscribe((response) => {
        if (XperienceResponse.isSuccess(response)) {
          this.profilesService.fetchProfileData();
          this.profileDeleted.emit(true);
          this.router.navigate(['/profiles']);
        } else {
          this.errorService.setError(new Error(`Failed to delete profile: ${response}`));
        }
      });
  }

  toEditProfile(e: Event): void {
    this.router
      .navigate([`profiles/profile/${this.profile.profileId}/edit`])
      .then((result) => {
        // router navigate returns a promise, success with navigation...
      })
      .catch((error) => {
        this.errorService.setError(new Error('Unable to edit profile'));
      });
  }

  resetAccessMode(e: ProfileInterface | null): void {
    this.changeAccessMode.emit(e);
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const loginControls = this.formGroup.get('profileLogin') as FormGroup;
    if (!this.profile.hasAccessCode || this.submittable(loginControls)) {
      this.submitting.set(true);
      this.profilesService
        .loginProfile(this.profile.profileId, loginControls.value.accessCode)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            if (XperienceResponse.isSuccess(res)) {
              this.profilesStore.updateActiveProfile(this.profile.profileId);
              this.router.navigate(['/tv']);
            } else {
              this.submitting.set(false);
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            this.formGroup.markAsPristine();
          },
        });
    }
  }

  forgotPincode(profile) {
    this.profilesStore.setpinResetProfile(profile);
    this.router.navigate(['/forgot-pincode']);
  }
}
