import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { EpgService } from "../../services/epg.service";
import { LiveTimeStoreService } from "../../stores/live-time-store.service";
import { take } from "rxjs";
import { ChannelEpgComponent } from "../../components/channel-epg/channel-epg.component";
import { BannerComponent } from "../../components/banner/banner.component";
import { XperienceResponse } from "../../models/xperience-response";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { DestroyService } from "../../services/destroy.service";
import { ErrorService } from "../../services/error.service";
import { BannerSlideInterface } from "../../models/banner-slide";
import { EpgItemInterface } from "../../models/epg-item";
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { ConvertSecondsPipe } from "../../pipes/convert-seconds.pipe";
import { EpgDatesComponent } from "../../components/epg-dates/epg-dates.component";
import { EpgGridItemComponent } from "../../components/epg-grid-item/epg-grid-item.component";
import { IntersectionObserverDirective } from "../../directives/intersection-observer.directive";
import { TimelineLeftOffsetPipe } from "../../pipes/timeline-left-offset.pipe";
import { ChannelEpgItemsInterface } from "../../models/channel-epg-items";

@Component({
  selector: "app-channel-page",
  templateUrl: "./channel-page.component.html",
  styleUrls: ["./channel-page.component.scss"],
  standalone: true,
  imports: [
    BannerComponent, ChannelEpgComponent, AsyncPipe, ConvertSecondsPipe, EpgDatesComponent,
    EpgGridItemComponent, IntersectionObserverDirective, NgForOf, NgIf, TimelineLeftOffsetPipe, NgStyle, DatePipe,
    RouterLink, NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelPageComponent implements OnInit {

  currentDate!: Date;
  tomorrowDate!: Date;
  epgItems: EpgItemInterface[];
  channelEpgItems: ChannelEpgItemsInterface;
  channelId!: number;

  private userAccountStore = inject(UserAccountStoreService);
  private epgService = inject(EpgService);
  private liveTimeStore = inject(LiveTimeStoreService);
  private destroyed = inject(DestroyService);
  private errorService = inject(ErrorService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  gridLoading: WritableSignal<boolean> = signal(false);
  bannerSlides: WritableSignal<BannerSlideInterface[]> = signal([]);

  constructor() {
    this.currentDate = new Date();
    this.tomorrowDate = new Date(this.currentDate);
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
  }

  ngOnInit() {
    this.route.params.pipe(
      take(1)
    ).subscribe({
      next: (params) => {
        this.channelId = params["channelId"];
      },
      complete: () => {
        this.getEpg();
      }
    });
  }

  getEpg() {
    this.gridLoading.set(true);
    this.epgService
      .getChannelEpg(
        this.channelId,
        this.epgService.getAsIsoString(this.currentDate),
        this.epgService.getAsIsoString(this.tomorrowDate)
      )
      .pipe(
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.gridLoading.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.epgItems = res.data.data;
            this.channelEpgItems = res.data;
          } else {
            this.errorService.setError(
              new Error("Unable to load channel content at this time."));
          }
        },
        complete: () => {
          this.gridLoading.set(false);
          this.getLiveItemsForBanner();
        }
      });
  }

  getLiveItemsForBanner() {
    this.liveTimeStore.liveTimeSeconds$.pipe(
      take(1)
    ).subscribe((liveTimeSeconds) => {
      const result: BannerSlideInterface[] = [];
      this.epgItems.forEach((item) => {
        let startDate = new Date(item.startDate);
        let endDate = new Date(item.endDate);
        if (
          this.epgService.getSecondsTotal(startDate) <= liveTimeSeconds
          && this.epgService.getSecondsTotal(endDate) > liveTimeSeconds
        ) {
          result.push({ label: "", image: item.largeVisual, channelId: this.channelId, epgItem: item, streamTokenOrChannelStreamId: this.channelEpgItems.channelId });
        }
      });
      this.bannerSlides.set(result);
    });
  }
}
