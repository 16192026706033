import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterLink } from '@angular/router';
import { take } from 'rxjs';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
import { ApplicationEvent } from '../../services/application.service';
import { CommonModule } from '@angular/common';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthenticationStoreService } from '../../stores/authentication-store.service';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormPasswordComponent } from '../../components/forms/components/control/form-password/form-password.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { FormUsernameComponent } from '../../components/forms/components/control/form-username/form-username.component';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { XperienceResponse } from '../../models/xperience-response';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { UserAccountService } from '../../services/user-account.service';

@Component({
  selector: 'app-card-login',
  standalone: true,
  imports: [CommonModule, RouterLink, SubmitComponent, ReactiveFormsModule, FormUsernameComponent, FormPasswordComponent, GetFormControlPipe, ErrorMessagesComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  templateUrl: './card-login.component.html',
  styleUrls: ['./card-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLoginComponent extends FormComponent implements OnInit {
  @Input() isActivated: boolean = false;
  @Output() loginSuccessful = new EventEmitter<boolean>();
  @Output() onShowLogin = new EventEmitter<boolean>();

  currentLoginRoute: WritableSignal<string | null> = signal(null);

  private authenticationService = inject(AuthenticationService);
  private authenticationStore = inject(AuthenticationStoreService);
  private applicationStore = inject(ApplicationStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private userAccountService = inject(UserAccountService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  override formGroup = this.formBuilder.group({
    login: this.formBuilder.group({
      username: this.formBuilder.control<string>(''),
      password: this.formBuilder.control<string>(''),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentLoginRoute.set(this.route.snapshot.routeConfig.path);
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const loginControls = this.formGroup.get('login') as FormGroup;
    if (this.submittable(loginControls)) {
      this.submitting.set(true);
      this.authenticationService
        .userLogin(loginControls.value.username, loginControls.value.password)
        .pipe(take(1))
        .subscribe((res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.loginSuccessful.emit(true);
            this.authenticationStore.setAuthenticatedUser(res.data);
            this.applicationStore.setApplicationEvent(ApplicationEvent.Login);
            // this.userAccountStore.isLoggedIn.set(true);
            // this.router.navigate(["/tv"]);
          } else {
            this.submitting.set(false);
            if (this.checkValidatedUser(res.resultMessages)) {
              this.resendValidation(res, loginControls.value.username);
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
            this.loginSuccessful.emit(false);
          }
        });
    }
  }

  checkValidatedUser(messages) {
    let isUnvalidated = false;
    messages.forEach((element) => {
      if (element.code === 7) {
        isUnvalidated = true;
      }
    });
    return isUnvalidated;
  }

  resendValidation(res, username) {
    console.log(username);
    res.resultMessages.forEach((elm) => {
      if (elm.code === 7) {
        elm.message = 'Dit account is nog niet geactiveerd. Druk op de knop in uw mail om uw activatie af te ronden';
      }
    });
    const error = new XperienceResponse().deserialize(res);
    error.setLevel(Q90ResponseLevels.Info);
    this.errorMessagesStore.setErrorMessage(error);
    this.userAccountService
      .resendActivationKey(username)
      .pipe(take(1))
      .subscribe((res) => {
        console.log(res);
      });
  }
}
