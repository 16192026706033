<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <div class="grid-xp grid-cols-2 spread align-center">
        <h3 i18n>Nieuw Wachtwoord Instellen</h3>
        <p>
          <a
            i18n
            routerLink="/login"
            >Terug naar inloggen</a
          >
        </p>
      </div>
      <!--      <p>Can't remember your password? Enter your email address and we will send you an email with a reset link.</p>-->
    </header>
    <fieldset formGroupName="changePassword">
      <shared-form-password
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_NEW_PASSWORD"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'changePassword.password'" />
      <shared-form-password-confirm
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_NEW_PASSWORD_CONFIRM"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'changePassword.passwordConfirm'" />
    </fieldset>
    <footer>
      <div class="button-bar align-left">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_RESET_PASSWORD"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
<!--<div id="card-{{ id }}"-->
<!--     class="card">-->

<!--  <div class="header">-->
<!--    <h3 class="title">Set New Password</h3>-->
<!--    <a class="btn is-transparent link"-->
<!--       routerLink="/login">-->
<!--      <h5>Back to login</h5>-->
<!--    </a>-->
<!--  </div>-->

<!--  <p class="subtitle">Your new password must exist out of a minimum of 8 characters, 1 uppercase, 1-->
<!--    lowercase and 1 special/numeric character.</p>-->

<!--  <form id="form-{{ id }}"-->
<!--        (keyup.enter)="submit()"-->
<!--        autocomplete="off">-->

<!--    <div class="field">-->
<!--      <input id="newPassword"-->
<!--             class="input"-->
<!--             type="password"-->
<!--             placeholder="New password..."-->
<!--             pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"-->
<!--             required>-->
<!--      <p class="error"></p>-->
<!--    </div>-->

<!--    <div class="field mt-0">-->
<!--      <input id="newPasswordRepeat"-->
<!--             linked="newPassword"-->
<!--             linkedName="Passwords"-->
<!--             class="input"-->
<!--             type="password"-->
<!--             placeholder="Repeat new password..."-->
<!--             required>-->
<!--      <p class="error"></p>-->
<!--    </div>-->

<!--  </form>-->

<!--  <div class="buttons">-->
<!--    <div class="btn is-colored"-->
<!--         (click)="submit()"-->
<!--         [ngClass]="{'is-loading' : isSubmitting}">-->
<!--      <h5>Reset password</h5>-->
<!--      <div class="message">-->
<!--        <div class="icon mci-close-circle-solid icon-dan"></div>-->
<!--        <div class="icon mci-close-circle-solid icon-suc"></div>-->
<!--        <h5></h5>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->
