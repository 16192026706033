<ng-container *ngIf="channelEpgPageData$ | async as channelEpgPageData">
  <div id="epg">
    <app-epg-dates
      [loaded]="gridLoaded()"
      (setNextDay)="nextDay($event)"
      (setPreviousDay)="previousDay($event)"
      [currentDate]="currentDate" />
    <div
      appIntersectionObserver
      id="channel-grid-bg">
      <div
        id="loader"
        *ngIf="!gridLoaded()">
        <shared-spinner />
      </div>
      <div
        id="epg-wrapper"
        [ngClass]="{ 'is-loading': !gridLoaded() }">
        <div
          *ngIf="liveItem"
          #liveTime
          [ngClass]="{ 'is-not-today': !isToday(), 'is-replaying': playerIsReplaying }"
          [ngStyle]="{ left: channelEpgPageData.liveDate | timelineLeftOffsetChannel: liveItem : liveTimeIndicatorIndex() }"
          id="live-time">
          <h5 class="my-0">{{ channelEpgPageData.liveTimeSeconds | convertSeconds: true }}</h5>
        </div>
        <!-- <div
          *ngIf="playerIsReplaying"
          #replayTime
          [ngStyle]="{ left: (channelEpgPageData.liveDate | markerReplay: liveItem : liveTimeIndicatorIndex()) }"
          id="replay-time">
          <h5 class="my-0">replaying</h5>
        </div> -->
        <div id="channel-grid">
          @if (epgItems?.length) {
            @for (item of epgItems; track item.epgId) {
              <div
                class="item"
                [ngClass]="{ 'is-live': liveItem && item.epgId == liveItem.epgId, 'last-item': $last, 'first-item': $first }">
                <div
                  class="time"
                  id="time-{{ item.startDate | date: 'HH:mm' }}">
                  <h5>{{ item.startDate | date: "HH:mm" }}</h5>
                </div>
                <div
                  class="poster"
                  (click)="setItem({ channelId: channelId, productId: item.mainProductId, startDate: item.startDate, endDate: item.endDate, mediaReplayId: item.mediaReplayId })">
                  <img
                    *ngIf="item.largeFrontCover"
                    src="{{ item.largeFrontCover }}" />
                  <span
                    *ngIf="!item.largeFrontCover"
                    class="mci-photo missing-image"></span>
                  <h6
                    i18n
                    class="label"
                    *ngIf="liveItem && item.epgId === liveItem.epgId && isToday()">
                    Nu op TV
                  </h6>
                </div>
                <div class="info">
                  <h5 class="title">{{ item.title }}</h5>
                  <!-- <p class="type my-0">{{ item.type.value }}</p> -->
                </div>
              </div>
            }
          } @else {
            <div class="no-programs">
              <h5>&nbsp;</h5>
              <div>
                <p i18n>Geen informatie beschikbaar in gids</p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>

<shared-modal
  *ngIf="popUpOpen && activeItem"
  (closeModal)="closePopUp()">
  <app-item-card
    [channelId]="activeItem.channelId"
    [itemId]="activeItem.productId"
    [itemStartDate]="activeItem.startDate"
    (callbackActive)="closePopUp()" />
</shared-modal>
