import { EpgService } from '../../services/epg.service';
import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { EpgDatesComponent } from '../../components/epg-dates/epg-dates.component';
import { BannerComponent } from '../../components/banner/banner.component';
import { RouterLink } from '@angular/router';
import { EpgGridItemComponent } from '../../components/epg-grid-item/epg-grid-item.component';
import { ConvertSecondsPipe } from '../../pipes/convert-seconds.pipe';
import { BeautifyDatePipe } from '../../pipes/beautify-date.pipe';
import { LiveTimeStoreService } from '../../stores/live-time-store.service';
import { ErrorService } from '../../services/error.service';
import { ChannelGridEpgItemsInterface } from '../../models/channel-grid-epg-items';
import { combineLatest, distinctUntilKeyChanged, filter, map, Observable, take, takeUntil } from 'rxjs';
import { XperienceResponse } from '../../models/xperience-response';
import { DestroyService } from '../../services/destroy.service';
import { BannerSlideInterface } from '../../models/banner-slide';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { TopbarComponent } from '../../components/topbar/topbar.component';
import { TimelineLeftOffsetPipe } from '../../pipes/timeline-left-offset.pipe';
import { IntersectionObserverDirective } from '../../directives/intersection-observer.directive';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { ItemCardComponent } from '../../components/item-card/item-card.component';

const intersectionCallback = (entries) => {
  // console.log(entries);
};

interface TvPageData {
  liveTimeSeconds: number;
  liveDate: Date;
  onTheHour: number;
}
interface ActiveItem {
  channelId: number;
  productId: number;
  startDate: string;
}

@Component({
  selector: 'app-tv-page',
  templateUrl: './tv-page.component.html',
  styleUrls: ['./tv-page.component.scss'],
  standalone: true,
  providers: [EpgService],
  imports: [
    TopbarComponent,
    NgIf,
    EpgDatesComponent,
    BannerComponent,
    NgFor,
    NgClass,
    RouterLink,
    NgStyle,
    EpgGridItemComponent,
    AsyncPipe,
    ConvertSecondsPipe,
    BeautifyDatePipe,
    DatePipe,
    TimelineLeftOffsetPipe,
    IntersectionObserverDirective,
    SpinnerComponent,
    ModalComponent,
    ItemCardComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvPageComponent implements OnInit, AfterViewChecked {
  currentDate!: Date;
  todayDate!: Date;
  tomorrowDate!: Date;
  channelGrid: ChannelGridEpgItemsInterface[] = [];
  dates: Date[] = [];
  hoursAsSecondsScale!: number[];
  popUpOpen: boolean = false;

  bannerSlides: WritableSignal<BannerSlideInterface[]> = signal([]);
  gridLoading: WritableSignal<boolean> = signal(false);
  gridLoaded: WritableSignal<boolean> = signal(false);
  isToday: WritableSignal<boolean> = signal(true);
  liveTimeIndicatorInitialPositionSet: WritableSignal<boolean> = signal(false);
  wasItNextOrPrevious: WritableSignal<'next' | 'previous' | null> = signal(null);

  private timelineIntersectionObserver: IntersectionObserver;

  @ViewChild('liveTime', { read: ElementRef }) liveTime: ElementRef;

  @ViewChild(IntersectionObserverDirective)
  set appIntersectionObserver(directive: IntersectionObserverDirective) {
    if (directive) this.timelineIntersectionObserver = new IntersectionObserver(intersectionCallback, directive.options);
  }

  tvPageData$: Observable<TvPageData>;
  activeItem: ActiveItem;

  private userAccountStore = inject(UserAccountStoreService);
  private epgService = inject(EpgService);
  private liveTimeStore = inject(LiveTimeStoreService);
  private destroyed = inject(DestroyService);
  private errorService = inject(ErrorService);

  constructor() {
    this.dates = this.epgService.getEpgDateRange();
    this.hoursAsSecondsScale = this.liveTimeStore.hoursAsSecondsScale;
    this.currentDate = new Date();
    this.todayDate = new Date();
    this.todayDate.setDate(this.currentDate.getDate());
  }

  ngAfterViewChecked() {
    if (this.liveTime?.nativeElement && !this.liveTimeIndicatorInitialPositionSet()) {
      this.scrollCarousel();
      this.liveTimeIndicatorInitialPositionSet.set(true);
    }
  }

  ngOnInit() {
    this.setCurrentEpgGridDate(this.currentDate);
    this.getEpg(true);

    this.liveTimeStore.onTheHour$
      .pipe(
        filter((onTheHour) => onTheHour !== null),
        takeUntil(this.destroyed),
      )
      .subscribe((onTheHour) => {
        this.getLiveItemsForBanner();
        this.scrollToLive();
      });

    this.tvPageData$ = combineLatest([this.liveTimeStore.liveTimeSeconds$, this.liveTimeStore.liveDate$, this.liveTimeStore.onTheHour$]).pipe(
      map(([liveTimeSeconds, liveDate, onTheHour]) => {
        return {
          liveTimeSeconds,
          liveDate,
          onTheHour,
        };
      }),
    );
  }

  setCurrentEpgGridDate(date: Date) {
    this.currentDate = date;
    this.tomorrowDate = new Date(this.currentDate);
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
    if (this.epgService.getAsIsoString(this.currentDate) === this.epgService.getAsIsoString(this.todayDate)) {
      this.isToday.set(true);
    } else {
      this.isToday.set(false);
    }
    this.getEpg(false);
  }

  private getEpg(updateBanner: boolean) {
    this.gridLoaded.set(false);
    this.gridLoading.set(true);
    this.epgService
      .getChannelGridEpg(this.epgService.getAsIsoString(this.currentDate), this.epgService.getAsIsoString(this.tomorrowDate))
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.checkEPGForGaps(res.data.reverse());
            this.channelGrid = res.data;
            this.gridLoaded.set(true);
            this.gridLoading.set(false);
          } else {
            this.errorService.setError(new Error('Unable to load multi-channel grid at this time.'));
          }
        },
        complete: () => {
          if (updateBanner) this.getLiveItemsForBanner();
          this.scrollCarousel();
        },
      });
  }

  checkEPGForGaps(data) {
    data.forEach((channel) => {
      let prevEnddate = '';
      let gaps = [];
      for (let i = 0; i < channel.epgs.length; i++) {
        const epg = channel.epgs[i];
        if (prevEnddate != epg.startDate && prevEnddate != '') {
          console.error(`GAP DETECTED IN ${channel.name} AT POSITION ${i}`);
          gaps.push({ startDate: prevEnddate, endDate: epg.startDate, index: i });
        }
        prevEnddate = epg.endDate;
      }
      if (gaps.length > 0) {
        gaps.forEach((el) => {
          const emptyEl = {
            endDate: el.endDate,
            startDate: el.startDate,
          };
          channel.epgs.splice(el.index, 0, emptyEl);
          channel.epgs.join();
        });
      }
    });
  }

  previousDay(e: Date): void {
    this.wasItNextOrPrevious.set('previous');
    this.setCurrentEpgGridDate(e);
  }

  nextDay(e: Date): void {
    this.wasItNextOrPrevious.set('next');
    this.setCurrentEpgGridDate(e);
  }

  private hasResults(): boolean {
    let result: boolean = false;
    this.channelGrid.forEach((channel) => {
      if (channel.epgs.length) {
        result = true;
      }
    });
    return result;
  }

  private getLiveItemsForBanner() {
    this.liveTimeStore.liveTimeSeconds$.pipe(take(1)).subscribe((liveTimeSeconds) => {
      const result: BannerSlideInterface[] = [];
      this.channelGrid.forEach((channel) => {
        channel.epgs.forEach((item) => {
          let startDate = new Date(item.startDate);
          let endDate = new Date(item.endDate);
          if (this.epgService.getSecondsTotal(startDate) <= liveTimeSeconds && this.epgService.getSecondsTotal(endDate) > liveTimeSeconds) {
            result.push({
              label: '',
              image: channel.largeFrontCover,
              channelId: channel.mainProductId,
              epgItem: item,
              channel: channel,
            });
          }
        });
      });
      this.bannerSlides.set(result);
    });
  }

  private scrollToLive() {
    const mainContainer = document.getElementsByTagName('main')[0];
    const mainContainerRectangle = mainContainer.getBoundingClientRect();
    const liveTimeContainer = this.liveTime?.nativeElement;
    const liveTimeContainerRectangle = liveTimeContainer.getBoundingClientRect();

    const liveTickerOffset = liveTimeContainerRectangle.x + liveTimeContainerRectangle.width / 2 - mainContainerRectangle.width / 2;
    document.getElementById('channel-grid-body')?.scrollBy({ left: liveTickerOffset, behavior: 'smooth' });
  }

  private scrollToBeginning() {
    document.getElementById('time-00:00')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  private scrollCarousel(): void {
    if (this.wasItNextOrPrevious() === 'next') {
      document.getElementById('time-00:00')?.scrollIntoView();
    }
    if (this.wasItNextOrPrevious() === 'previous') {
      document.getElementById('time-23:00')?.scrollIntoView();
    }
    if (this.hasResults()) {
      this.scrollToLive();
    } else {
      this.scrollToBeginning();
    }
  }
  closePopUp() {
    this.activeItem = null;
    this.popUpOpen = false;
  }

  setItem(item) {
    this.activeItem = item;
    this.popUpOpen = true;
  }
}
