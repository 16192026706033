import { Pipe, PipeTransform } from '@angular/core';

export const logoLight = 'assets/images/logo-192tv.svg';
export const logoDark = 'assets/images/logo-192tv.svg';

@Pipe({
  name: 'logo',
  standalone: true,
})
export class LogoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'dark':
        return logoDark;
      default:
        return logoLight;
    }
  }

}
