<ng-container *ngIf="tvPageData$ | async as tvPageData">
  <app-banner [epgType]="'multi'" [loading]="gridLoading()" [bannerSlides]="bannerSlides()"></app-banner>
  <div id="epg">
    <app-epg-dates (setNextDay)="nextDay($event)" (setPreviousDay)="previousDay($event)" [currentDate]="currentDate" [loaded]="gridLoaded()" />
    <div appIntersectionObserver id="channel-grid-bg">
      <div id="loader" *ngIf="gridLoading()">
        <shared-spinner />
      </div>
      <div id="epg-wrapper" [ngClass]="{ 'is-loading': gridLoading() }">
        <div id="logo-grid-body">
          <h5 class="epg-column-header logos-column-header">&nbsp;</h5>
          <div *ngFor="let channel of channelGrid" class="channel-logo">
            <a routerLink="/tv/channel/{{ channel.mainProductId }}"><img src="{{ channel.largeFrontCover }}" alt="{{ channel.name }} logo" /></a>
          </div>
        </div>
        <div id="channel-grid-body">
          <div *ngIf="tvPageData.liveDate" #liveTime id="live-time" [ngClass]="{ 'is-not-today': !isToday() }" [ngStyle]="{ left: tvPageData.liveDate | timelineLeftOffset }">
            <h5 class="my-0">{{ tvPageData.liveTimeSeconds | convertSeconds: true }}</h5>
          </div>
          <div id="channel-grid">
            @for (hourAsSeconds of hoursAsSecondsScale; track hourAsSeconds) {
              <h5 class="epg-column-header" [ngClass]="{ 'time-2300': $last, 'time-0000': $first }" id="time-{{ hourAsSeconds | convertSeconds: false }}">
                {{ hourAsSeconds | convertSeconds: false }}
              </h5>
            }
            <ng-container *ngFor="let channel of channelGrid">
              @if (channel.epgs.length) {
                <epg-grid-item (setNewItem)="setItem($event)" *ngFor="let item of channel.epgs" [channelId]="channel.mainProductId" [item]="item"></epg-grid-item>
              } @else {
                <div class="no-programs"><p i18n>Er is op dit moment geen informatie beschikbaar in de gids</p></div>
              }
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<shared-modal *ngIf="popUpOpen && activeItem" (closeModal)="closePopUp()">
  <app-item-card [channelId]="activeItem.channelId" [itemId]="activeItem.productId" [itemStartDate]="activeItem.startDate" />
</shared-modal>
