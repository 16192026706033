import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { CrwStoreService } from '../../stores/crw-store.service';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { GeneralStoreService } from '../../stores/general-store.service';
import { SubscriptionDurationInterface, SubscriptionInterface } from '../../models/subscription.model';
import { FilterByDurationPipe } from '../../pipes/filter-by-duration.pipe';
import { BeautifyDurationPipe } from '../../pipes/beautify-duration.pipe';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FormRadioGroupComponent } from '../../components/forms/components/control/form-radio-group/form-radio-group.component';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { getUniqueArrayByProperty } from '../../functions/utilities';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { FormRadioComponent } from '../../components/forms/components/control/form-radio/form-radio.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
interface CardSubscriptionsData {
  subscriptions: SubscriptionInterface[];
  durations: SubscriptionDurationInterface[];
  crwSubCart: SubscriptionInterface[];
}
@Component({
  selector: 'app-card-subscriptions',
  templateUrl: './card-subscriptions.component.html',
  styleUrls: ['./card-subscriptions.component.scss'],
  standalone: true,
  providers: [{ provide: ErrorMessagesStoreService }],
  imports: [SubmitComponent, SpinnerComponent, FormRadioComponent, NgIf, NgFor, NgClass, AsyncPipe, BeautifyDurationPipe, FilterByDurationPipe, FormRadioGroupComponent, ReactiveFormsModule, ErrorMessagesComponent, GetFormControlPipe],
})
export class CardSubscriptionsComponent extends FormComponent implements OnInit {
  private generalStore = inject(GeneralStoreService);
  private crwStore = inject(CrwStoreService);
  private router = inject(Router);

  cardSubscriptionsData$: Observable<CardSubscriptionsData>;

  subscriptions: WritableSignal<SubscriptionInterface[]> = signal([]);
  chosenSubscriptionInForm: WritableSignal<SubscriptionInterface | null> = signal(null);
  chosenSubscriptionInCart: WritableSignal<SubscriptionInterface[]> = signal([]);
  chosenSubscriptionDuration: WritableSignal<SubscriptionDurationInterface | null> = signal(null);

  override formGroup = this.formBuilder.group({
    subscription: this.formBuilder.group({
      productId: this.formBuilder.control<number>(0, [Validators.required]),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    this.cardSubscriptionsData$ = combineLatest([this.generalStore.subscriptions$, this.generalStore.subscriptionDurations$, this.crwStore.crwSubCart$]).pipe(
      map(([subscriptions, tmpDurations, crwSubCart]) => {
        this.subscriptions.set(subscriptions);
        this.chosenSubscriptionInCart.set(crwSubCart);
        const durations = getUniqueArrayByProperty(tmpDurations, 'periodUnit');
        if (!this.chosenSubscriptionDuration()) {
          this.chosenSubscriptionDuration.set(durations[0]);
        }
        this.updateSubscriptionsForm();
        this.setFirstActive(subscriptions);
        return {
          subscriptions,
          durations,
          crwSubCart,
        };
      }),
    );
  }

  private cartIsEmpty(): boolean {
    return this.chosenSubscriptionInCart()?.length < 1;
  }

  setFirstActive(subs) {
    if (this.chosenSubscriptionInCart()?.length === 0) {
      if (subs.length > 1 && this.chosenSubscriptionDuration()) {
        const filteredArray = subs.filter((itm) => {
          return itm.periodUnit === this.chosenSubscriptionDuration().periodUnit;
        });
        this.subscriptionChosen(null, filteredArray[0]);
        this.formGroup.get('subscription.productId').setValue(filteredArray[0].productId);
        this.formGroup.get('subscription.productId').markAsDirty();
      }
    }
  }

  changeSubscriptionDuration(option: SubscriptionDurationInterface) {
    if (this.cartIsEmpty()) {
      this.clearSubscriptionChoiceInForm();
    }
    this.chosenSubscriptionDuration.set(option);
    this.generalStore.subscriptions$.subscribe((res) => {
      this.setFirstActive(res);
    });
  }

  subscriptionChosen(e: Event, subscription: SubscriptionInterface): void {
    if (this.chosenSubscriptionInCart()?.length > 0) {
      this.crwStore.clearCart();
      this.crwStore.setCrwSubCart(subscription);
    }
  }

  skip() {
    this.crwStore.clearCart();
  }

  next(e: SubscriptionInterface[]): void {
    this.router.navigate(['/crw/payment']);
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const subscriptionControls = this.formGroup.get('subscription') as FormGroup;
    const chosenSubscription = this.subscriptions().find((s) => +s.productId === +subscriptionControls.value.productId);
    if (this.submittable(subscriptionControls) && chosenSubscription) {
      this.chosenSubscriptionInForm.set(chosenSubscription);
      this.crwStore.setCrwSubCart(chosenSubscription);
    }
  }

  private clearSubscriptionChoiceInForm(): void {
    this.chosenSubscriptionInForm.set(null);
    this.formGroup.get('subscription.productId').setValue(null);
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  private updateSubscriptionsForm() {
    if (this.chosenSubscriptionInCart().length > 0) {
      const subscriptionInCart = this.chosenSubscriptionInCart()[0];
      this.chosenSubscriptionInForm.set(subscriptionInCart);
      this.changeSubscriptionDuration({ period: subscriptionInCart.period, periodUnit: subscriptionInCart.periodUnit });
      this.formGroup.get('subscription.productId').setValue(subscriptionInCart.productId);
    } else {
      this.chosenSubscriptionInForm.set(null);
      this.formGroup.get('subscription.productId').setValue(null);
    }
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  sanitizeUspVal(val) {
    const valToDate = new Date(val);
    const isValidDate = !valToDate.toString().includes('Thu Jan 01 1970 01:00:00');
    if (isValidDate) {
      return valToDate.toDateString();
    } else if (typeof val == 'boolean') {
      return '';
    } else {
      return val;
    }
  }
}
