import { DOCUMENT } from "@angular/common";
import { Component, ChangeDetectionStrategy, OnInit, inject } from "@angular/core";
import { RouterOutlet, Router } from "@angular/router";
import { ApplicationEvent } from "./services/application.service";
import { ApplicationStoreService } from "./stores/application-store.service";
import { registerLocaleData } from '@angular/common';

import en from '@angular/common/locales/en';
import nl from '@angular/common/locales/nl';
import { FrontendCookieStorageService } from "./services/storage/frontend-cookie-storage.service";

registerLocaleData(en, 'en');
registerLocaleData(nl, 'nl');

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit {

  public router = inject(Router);

  private applicationStore = inject(ApplicationStoreService);
  private document = inject(DOCUMENT);
  private language = inject(FrontendCookieStorageService);

  constructor() {}

  ngOnInit(): void {
    this.document.documentElement.lang = this.language.getItem('language');
    this.applicationStore.setApplicationEvent(ApplicationEvent.Prime);
  }
}
