import { Component, inject } from '@angular/core';
import { LogoPipe } from '../../pipes/logo.pipe';
import { NgStyle } from '@angular/common';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { CardResetPincodeComponent } from '../../cards/card-reset-pincode/card-reset-pincode.component';
@Component({
  selector: 'app-change-pincode-page',
  templateUrl: './change-pincode-page.component.html',
  styleUrls: ['./change-pincode-page.component.scss'],
  standalone: true,
  imports: [NgStyle, CardResetPincodeComponent, LogoPipe],
})
export class ChangePincodePageComponent {
  private backgroundImageStore = inject(BackgroundImageStoreService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage('background-192tv.png');
    localStorage.removeItem('resetActive');
  }
}
