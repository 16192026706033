import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { AccountInterface } from '../../models/account.model';
import { combineLatest, map, Observable } from 'rxjs';
import { UserAccountService } from '../../services/user-account.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { CrwStoreService } from '../../stores/crw-store.service';
import { ErrorService } from '../../services/error.service';
import { SubscriptionInterface } from '../../models/subscription.model';
import { PaymentMethodInterface } from '../../models/payment-method.model';

interface CrwPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
  crwFlowSection: CrwFlowSection;
  subscription: SubscriptionInterface;
  crwPaymentMethod: PaymentMethodInterface;
}

@Component({
  selector: 'app-side-menu-crw',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgIf, NgClass, AsyncPipe],
  templateUrl: './side-menu-crw.component.html',
  styleUrl: './side-menu-crw.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuCrwComponent implements OnInit {
  crwSection = CrwFlowSection;
  crwPageData$!: Observable<CrwPageData>;

  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private crwStore = inject(CrwStoreService);
  private errorService = inject(ErrorService);

  ngOnInit(): void {
    this.crwPageData$ = combineLatest(
      [
        this.userAccountStore.isLoggedIn$,
        this.userAccountStore.userAccount$,
        this.crwStore.crwSection$,
        this.crwStore.crwSubCart$,
        this.crwStore.crwPaymentMethod$
      ]
    ).pipe(
      map(([isLoggedIn, userAccount, crwFlowSection, crwSubCart, crwPaymentMethod]) => {
        const subscription = crwSubCart[0];
        return {
          isLoggedIn,
          userAccount,
          crwFlowSection,
          subscription,
          crwPaymentMethod,
        };
      }),
    );
  }
}
