import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestroyService } from '../../../../services/destroy.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ErrorService } from '../../../../services/error.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Q90ErrorResponseTypes } from '../../../../interfaces/q90-response';
import { ErrorMessagesStoreService } from '../../../../stores/error-messages-store.service';
import { Q90Response, Q90ResponseLevels } from '../../../../models/q90-response';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'shared-form',
  standalone: true,
  imports: [CommonModule],
  providers: [{ provide: ErrorMessagesStoreService }],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormComponent implements OnInit {
  public LABEL_USERNAME = $localize`Gebruikersnaam`;
  public LABEL_PASSWORD = $localize`Wachtwoord`;
  public LABEL_PASSWORD_CONFIRM = $localize`Wachtwoord bevestigen`;
  public LABEL_PASSWORD_REPEAT = $localize`Wachtwoord herhalen`;
  public LABEL_NEW_PASSWORD = $localize`Nieuw wachtwoord`;
  public LABEL_NEW_PASSWORD_CONFIRM = $localize`Nieuw wachtwoord bevestigen`;
  public LABEL_NEW_PINCODE = $localize`Nieuwe toegangscode`;
  public LABEL_NEW_PINCODE_CONFIRM = $localize`Nieuwe toegangscode bevestigen`;
  public LABEL_EMAIL = $localize`E-mail`;
  public LABEL_EMAIL_CONFIRM = $localize`E-mail bevestigen`;
  public LABEL_EMAIL_REPEAT = $localize`E-mail herhalen`;
  public LABEL_NEW_EMAIL = $localize`Nieuw e-mail adres`;
  public LABEL_NEW_EMAIL_CONFIRM = $localize`Nieuw e-mail adres bevestigen`;
  public LABEL_NAME = $localize`Naam`;
  public LABEL_FIRST_NAME = $localize`Voornaam`;
  public LABEL_LAST_NAME = $localize`Achternaam`;
  public LABEL_LANGUAGE = $localize`Taal`;
  public LABEL_NEWSLETTER = $localize`Nieuwsbrief`;
  public LABEL_SUBJECT = $localize`Onderwerp`;
  public LABEL_TELEPHONE = $localize`Telefoon`;
  public LABEL_MESSAGE = $localize`Bericht`;
  public LABEL_ACCOUNT_EMAIL = $localize`Account e-mail`;
  public LABEL_ACCESS_CODE = $localize`Toegangscode`;
  public LABEL_NEW_ACCESS_CODE = $localize`Nieuwe toegangscode`;
  public LABEL_HAS_ACCESS_CODE = $localize`Heeft toegangscode`;
  public LABEL_KIDS_MODE = $localize`Kindermodus`;
  public LABEL_ACTIVATION_KEY = $localize`Activatiesleutel`;
  public LABEL_ENABLED = $localize`Ingeschakeld`;
  public LABEL_DISABLED = $localize`Ingeschakeld`;
  public LABEL_PROFILE_IMAGE = $localize`Profielafbeelding`;
  public LABEL_PAIRING_CODE = $localize`Koppelcode`;
  public LABEL_VOUCHER_CODE = $localize`Kortingscode`;

  public LABEL_BUTTON_SAVE = $localize`Opslaan`;
  public LABEL_BUTTON_SEND = $localize`Versturen`;
  public LABEL_BUTTON_LOGIN = $localize`Login`;
  public LABEL_BUTTON_NEXT = $localize`Volgende`;
  public LABEL_BUTTON_PREVIOUS = $localize`Vorige`;
  public LABEL_BUTTON_REDEEM = $localize`Inwisselen`;
  public LABEL_BUTTON_YES_DELETE = $localize`Ja, verwijderen`;
  public LABEL_BUTTON_YES_CANCEL = $localize`Ja, annuleren`;
  public LABEL_BUTTON_PAIR_DEVICE = $localize`Device koppelen`;
  public LABEL_BUTTON_DELETE_ACCOUNT = $localize`Account verwijderen`;
  public LABEL_BUTTON_CREATE_ACCOUNT = $localize`Account aanmaken`;
  public LABEL_BUTTON_RESET_PASSWORD = $localize`Wachtwoord resetten`;
  public LABEL_BUTTON_RESET_PINCODE = $localize`Toegangscode resetten`;
  public LABEL_BUTTON_ADD_TO_CART = $localize`Toevoegen aan winkelwagen`;
  public LABEL_BUTTON_ENTER_ACTIVATION_KEY = $localize`Voer activatiesleutel in`;
  public LABEL_BUTTON_CANCEL_SUBSCRIPTION = $localize`Abonnement annuleren`;
  public LABEL_BUTTON_CANCEL_CURRENT_SUBSCRIPTION = $localize`Huidige abonnement annuleren`;

  public translations = inject(TranslationsService);
  protected errorService = inject(ErrorService);
  protected errorMessagesStore = inject(ErrorMessagesStoreService);
  protected destroyed = inject(DestroyService);
  protected formBuilder = inject(FormBuilder);
  public invalidFields: Record<string, string[]> = {};

  @Output() isSubmitted = new EventEmitter<FormComponent>();
  @Output() cancelEdit = new EventEmitter<boolean>();

  /**
   * Whether form is being submitted.
   *
   * Q90 uses the Behavior subject at the moment. Should migrate to use of signal. Leave both
   * here for now.
   */
  protected submittingStore = new BehaviorSubject<boolean>(false);
  submitting$: Observable<boolean> = this.submittingStore.asObservable();

  /**
   * Signal for submitting
   */
  submitting: WritableSignal<boolean> = signal(false);

  public abstract formGroup: FormGroup;
  protected submitted = false;

  ngOnInit(): void {}

  submittable(formGroup: FormGroup | FormArray = this.formGroup): boolean {
    let result: boolean = true;
    if (!formGroup.valid) {
      result = false;
      this.setFormMessage(this.translations.getTranslation(this.translations.FORM_HAS_ERRORS));
    }
    if (formGroup.pristine) {
      result = false;
      this.setFormMessage(this.translations.getTranslation(this.translations.FORM_HAS_NOT_BEEN_CHANGED));
    }
    return result;
  }

  onSubmit(e?: Event) {
    this.submitted = true;
    this.setInvalidFields();
    this.isSubmitted.emit(this);
  }

  cancelMe(e: Event): void {
    this.errorMessagesStore.setErrorMessage(null);
    this.cancelEdit.emit(true);
  }

  formClicked(e: Event): void {
    this.errorMessagesStore.setErrorMessage(null);
    this.formGroup.updateValueAndValidity();
  }

  setErrorMessage(message: Q90ErrorResponseTypes) {
    this.errorMessagesStore.setErrorMessage(message);
  }

  setFormMessage(message: string, level: Q90ResponseLevels = Q90ResponseLevels.Warning) {
    const msg = Q90Response.message(message, level);
    this.errorMessagesStore.setErrorMessage(msg);
  }

  setSubmitting(setting: boolean) {
    this.submittingStore.next(setting);
  }

  clearFormArray(formArrayControl: FormArray): void {
    while (formArrayControl.length !== 0) {
      formArrayControl.removeAt(0);
    }
  }

  setInvalidFields(formGroupName: string | null = null, invalidFields: Record<string, string[]> = {}): void {
    const formGroup = (formGroupName ? this.formGroup.get(formGroupName) : this.formGroup) as FormGroup;
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        if (!control.valid) {
          invalidFields[controlName] = [];
          this.setInvalidFields(controlName, invalidFields);
        }
      } else {
        if (control instanceof FormControl) {
          if (!control.valid) {
            invalidFields[formGroupName!].push(controlName);
          }
        }
      }
    }
    this.invalidFields = invalidFields;
  }

  // Obsolete if your formGroup structure matches your api call payload structure. In this case,
  // use const myPayloadData = myFormGroup.values to 'construct' query for API update call.
  getQueryFromFormGroup<T, K extends keyof T>(formGroupName: string | null = null, query: Partial<T> = {}): Partial<T> {
    const formGroup = (formGroupName ? this.formGroup.get(formGroupName) : this.formGroup) as FormGroup;
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        this.getQueryFromFormGroup(controlName, query);
      } else {
        if (control instanceof FormControl) {
          query[<K>controlName] = control!.value;
        }
      }
    }
    return query;
  }

  /**
   * Recursively populates a formGroup of like structure with values in entity
   * Perhaps this can be replaced with use of the patchValue() call on FormGroups. ???
   *
   * @param entity
   * @param formGroup
   * @param controlNameList
   */
  protected populateForm<T, K extends keyof T>(entity: T, formGroup: FormGroup | string = this.formGroup, controlNameList: string[] = []): void {
    if (typeof formGroup === 'string') {
      formGroup = this.formGroup.get(formGroup) as FormGroup;
    }
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        this.populateForm<T, K>(entity, control, [...controlNameList, controlName]);
      }
      if (control instanceof FormArray) {
        control.controls.forEach((control2, i) => {
          if (control2 instanceof FormGroup) {
            this.populateForm<T, K>(entity, control2, [...controlNameList, controlName, i.toString()]);
          }
        });
      }
      if (control instanceof FormControl) {
        control.patchValue(this.deepGetWrapper(entity, [...controlNameList, controlName]));
      }
    }
  }

  // https://www.30secondsofcode.org/js/s/deep-get-object-value/
  private deepGetWrapper(entity: any, pathValue: string[] = []): any {
    const deepGet = (entity: any, pathValue: string[] = []) => pathValue.reduce((xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null), entity);
    const result = deepGet(entity, pathValue);
    return result;
  }
}
