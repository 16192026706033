<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }">
    <div class="form-label flex-layout">
      <label
        for="{{ id }}"
        [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
        >{{ label }}</label
      >
      <ng-content select="[afterControlLabel]"></ng-content>
    </div>
    <input
      id="{{ id }}"
      [formControl]="control | getAsFormControl"
      autocapitalize="off"
      [attr.placeholder]="showPlaceholder ? placeholder + '&#8230;' : null" />
      <p i18n class="sub">Let op, gebruikersnaam is hoofdletter gevoelig</p>
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }">
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{ getErrorMessage() }}</p>
  </div>
  <ng-content select="[afterControlField]"></ng-content>
</ng-container>
