import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest, map, take } from 'rxjs';
import { SubscriptionInterface } from '../../models/subscription.model';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { CrwStoreService } from '../../stores/crw-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { SubscriptionService } from '../../services/subscription.service';
import { UserAccountService } from '../../services/user-account.service';
import { RouterLink } from '@angular/router';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { BeautifyDatePipe } from "../../pipes/beautify-date.pipe";
import { FormInputHiddenComponent } from '../../components/forms/components/control/form-input-hidden/form-input-hidden.component';
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";

interface CardYourSubscriptionData {
  crwFlowSection: CrwFlowSection;
  subscriptions: SubscriptionInterface[];
  userSubscriptions: SubscriptionInterface[];
  validSubscriptions: SubscriptionInterface[];
  invalidSubscriptions: SubscriptionInterface[];
}
@Component({
  selector: 'app-card-subscription-your',
  standalone: true,
  templateUrl: './card-subscription-your.component.html',
  styleUrl: './card-subscription-your.component.scss',
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormInputHiddenComponent, CommonModule, NgIf, RouterLink, NgClass, AsyncPipe, ReactiveFormsModule, SubmitComponent, ErrorMessagesComponent, BeautifyDatePipe, GetFormControlPipe]
})
export class CardSubscriptionYourComponent extends FormComponent implements OnInit {

  isSubmitting: boolean = false;
  cancelMode: boolean = false;
  subscriptionId: number = 0;

  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private UserAccountService = inject(UserAccountService);
  private crwStore = inject(CrwStoreService);
  private subscriptionService = inject(SubscriptionService);

  cardYourSubscriptionData$: Observable<CardYourSubscriptionData>;

  override formGroup = this.formBuilder.group({
    productId: this.formBuilder.control<number>(0, [Validators.required]),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.cardYourSubscriptionData$ = combineLatest(
      [
        this.crwStore.crwSection$,
        this.generalStore.subscriptions$,
        this.userAccountStore.userAccountSubscriptions$,
        this.userAccountStore.validSubscriptions$,
        this.userAccountStore.invalidSubscriptions$,
        this.userAccountStore.currentSubscription$,
      ]
    ).pipe(
      map(([crwFlowSection, subscriptions, userSubscriptions, validSubscriptions, invalidSubscriptions, currentSubscription]) => {
        if (currentSubscription) {
          this.formGroup.get('productId').setValue(currentSubscription.productId);
          this.formGroup.markAsDirty();
        }
        return {
          crwFlowSection,
          subscriptions,
          userSubscriptions,
          validSubscriptions, invalidSubscriptions, currentSubscription
        };
      }),
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const cancelControls = this.formGroup;
    if (this.submittable(cancelControls)) {
      this.submitting.set(true);
      this.subscriptionService
        .endSubscription(+cancelControls.value.productId)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.submitting.set(false);
            if (XperienceResponse.isSuccess(res)) {
              this.setFormMessage(this.translations.getTranslation(this.translations.FORM_SUBSCRIPTION_CANCEL_SUCCESS), Q90ResponseLevels.Success);
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            this.submitting.set(false);
            this.formGroup.markAsPristine();
          },
        });
    }
  }

  setCancelMode(id: number) {
    this.cancelMode = id !== 0;
    this.subscriptionId = id;
  }

}
