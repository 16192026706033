import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { CardAccountDeleteComponent } from '../../cards/card-account-delete/card-account-delete.component';
import { CardAccountComponent } from '../../cards/card-account/card-account.component';
import { CardEmailComponent } from '../../cards/card-email/card-email.component';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { CardPasswordComponent } from '../../cards/card-password/card-password.component';
import { SideMenuHelpComponent } from '../../components/side-menu-help/side-menu-help.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccountInterface } from '../../models/account.model';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { CardContactComponent } from '../../cards/card-contact/card-contact.component';

interface ContactPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
}
@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf, SideMenuComponent, CardAccountComponent, CardPairingComponent, CardPasswordComponent, CardEmailComponent,
    AsyncPipe,
    CardAccountDeleteComponent,
    SideMenuHelpComponent,
    CardContactComponent
  ]
})
export class ContactPageComponent implements OnInit {

  contactPageData$: Observable<ContactPageData>;

  showDeleteAccountCard: WritableSignal<boolean> = signal(false);

  private profilesStore = inject(ProfilesStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  constructor() {
  }

  ngOnInit(): void {
    this.contactPageData$ = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([isLoggedIn, userAccount]) => {
          return {
            isLoggedIn, userAccount
          };
        }
      )
    );
  }

}
