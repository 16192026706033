<ng-container [formGroup]="formGroup">
  <div class="form-control {{styleClass}} {{additionalStyleClasses}}"
       [ngClass]="{'hide-errors-hints':!(showErrorHints)}">
    <ng-container *ngFor="let el of options | keyvalue">
      <label for="{{ id }}" [ngClass]="{'visually-hidden': !showLabel, 'disabled': isDisabled}">{{ el.key }}</label>
      <input name='controlName'
             type="radio"
             [formControl]="control | getAsFormControl"
             id="{{el.value}}"
             value="{{el.value}}">
    </ng-container>
    <div class="submit-warning" [ngClass]="{'invalid-after-submit': submitted && control.invalid}">
      <span class='mci-warning'></span></div>
  </div>
  <div class="form-errors {{styleClass}}" *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{ getErrorMessage() }}</p>
  </div>
</ng-container>
