import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoaderComponent } from '../../components/loader/loader.component';
import { TopbarComponent } from '../../components/topbar/topbar.component';
import { CookieDisclaimerComponent } from '../../shared/components/cookie-disclaimer/cookie-disclaimer.component';
import { CookieDisclaimerService } from '../../services/cookie-disclaimer.service';

interface LayoutAccountData {
  darkMode: boolean;
  isLoggedIn: boolean;
}
@Component({
  selector: 'app-layout-help',
  standalone: true,
  imports: [
    TopbarComponent,
    RouterOutlet,
    LoaderComponent,
    NgIf,
    AsyncPipe,
    CookieDisclaimerComponent
  ],
  templateUrl: './layout-help.component.html',
  styleUrl: './layout-help.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LayoutHelpComponent implements OnInit {

  layoutAccountData$!: Observable<LayoutAccountData>;
  public router = inject(Router);

  cookieDisclaimerService = inject(CookieDisclaimerService);
  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  constructor() {
  }

  ngOnInit(): void {
    this.layoutAccountData$ = combineLatest([
      this.generalStore.darkMode$,
      this.userAccountStore.isLoggedIn$,
    ]).pipe(
      map(
        ([
          darkMode,
          isLoggedIn,
        ]) => {
          return {
            darkMode,
            isLoggedIn,
          };
        }
      )
    );
  }

}
