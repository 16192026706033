import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlComponent } from '../form-control.component';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { PasswordValidator } from '../../../validators/password-validator';

@Component({
  selector: 'shared-form-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetAsFormControlPipe],
  templateUrl: './form-password.component.html',
  styleUrls: ['./form-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPasswordComponent extends FormControlComponent implements OnInit {
  showPassword: boolean = false;

  passwordMissingUpperCaseLetter = $localize`:@@passwordMissingUpperCase:Wachtwoord mist een hoofdletter.`;
  passwordMissingLowerCaseLetter = $localize`:@@passwordMissingLowerCase:Wachtwoord mist een kleine letter.`;
  passwordMissingDigit = $localize`:@@passwordMissingDigit:Wachtwoord mist een nummer.`;
  passwordMissingSpecialCharacter = $localize`:@@passwordMissingSpecialCharacter:Wachtwoord mist een speciaal teken.`;
  passwordLengthIncorrect = $localize`:@@passwordLengthIncorrect:Wachtwoord moet tussen 8 en 24 tekens lang zijn.`;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.shouldValidate) {
      this.control.addValidators([Validators.required, PasswordValidator.passwordFormat()]);
    } else {
      this.control.addValidators([Validators.required]);
    }
  }

  override getErrorMessage(): string {
    if (this.control.hasError('noUpper')) return this.passwordMissingUpperCaseLetter;
    if (this.control.hasError('noLower')) return this.passwordMissingLowerCaseLetter;
    if (this.control.hasError('noDigit')) return this.passwordMissingDigit;
    if (this.control.hasError('noSpecialChar')) return this.passwordMissingSpecialCharacter;
    if (this.control.hasError('invalidLength')) return this.passwordLengthIncorrect;

    return super.getErrorMessage();
  }
}
