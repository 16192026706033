import { Component, OnInit } from "@angular/core";
import { CrwFlowSection, CrwPageComponent } from "../crw-page/crw-page.component";
import { CardOverviewComponent } from "../../cards/card-overview/card-overview.component";

@Component({
  selector: "app-crw-overview",
  standalone: true,
  imports: [
    CardOverviewComponent
  ],
  templateUrl: "./crw-overview.component.html",
  styleUrl: "./crw-overview.component.scss"
})
export class CrwOverviewComponent extends CrwPageComponent implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwStore.setCrwFlow(CrwFlowSection.Overview);
  }
}
