import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertSeconds',
  standalone: true
})

export class ConvertSecondsPipe implements PipeTransform {
  transform(seconds: number, secondsVisible: boolean): string {
    const timeEntry = new Date();
    timeEntry.setHours(0, 0, seconds, 0);
    if (secondsVisible) {
      return timeEntry.getHours().toString().padStart(2, '0') + ":" + timeEntry.getMinutes().toString().padStart(2, '0') + ":" + timeEntry.getSeconds().toString().padStart(2, '0');
    } else {
      return timeEntry.getHours().toString().padStart(2, '0') + ":00";
    }
    // let h: any = Math.floor(seconds / 3600);
    // seconds = seconds - (h * 3600);
    // let m: any = Math.floor(seconds / 60);
    // seconds = seconds - m * 60;
    // let s: any = seconds;
    //
    // h = h < 10 ? "0" + h : h;
    // m = m < 10 ? "0" + m : m;
    // s = s < 10 ? "0" + s : s;
    //
    // let formattedTime = h + ":" + m + (secondsVisible ? ":" + s : "");
    // (formattedTime == "24:00" || formattedTime == "24:00:00") ? formattedTime = "00:00" : formattedTime;
    //
    // return formattedTime;
  }
}
