import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 * Abstract interface to frontend data storage classes
 * 
 * In addition to a state management tool like RxJS subjects, data in Q90 frontend can be stored
 * in localStorage, sessionStorage (see https://developer.mozilla.org/en-US/docs/Web/API/Storage),
 * or in cookies. The services FrontendLocalStorageService, FrontendSessionStorageService,
 * and FrontendCookieStorageService can be used to achieve this.
 */
export abstract class FrontendStorageService {

  constructor() { }

  abstract setItem(name: string, setting: string): void;
  abstract getItem(name: string): string | null;
  abstract removeItem(name: string): void;
  abstract clear(): void;
}
