<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <h3 i18n>Koppelcode</h3>
    </header>
    <p i18n>Voer in het onderstaande veld de koppelcode in die wordt weergegeven op de TV-app.</p>
    <fieldset
      *ngIf="!pairingSuccess"
      formGroupName="pairing">
      <shared-form-pairing-code
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_PAIRING_CODE"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'pairing.token'" />
    </fieldset>
    <footer>
      <div
        *ngIf="pairingSuccess"
        class="button-bar align-left">
        <a
          i18n
          *ngIf="withBackToLink"
          routerLink="/tv"
          class="button is-colored">
          Terug
        </a>
      </div>
      <div
        *ngIf="!pairingSuccess"
        class="button-bar align-left">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_PAIR_DEVICE"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
        <a
          i18n
          *ngIf="withBackToLink"
          routerLink="/tv"
          class="button is-transparent">
          Annuleren
        </a>
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
