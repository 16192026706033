import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule, NgClass } from '@angular/common';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutocompleteOffDirective } from '../../components/forms/directives/autocomplete-off.directive';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { take } from 'rxjs';
import { FormPincodeComponent } from '../../components/forms/components/control/form-pincode/form-pincode.component';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { FormPincodeConfirmComponent } from '../../components/forms/components/control/form-pincode-confirm/form-pincode-confirm.component';
import { ProfilesService } from '../../services/profiles.service';

@Component({
  selector: 'app-card-reset-pincode',
  templateUrl: './card-reset-pincode.component.html',
  styleUrls: ['./card-reset-pincode.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, NgClass, AutocompleteOffDirective, ErrorMessagesComponent, GetFormControlPipe, ReactiveFormsModule, SubmitComponent, FormPincodeComponent, FormPincodeConfirmComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardResetPincodeComponent extends FormComponent implements OnInit {
  changeSuccess: WritableSignal<boolean> = signal(false);

  private resetKey: string = '';

  private userAccountStore = inject(UserAccountStoreService);

  private profilesService = inject(ProfilesService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  override formGroup = this.formBuilder.group({
    changePincode: this.formBuilder.group({
      accessCode: this.formBuilder.control<string>('', [Validators.required]),
      accessCodeConfirm: this.formBuilder.control<string>('', [Validators.required]),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.route.queryParams.pipe(take(1)).subscribe({
      next: (params) => {
        this.resetKey = params['key'];
        if (params['key']) localStorage.setItem('resetKey', params['key']);

        if (localStorage.getItem('resetKey') && !params['key']) this.resetKey = localStorage.getItem('resetKey');
      },
    });
    this.userAccountStore.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        localStorage.setItem('resetActive', '1');
        this.router.navigate(['/login']);
      }
    });
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const changePincodeControls = this.formGroup.get('changePincode') as FormGroup;
    if (this.submittable(changePincodeControls)) {
      this.submitting.set(true);
      this.profilesService
        .resetPincode(this.resetKey, changePincodeControls.value.accessCode)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.submitting.set(false);
            if (XperienceResponse.isSuccess(res)) {
              this.setFormMessage(this.translations.getTranslation(this.translations.FORM_PINCODE_RESET_SUCCESS), Q90ResponseLevels.Success);
              this.formGroup.reset();
              this.changeSuccess.set(true);
              localStorage.removeItem('resetKey');
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            this.formGroup.markAsPristine();
          },
        });
    }
  }
}
