<ng-container *ngIf="epgGridItemData$ | async as epgGridItemData">
  <div
    class="channel-grid-item hours-span-{{ width() }}"
    [ngStyle]="{ width: width() }"
    [ngClass]="{ 'is-live': epgGridItemData.liveDate | dateBetweenDates: item.startDate : item.endDate }">
    <div *ngIf="item.mainProductId" class="block" (click)="setItem(channelId, item.mainProductId, item.startDate)">
      <h5 class="title my-0">{{ item.title }}</h5>
      <p class="time my-0">{{ item.startDate | date: "HH:mm" }} - {{ item.endDate | date: "HH:mm" }}</p>
    </div>
  </div>
</ng-container>
