<ng-container *ngIf="profilesData$ | async as profilesData">
  <div class="profiles">
    <h2
      i18n
      class="mt-0"
      *ngIf="!profilesData.userActiveProfile && !accessMode()">
      Wie kijkt er?
    </h2>
    <div
      class="profile-list"
      *ngIf="!editMode() && !accessMode()">
      <div *ngFor="let profile of profilesData.userProfiles">
        <app-card-profile
          [profile]="profile"
          [activeProfile]="profilesData.userActiveProfile?.profileId === profile.profileId"
          [editMode]="editMode()"
          [accessMode]="accessMode()"
          (cancelEdit)="setProfileLogin(null)"
          (changeAccessMode)="resetMode(true)"
          (click)="changeMode(profile, profilesData.userActiveProfile)" />
      </div>
      <div *ngIf="profilesData.userProfiles.length < maxNumberOfProfiles">
        <app-card-profile-add (onProfileAdd)="toAddProfile($event)" />
      </div>
    </div>
    <div
      class="profile-list"
      *ngIf="accessMode()">
      <app-card-profile
        [profile]="accessMode()"
        (changeAccessMode)="resetMode(true)"
        [activeProfile]="profilesData.userActiveProfile?.profileId === accessMode()?.profileId"
        [accessMode]="accessMode()" />
    </div>
    <div
      class="profile-list"
      *ngIf="editMode()">
      <app-card-profile
        [accessMode]="accessMode()"
        (changeAccessMode)="resetMode(true)"
        [profile]="editMode()"
        [activeProfile]="profilesData.userActiveProfile?.profileId === editMode()?.profileId"
        [editMode]="editMode()"
        (profileDeleted)="resetMode($event)" />
    </div>
  </div>

  <div
    class="button-bar align-center"
    *ngIf="profilesData.userActiveProfile">
    <button
      i18n
      (click)="toAccount($event)"
      *ngIf="!editMode() && !accessMode()"
      type="button">
      Terug naar account
    </button>
    <!-- <button
      i18n
      (click)="toProfiles($event)"
      *ngIf="editMode() || accessMode()"
      type="button">
      Terug naar profielen
    </button> -->
  </div>
</ng-container>
