<ng-container *ngIf="topBarData$ | async as topBarData">
  <div
    class="topbar"
    [ngClass]="{ 'dark-mode': topBarData.darkMode }">
    <nav class="grid md:hidden">
      <ul id="hamburger-list">
        <li id="hamburger">
          <button
            type="button"
            (click)="toggleHamburgerMenu()"
            class="is-transparent">
            <span class="mci-menu-hamburger-solid"></span>
          </button>
        </li>
        <li
          id="hamburger-menu"
          (click)="toggleHamburgerMenu()"
          *ngIf="showHamburgerMenu()">
          <ul>
            <li *ngIf="topBarData.isLoggedIn && !topBarData.currentSubscription">
              <a
                i18n
                class="button is-colored"
                routerLink="/crw/subscription">
                Abonnement afsluiten
              </a>
            </li>
            <li *ngIf="!topBarData.isLoggedIn">
              <a
                i18n
                class="is-transparent with-icon"
                routerLink="/crw/account">
                <span class="mci-user-single-solid"></span>
                Account aanmaken
              </a>
            </li>
            <li>
              <a
                class="with-icon"
                i18n
                id="tv-replay"
                routerLink="/tv">
                <span class="mci-tv-solid"></span>
                TV & Replay
              </a>
            </li>
            <li>
              <a
                class="with-icon"
                i18n
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLink="/help">
                <span class="mci-help-circle-solid"></span>
                Help Centrum
              </a>
            </li>
            <li>
              <a
                class="is-transparent with-icon"
                i18n
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLink="/info">
                <span class="mci-handshake"></span>
                Extra
              </a>
            </li>
            <li>
              <a
                class="is-transparent with-icon"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: false }"
                i18n
                routerLink="/pairing-code">
                <span class="mci-link-solid"></span>
                Koppelcode
              </a>
            </li>
          </ul>
        </li>
        <li>
          <app-logo />
        </li>
        <li *ngIf="!topBarData.isLoggedIn">
          <a
            i18n
            class="button is-colored"
            routerLink="/login">
            Inloggen
          </a>
        </li>
        <ng-container *ngTemplateOutlet="userAccount; context: { data: topBarData }" />
      </ul>
    </nav>

    <div
      id="md-nav"
      class="hidden md:grid grid-cols-2">
      <nav class="grid">
        <ul class="flex">
          <li>
            <app-logo />
          </li>
          <li>
            <a
              class="with-icon"
              i18n
              id="tv-replay"
              routerLink="/tv">
              <span class="mci-tv-solid"></span>
              TV & Replay
            </a>
          </li>
          <li>
            <a
              class="with-icon"
              i18n
              [routerLinkActive]="'is-active'"
              [routerLinkActiveOptions]="{ exact: false }"
              routerLink="/help">
              <span class="mci-help-circle-solid"></span>
              Help Centrum
            </a>
          </li>
          <li>
            <a
              class="is-transparent with-icon"
              i18n
              [routerLinkActive]="'is-active'"
              [routerLinkActiveOptions]="{ exact: false }"
              routerLink="/info">
              <span class="mci-handshake"></span>
              Extra
            </a>
          </li>
          <li>
            <a
              class="is-transparent with-icon"
              [routerLinkActive]="'is-active'"
              [routerLinkActiveOptions]="{ exact: false }"
              i18n
              routerLink="/pairing-code">
              <span class="mci-link-solid"></span>
              Koppelcode
            </a>
          </li>
        </ul>
      </nav>
      <nav
        id="account-nav"
        class="grid">
        <ul class="flex">
          <ng-container *ngIf="!topBarData.isLoggedIn">
            <li class="hidden md:flex">
              <a
                i18n
                class="is-transparent with-icon"
                routerLink="/crw/account">
                <span class="mci-user-single-solid"></span>
                Account aanmaken
              </a>
            </li>
            <li>
              <a
                i18n
                class="button is-colored"
                routerLink="/login">
                Inloggen
              </a>
            </li>
          </ng-container>
          <li *ngIf="topBarData.isLoggedIn && !topBarData.currentSubscription">
            <a
              i18n
              class="button is-colored"
              routerLink="/crw/subscription">
              Abonnement afsluiten
            </a>
          </li>
          <ng-container *ngTemplateOutlet="userAccount; context: { data: topBarData }" />
        </ul>
      </nav>
    </div>
  </div>
</ng-container>

<ng-template
  #userAccount
  let-data="data">
  <ng-container *ngIf="data.isLoggedIn && data.userActiveProfile">
    <li id="user-account">
      <a
        id="topbar-avatar"
        routerLinkActive="is-active"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLink="/account"
        ><core-avatar
          [avatarSrc]="data.userActiveProfile?.avatar"
          [size]="'sm'"
      /></a>
    </li>
  </ng-container>
</ng-template>
