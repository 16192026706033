import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'beautifyDate',
  standalone: true
})

export class BeautifyDatePipe implements PipeTransform {

  constructor(
    public datePipe: DatePipe
  ) { }

  transform(date) {

    let today = new Date();
    let yesterday = new Date();
    let tomorrow = new Date();
    let todayPlus2 = new Date();
    let todayPlus3 = new Date();
    let todayPlus4 = new Date();
    let todayPlus5 = new Date();
    let todayPlus6 = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    todayPlus2.setDate(todayPlus2.getDate() + 2);
    todayPlus3.setDate(todayPlus3.getDate() + 3);
    todayPlus4.setDate(todayPlus4.getDate() + 4);
    todayPlus5.setDate(todayPlus5.getDate() + 5);
    todayPlus6.setDate(todayPlus6.getDate() + 6);

    let d = new Date(date);
    let days = [$localize`Zondag`, $localize`Maandag`, $localize`Dinsdag`, $localize`Woensdag`, $localize`Donderdag`, $localize`Vrijdag`, $localize`Zaterdag`];

    if (d.toDateString() == today.toDateString()) {
      return $localize`Vandaag`;
    } else if (d.toDateString() == yesterday.toDateString()) {
      return $localize`Gisteren`;
    } else if (d.toDateString() == tomorrow.toDateString()) {
      return $localize`Morgen`;
    } else if (d.toDateString() == todayPlus2.toDateString()) {
      return days[d.getDay()];
    } else if (d.toDateString() == todayPlus3.toDateString()) {
      return days[d.getDay()];
    } else if (d.toDateString() == todayPlus4.toDateString()) {
      return days[d.getDay()];
    } else if (d.toDateString() == todayPlus5.toDateString()) {
      return days[d.getDay()];
    } else if (d.toDateString() == todayPlus6.toDateString()) {
      return days[d.getDay()];
    } else {
      return this.datePipe.transform(d, 'dd MMMM YYYY');
    }

  }

}
