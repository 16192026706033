import { Injectable, signal, WritableSignal } from '@angular/core';
import { ProfileInterface } from '../models/profile.model';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { LanguageInterface } from '../models/language.model';

@Injectable({
  providedIn: 'root',
})
export class ProfilesStoreService {
  private userProfilesStore = new BehaviorSubject<ProfileInterface[]>([]);
  userProfiles$: Observable<ProfileInterface[]> = this.userProfilesStore.asObservable();

  private pinResetProfileStore = new BehaviorSubject<ProfileInterface>(null);
  pinResetProfile$: Observable<ProfileInterface> = this.pinResetProfileStore.asObservable();

  private userActiveProfileStore = new BehaviorSubject<ProfileInterface | null>(null);
  userActiveProfile$: Observable<ProfileInterface | null> = this.userActiveProfileStore.asObservable();

  profileLanguage: WritableSignal<LanguageInterface | null> = signal(null);

  constructor() {}

  setUserProfiles(profiles: ProfileInterface[]): void {
    this.userProfilesStore.next(profiles);
  }

  setpinResetProfile(profile: ProfileInterface): void {
    this.pinResetProfileStore.next(profile);
  }
  getpinResetProfile(): BehaviorSubject<ProfileInterface> {
    return this.pinResetProfileStore;
  }

  setUserActiveProfile(activeProfile: ProfileInterface | null): void {
    this.userActiveProfileStore.next(activeProfile);
  }

  hasActiveProfile(): boolean {
    let result!: boolean;
    this.userActiveProfile$.pipe(take(1)).subscribe((activeProfile) => {
      if (activeProfile) {
        result = true;
      }
    });
    return result;
  }

  updateActiveProfile(profileId: number): void {
    this.userProfiles$.pipe(take(1)).subscribe((profiles) => {
      const filtered = (profiles as ProfileInterface[]).find((f) => f.profileId === profileId);
      this.userActiveProfileStore.next(filtered);
    });
  }

  updateProfile(profile: ProfileInterface): void {
    this.userProfiles$.pipe(take(1)).subscribe((profiles) => {
      const filtered = (profiles as ProfileInterface[]).filter((f) => f.profileId !== profile.profileId);
      const newEntities = [...filtered, profile];
      this.userProfilesStore.next(newEntities);
    });
  }
}
