import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// https://dev.to/this-is-angular/using-dependency-injection-to-automatically-unsubscribe-from-an-observable-30fi
@Injectable({
  providedIn: 'root'
})
export class DestroyService extends Subject<void> implements OnDestroy {

  ngOnDestroy(): void {
    this.next();
    this.complete();
  }
}
