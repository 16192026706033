<div class="card">
  <div
    *ngIf="!profileLoaded()"
    class="grid place-items-center">
    <shared-spinner />
  </div>
  <form
    *ngIf="profileLoaded()"
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <h3 i18n>Wijzig {{ profile().profileName }}</h3>
    </header>
    <fieldset formGroupName="profile">
      <shared-form-avatar
        [person]="profile()"
        [styleClass]="'xperience'"
        [label]="LABEL_PROFILE_IMAGE"
        [showLabel]="false"
        [submitted]="submitted"
        [maxNumberOfFiles]="1"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.avatar'"
        (uploadsChange)="setUploadFiles($event)" />
      <shared-form-input
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_NAME"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.profileName'" />
      <!-- <shared-form-select
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_LANGUAGE"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.languageId'"
        [options]="languages" /> -->
      <shared-form-pincode
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_NEW_ACCESS_CODE"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.accessCode'" />
      <shared-form-input-hidden
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_HAS_ACCESS_CODE"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.hasAccessCode'" />
      <shared-form-input-hidden
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="'Access code changed'"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.isAccessCodeChanged'" />
      <shared-form-checkbox
        [styleClass]="'xperience grid-xp'"
        [submitted]="submitted"
        [label]="LABEL_KIDS_MODE"
        [showLabel]="true"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profile.props.kidsMode'" />
    </fieldset>
    <footer>
      <div class="button-bar align-center">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_SAVE"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
        <button
          i18n
          type="button"
          class="is-transparent"
          (click)="toProfiles($event)">
          Annuleren
        </button>
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
