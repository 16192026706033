import {inject, Pipe, PipeTransform} from '@angular/core';
import {EpgService} from "../services/epg.service";

@Pipe({
  name: 'dateAsIsoString',
  standalone: true
})
export class DateAsIsoStringPipe implements PipeTransform {
  private epgService = inject(EpgService)

  transform(value: Date, ...args: unknown[]): unknown {
    return this.epgService.getAsIsoString(value, args[0] as boolean, args[1] as boolean);
  }

}
