import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlComponent } from '../form-control.component';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { PasswordValidator } from "../../../validators/password-validator";

@Component({
  selector: 'shared-form-password-confirm',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetAsFormControlPipe],
  templateUrl: './form-password-confirm.component.html',
  styleUrls: ['./form-password-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPasswordConfirmComponent extends FormControlComponent implements OnInit {
  showPassword: boolean = false;

  valueDoesNotMatch = $localize`:@@valueDoesNotMatch:Waardes komen niet overeen.`
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.shouldValidate) {
      this.control.addValidators([Validators.required, PasswordValidator.matchPassword(this.formGroup.get("register.password"))]);
      // this.control.addValidators([Validators.required, RxwebValidators.password({ validation: { maxLength: 24, minLength: 8, digit: true, specialCharacter: true, upperCase: true } }), RxwebValidators.compare({ fieldName: 'password' })]);
    }
  }

  override getErrorMessage(): string {
    if (this.control.hasError('mismatchedPasswords')) {
      return this.valueDoesNotMatch;
    }

    return super.getErrorMessage();
  }
}
