import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2
} from "@angular/core";
import { ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";
import { GetFormControlPipe } from "../../../pipes/get-form-control.pipe";
import { FormControlComponent } from "../form-control.component";
import { OptionCollection } from "../../../../../shared/interfaces/option-collection";

@Component({
  selector: "shared-form-select",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe
  ],
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSelectComponent
  extends FormControlComponent
  implements OnInit, OnChanges {
  private renderer = inject(Renderer2);

  @Input() options!: OptionCollection[];
  @Input() excludeOptions: any[] = [];
  @Input() includeLabel = true;
  @Input() disabledLabel = true;
  @Input() hasNoSelection = false;
  @Input() isBoolean = false;
  @Input() isBooleanAsString = false;
  @Input() isYesNo = false;

  @Output() selectControlChange = new EventEmitter<OptionCollection>();

  private validatorNotEmpty: ValidatorFn = RxwebValidators.notEmpty();

  constructor() {
    super();
    this.additionalValidators.push(this.validatorNotEmpty);
  }

  checkIfExcluded(id: string | number | boolean) {
    return this.excludeOptions.some((el) => el.languageId === id);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.isBoolean) {
      this.options = [
        { optionName: "True", optionValue: true },
        { optionName: "False", optionValue: false }
      ];
    }
    if (this.isBooleanAsString) {
      this.options = [
        { optionName: "True", optionValue: "true" },
        { optionName: "False", optionValue: "false" }
      ];
    }
    if (this.isYesNo) {
      this.options = [
        { optionName: "Yes", optionValue: true },
        { optionName: "No", optionValue: false }
      ];
    }
    if (this.hasNoSelection) {
      const emptyVal: OptionCollection = {
        optionName: "No selection",
        optionValue: "0"
      };
      this.options = [emptyVal, ...this.options];
    }
    this.control.setValue(
      this.control.value === null ? "":this.control.value
    );
  }

  ngOnChanges(): void {
    this.isDisabled ? this.control.disable():this.control.enable();
  }

  toggleSelect(e: Event): void {
    const selected = e.currentTarget as HTMLDivElement;
    const parent = this.renderer.parentNode(selected) as HTMLDivElement;
    if (parent.classList.contains("open")) {
      this.renderer.removeClass(parent, "open");
    } else {
      this.renderer.addClass(parent, "open");
    }
  }

  selectClosed(e: Event): void {
    const selected = e.currentTarget as HTMLDivElement;
    const parent = this.renderer.parentNode(selected) as HTMLDivElement;
    this.renderer.removeClass(parent, "open");
  }

  closeSelect(e: Event): void {
    const selected = e.currentTarget as HTMLDivElement;
  }

  onChange(e: Event): void {
    const selected = e.currentTarget as HTMLOptionElement;
    const choice = this.options.find(
      (o) => (o.optionValue as string) == (selected.value as string)
    ) as OptionCollection;
    if (choice) {
      this.selectControlChange.emit(choice);
    }
  }

  override getErrorMessage(): string {
    if (this.control.hasError("notEmpty")) {
      return "Choose a valid option.";
    }
    return super.getErrorMessage();
  }
}
