<ng-container *ngIf="subscriptionsData | async as subscriptionsData">
  <div class="grid grid-cols-6 gap-x-vertical-base grow">
    <section class="col-span-6 md:col-span-1">
      <app-side-menu />
    </section>
    <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
      <section class="col-span-6 md:col-span-3">
        <h2 i18n>Abonnementen</h2>
        <app-card-subscription></app-card-subscription>
      </section>
      <section class="col-span-6 md:col-span-2">
        <app-card-subscription-past />
        <!-- <app-card-payment-information *ngIf="subscriptionsData.userAccountSubscriptions"></app-card-payment-information> -->
      </section>
    </div>
  </div>
</ng-container>
