/**
 * Classes whose instances can be collected in arrays and then
 * listed via a select form field type as options should implement
 * this interface. Provides for id:value pairs as options in dropdown
 * select list.
 */
export interface OptionCollection {
  optionName: string | number;
  optionValue: string | number | boolean;
}

export interface OptionCollectionLanguageId {
  optionLanguageId: number;
}

export interface OptionCollectionBrandId {
  optionBrandId: number;
}

export interface OptionCollectionDatatypeId {
  optionDatatypeId: number;
}

export interface OptionCollectionWithLanguage
  extends OptionCollection,
  OptionCollectionLanguageId {
}

export interface OptionCollectionWithBrand
  extends OptionCollection,
  OptionCollectionBrandId {
}

export interface OptionCollectionWithDatatype
  extends OptionCollection,
  OptionCollectionDatatypeId {
}

export interface OptionCollectionWithLanguageAndDatatype
  extends OptionCollection,
  OptionCollectionLanguageId,
  OptionCollectionDatatypeId {
}

export function toOptionCollection(options: Record<string | number, any>[], optionNameField: string = "optionName", optionValueField: string = "optionValue"): OptionCollection[] {
  let result: OptionCollection[] = [];
  options.forEach((o) => {
    const boo = Object();
    boo.optionName = o[optionNameField];
    boo.optionValue = o[optionValueField];
    result.push(boo);
  });
  return result;
}
