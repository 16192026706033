import { Q90Response, Q90ResponseLevels } from "./q90-response";

export enum XperienceBackendErrorCodes {
  UsernameInUse = 1,
  EmailAddressInUse = 2,
  ComplexPasswordRequired = 3,
  ConnectionError = 4,
  UserDoesNotExists = 5,
  ActivationKeyNotFound = 6,
  UsernameOrPasswordIncorrect = 7,
  NotLoggedIn = 8,
  AlreadyLoggedIn = 9,
  InvalidTFA = 10,
  KeyExpired = 11,
  EmailAddressNotFound = 12,
  InvalidInputDataType = 13,
  InvalidAuthenticationToken = 14,
  AccessDenied = 15,
  InternalError = 16,
  FatalError = 17,
  GatewayError = 18,
  UserNotActivated = 19,
  EmailNotValid = 20,
  UsernameEmpty = 21,
  NotAllDataProvided = 22,
  UserDisabled = 23,
  OperationNotAllowed = 24,
  LicenseError = 25,
  InvalidInputData = 26,
  TFAAlreadySetup = 27,
  TFANotEnabled = 28,
  ItemAlreadyExists = 29,
  WaitBetweenOperations = 30,
  InvalidApiKey = 31,
  ItemDoesNotExists = 32,
  AccountSettingNotAllowed = 33,
  InvalidAccountSettingsFormat = 34,
  ItenHasItemsAttached = 35,
  PositionAlreadyExists = 36,
  NoValidNotificationProfile = 37,
  NoThirdpartyLogin = 38,
  ProfileIsNotMain = 39,
  ProfileIsAlreadyMain = 40,
  ExternalAPIUnknownError = 10000,
  ExternalAPIInvalidMaxxXSSettings = 10001,
  ExternalAPIInvalidMaxxXSSession = 10002,
  ExternalAPIInvalidMaxxXSXMLResult = 10003,
  ExternalAPIOneOrMoreErrorsOccured = 10004,
  ExternalAPIAPIDeserializationError = 10005,
  ExternalAPIAuthenticationFailure3 = 20003,
  ExternalAPIAuthenticationFailure4 = 20004,
  ExternalAPIAuthenticationFailure5 = 20005,
  ExternalAPIAuthenticationFailure6 = 20006,
  ExternalAPIAuthorizationFailureTokenInvalid = 20007,
  ExternalAPIAuthorizationFailureTokenNotValidated = 20008,
  ExternalAPINoValidFunction = 20010,
  ExternalAPINoValidApiCall = 20011,
  ExternalAPINoInformationFound = 20020,
  ExternalAPINoOrInvalidEmailAddress = 20031,
  ExternalAPINoAccesscode = 20032,
  ExternalAPIAccesscodeTooShort = 20033,
  ExternalAPIAccesscodeWrongLength = 20034,
  ExternalAPIInvalidAccesscode = 20035,
  ExternalAPITooManyAttempts = 20036,
  ExternalAPIInvalidAttemptXLeft = 20037,
  ExternalAPITemporarilyBlocked = 20038,
  ExternalAPIAccountDeactivated = 20040,
  ExternalAPINoAccount = 20041,
  ExternalAPISubscriptionNotFound = 20042,
  ExternalAPIMissingParameter = 20043,
  ExternalAPIInvalidParameter = 20044,
  ExternalAPISubscriptionNotRunningOrNoChannelId = 20045,
  ExternalAPINoProductsFound = 20046,
  ExternalAPIAccountSuspended = 20047,
  ExternalAPINoProductIdGiven = 20050,
  ExternalAPIProductNotFound = 20051,
  ExternalAPINoActiveLicenseForThisProduct = 20052,
  ExternalAPINoAccessAllowedToThisProduct = 20053,
  ExternalAPINoMediaSourceFound = 20054,
  ExternalAPINoProductIdGivenError = 20055,
  ExternalAPITooManyLicensesIssuedForHisProduct = 20056,
  ExternalAPIProtocolNotAllowedForThisProductType = 20057,
  ExternalAPIDownloadURLHasExpired = 20058,
  ExternalAPIDownloadURLNotAvailable = 20059,
  ExternalAPIValidationErrorsOnInputValues = 20060,
  ExternalAPIAccessDurationNotValidOrMissing = 20061,
  ExternalAPICouldNotAddReminder = 20062,
  ExternalAPIInvalidOrMissingPlaylistId = 20070,
  ExternalAPIInvalidOrMissingMediaId = 20071,
  ExternalAPIMediaIdHasNotBeenFoundOrTheItemIsCurrentlyInactive = 20072,
  ExternalAPIPlaylistNotFoundOrCouldNotBeDeleted = 20073,
  ExternalAPIInvalidOrMissingTitle = 20074,
  ExternalAPIItemAlreadyPresentInPlaylist = 20075,
  ExternalAPIItemNotPresentInPlaylist = 20076,
  ExternalAPIReplayTVStartTimeInvalid = 20080,
  ExternalAPIReplayTVRecordingNotAvailable = 20081,
  ExternalAPINoOrInvalidGender = 20101,
  ExternalAPINoOrInvalidInitials = 20102,
  ExternalAPINoOrInvalidName = 20103,
  ExternalAPINoOrInvalidSurname = 20104,
  ExternalAPIEmailAddressMismatch = 20106,
  ExternalAPINoOrInvalidAccesscode = 20107,
  ExternalAPIAccesscodeMismatch = 20108,
  ExternalAPIAccesscodeNotNumeric = 20110,
  ExternalAPIAccesscodeIncorrectLength = 20111,
  ExternalAPIAccesscodeHasConsecutiveUpwardNumbers = 20112,
  ExternalAPIAccesscodeHasConsecutiveDownwardNumbers = 20113,
  ExternalAPIAllAccesscodeNumbersAreTheSame = 20114,
  ExternalAPINoOrInvalidAccesscodeGiven = 20115,
  ExternalAPISessionNotFound = 20120,
  ExternalAPIWebsiteMismatchOnSession = 20121,
  ExternalAPIInvalidGender = 20131,
  ExternalAPIInvalidSpokenlanguage = 20132,
  ExternalAPIInvalidStreet = 20133,
  ExternalAPIInvalidNumber = 20134,
  ExternalAPIInvalidSuffix = 20135,
  ExternalAPIInvalidPostalcode = 20136,
  ExternalAPIInvalidCity = 20137,
  ExternalAPIInvalidPhonenumber = 20138,
  ExternalAPIInvalidMobile = 20139,
  ExternalAPIInvalidNewsletter = 20140,
  ExternalAPIInvalidInitials = 20141,
  ExternalAPIInvalidName = 20142,
  ExternalAPIInvalidSurname = 20143,
  ExternalAPIInvalidJSONStructure = 20144,
  ExternalAPIEmailAddressInUse = 20201,
  ExternalAPIRegistrationCompleted = 20202,
  ExternalAPIRegistrationError = 20203,
  ExternalAPIProductHasAlreadyBeenRatedByUser = 20301,
  ExternalAPIReviewTooLongMaximumOfXSymbols = 20302,
  ExternalAPICustomerAccountHasAlreadyBeenRemoved = 20410,
  ExternalAPICustomerAccountHasRunningSubscriptions = 20411,
  ExternalAPICustomerAccountHasUnpaidInvoices = 20412,
  ExternalAPICustomerAccountHasOpenBalance = 20413,
  ExternalAPICustomerAccountOwnsCredits = 20414,
  ExternalAPINoValidMethod = 20900,
  ExternalAPINoPaymentMethodsAvailable = 20901,
  ExternalAPINoPaymentUrl = 20902,
  ExternalAPINoTransaction = 20903,
  ExternalAPINoReturnUrl = 20904,
  ExternalAPINoCancelUrl = 20905,
  ExternalAPINoActiveUser = 20906,
  ExternalAPINoProduct = 20907,
  ExternalAPIDirectDebitIsAcceptedAndEnabledInAccount = 20910,
  ExternalAPIDirectDebitIsNotAcceptedPaymentDetailsNotValidated = 20911,
  ExternalAPIDirectDebitProcessNotCompleted = 20912,
  ExternalAPINoValidActionCode = 20950,
  ExternalAPICampaignNotActive = 20951,
  ExternalAPIActionCodeNotActive = 20952,
  ExternalAPIActionCodeRedeemed = 20953,
  ExternalAPIActionCodeRedeemedByUser = 20954,
  ExternalAPINoProductsFoundForThisActionWithinLicenseRegion = 20955,
  ExternalAPIProductNotValidForThisAction = 20956,
  ExternalAPIStartTimeNotPresentOrNumeric = 21010,
  ExternalAPIEndTimeNotPresentOrNotNumeric = 21011,
  ExternalAPIStartTimeNotValid = 21012,
  ExternalAPIEndTimeNotValid = 21013,
  ExternalAPINoTitlePresent = 21014,
  ExternalAPINoValidTitle = 21015,
  ExternalAPIDuplicateEPGTime = 21016,
  ExternalAPICriticalNewItemNotCreatedInCMS = 21017,
  ExternalAPICriticalEPGTimesNotStoredInCMS = 21018,
  ExternalAPICriticalNewProductNotCreatedInCMS = 21019,
  ExternalAPICriticalNoLicenseRegionsFoundForThisWebsiteOrPlatform = 21020,
  ExternalAPICriticalLicenseRegionNotCreatedForThisWebsiteOrPlatform = 21021,
  ExternalAPIEPGNotAllowedWithThisProduct = 21022,
  ExternalAPIPlaylistNotCreated = 21100,
  ExternalAPICustomerAccountNotValidated = 21101,
  // Codes added to list seen on the frontend from backend that were not in the list
  FailedProfileLogin = 20208,
}

export class XperienceResponse extends Q90Response {

  static override message(message: string = "There was a problem.", level: string = Q90ResponseLevels.Warning): XperienceResponse {
    const responseObj = new XperienceResponse();
    responseObj.resultMessages.push({ code: 0, message: message });
    return responseObj;
  }

  protected override hasBackendErrors(): boolean {
    let result = false;
    this.resultMessages.forEach(message => {
      if (Object.values(XperienceBackendErrorCodes).includes(+message.code)) {
        result = true;
      }
    });
    return result;
  }

  protected override getBackendMessages(): string[] {
    const result: string[] = [];
    this.resultMessages.forEach(message => {
      result.push(message.message);

    });
    return result;
  }

}
