import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'beautifyDuration',
    standalone: true
})

export class BeautifyDurationPipe implements PipeTransform {

  transform(duration): unknown {

    let text = "";

    if (duration.periodUnit == "day" && duration.period == 1) {
      text = "Daily";
    } else if (duration.periodUnit == "day" && duration.period == 2) {
      text = "2 Daily";
    } else if (duration.periodUnit == "day" && duration.period == 7) {
      text = "Weekly";
    } else if (duration.periodUnit == "week" && duration.period == 1) {
      text = "Weekly";
    } else if (duration.periodUnit == "month" && duration.period == 1) {
      text = "Monthly";
    } else if (duration.periodUnit == "month" && duration.period == 2) {
      text = "2 Monthly";
    } else if (duration.periodUnit == "month" && duration.period == 3) {
      text = "Quarterly";
    } else if (duration.periodUnit == "month" && duration.period == 6) {
      text = "Half Yearly";
    } else if (duration.periodUnit == "month" && duration.period == 12) {
      text = "Yearly";
    } else if (duration.periodUnit == "year" && duration.period == 1) {
      text = "Yearly";
    }

    return text;

  }

}
