import { inject, Injectable } from '@angular/core';
import { FrontendCookieStorageService } from "../services/storage/frontend-cookie-storage.service";
import { AuthenticateInterface } from "../models/authenticate.model";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStoreService {

  cookieStorageService = inject(FrontendCookieStorageService);

  constructor() {
  }

  setAuthenticatedUser(authenticatedUser: AuthenticateInterface | null): void {
    // Don't write auth info to local storage unless user is authenticated
    // if (authenticatedUser && authenticatedUser.isAuthenticated) {
    if (authenticatedUser) {
      this.setFrontendStorage(authenticatedUser);
    }
  }

  getToken(): string {
    return this.cookieStorageService.getItem('token')!;
  }

  getTokenExpiry(): string {
    return this.cookieStorageService.getItem('tokenExpiry')!;
  }

  getRefreshToken(): string {
    return this.cookieStorageService.getItem('refreshToken')!;
  }

  private setFrontendStorage(authenticatedUser: AuthenticateInterface) {
    if (authenticatedUser.token) {
      this.cookieStorageService.setItem('token', authenticatedUser.token);
    }
    if (authenticatedUser.tokenExpiry) {
      this.cookieStorageService.setItem(
        'tokenExpiry',
        authenticatedUser.tokenExpiry,
        true
      );
    }
    if (authenticatedUser.refreshToken) {
      this.cookieStorageService.setItem(
        'refreshToken',
        authenticatedUser.refreshToken
      );
    }
    if (authenticatedUser.refreshTokenExpiry) {
      this.cookieStorageService.setItem(
        'refreshTokenExpiry',
        authenticatedUser.refreshTokenExpiry,
        true
      );
    }
    if (authenticatedUser.level) {
      const level: string = authenticatedUser.level as any;
      this.cookieStorageService.setItem('level', level);
    }
  }

  clearStore(): void {
    this.cookieStorageService.removeItem('token');
    this.cookieStorageService.removeItem('tokenExpiry');
    this.cookieStorageService.removeItem('refreshToken');
    this.cookieStorageService.removeItem('refreshTokenExpiry');
    this.cookieStorageService.removeItem('level');
  }
}
