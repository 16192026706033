@if (!loading) {
  @if (avatarSrc) {
    <div class="avatar {{ size }}">
      <picture [ngClass]="withLink ? 'avatar-link' : ''">
        <source
          *ngIf="negotiateFormat"
          [srcset]="avatarSrc"
          class="{{ size }}"
          media="(min-width: 1536px)" />
        <img
          class="{{ size }}"
          [src]="avatarSrc"
          alt="user avatar" />
      </picture>
    </div>
  } @else {
    <div class="avatar {{ size }} avatar-empty">
      <span class="mci-user-single-solid"></span>
    </div>
  }
} @else {
  <shared-spinner></shared-spinner>
}
