import { Component, OnInit } from "@angular/core";
import { CrwFlowSection, CrwPageComponent } from "../crw-page/crw-page.component";
import { CardSelectionComponent } from "../../cards/card-selection/card-selection.component";

@Component({
  selector: "app-crw-selection",
  standalone: true,
  imports: [
    CardSelectionComponent
  ],
  templateUrl: "./crw-selection.component.html",
  styleUrl: "./crw-selection.component.scss"
})
export class CrwSelectionComponent extends CrwPageComponent implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwStore.setCrwFlow(CrwFlowSection.Selection);
  }

}
