import { CommonModule, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { CardForgotPasswordComponent } from '../../cards/card-forgot-password/card-forgot-password.component';
import { LogoPipe } from '../../pipes/logo.pipe';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { CardLoginComponent } from '../../cards/card-login/card-login.component';
import { Observable, combineLatest, map } from 'rxjs';

interface PairingDevicePageData {
  isLoggedIn: boolean;
}
@Component({
  selector: 'app-pairing-code-page',
  standalone: true,
  templateUrl: './pairing-code-page.component.html',
  styleUrl: './pairing-code-page.component.scss',
  imports: [CommonModule, NgIf, NgStyle, CardForgotPasswordComponent, LogoPipe, CardPairingComponent, CardLoginComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PairingCodePageComponent implements OnInit {

  private backgroundImageStore = inject(BackgroundImageStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  pairingDevicePageData$: Observable<PairingDevicePageData>;
  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }

  ngOnInit(): void {
    this.pairingDevicePageData$ = combineLatest(
      [
        this.userAccountStore.isLoggedIn$
      ]
    ).pipe(
      map(([isLoggedIn]) => {
        return {
          isLoggedIn,
        };
      }),
    );
  }
}
