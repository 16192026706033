<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }">
    <label
      for="{{ id }}"
      [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
      >{{ label }}</label
    >
    <ng-content select="[afterControlLabel]"></ng-content>
    <div class="select-wrapper">
      <select
        id="{{ id }}"
        (change)="onChange($event)"
        [formControl]="control | getAsFormControl"
        (click)="toggleSelect($event)"
        (blur)="selectClosed($event)">
        <option
          *ngIf="includeLabel"
          [disabled]="disabledLabel"
          class="placeholder"
          value="">
          &ndash;&nbsp;{{ label }}&nbsp;&ndash;
        </option>
        <option
          [disabled]="checkIfExcluded(opt.optionValue)"
          *ngFor="let opt of options"
          [value]="opt.optionValue">
          {{ opt.optionName }}
        </option>
      </select>
    </div>
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted }">
      <span class="mci-warning"></span>
    </div>
  </div>
  <ng-content select="[afterControlField]"></ng-content>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{ getErrorMessage() }}</p>
  </div>
  <div
    class="form-info {{ styleClass }}"
    *ngIf="info">
    <p>{{ info }}</p>
  </div>
</ng-container>
