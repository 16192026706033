import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateEpgitemIsLive",
  standalone: true
})
export class DateEpgitemIsLivePipe implements PipeTransform {

  transform(startDate: string, ...args: any[]): boolean {
    const now = new Date();
    const endDate: Date = new Date(args[0]);
    const startDateObject: Date = new Date(startDate);
    // console.log(now.getTime(), startDateObject.getTime(), startDateObject.getTime() <= now.getTime());
    return (now.getTime() >= startDateObject.getTime() && now.getTime() < endDate.getTime());
  }

}
