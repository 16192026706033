<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }">
    <label
      for="{{ id }}"
      [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
      >{{ label }}</label
    >
    @if (person() && hasAvatar()) {
      <div class="grid-xp grid-cols-2 right-heavy align-center spread">
        <core-avatar
          [avatarSrc]="person().avatarThumbnail ? person().avatarThumbnail : person().avatar"
          [size]="'md'" />
        <div class="button-bar align-right m-block-0">
          <button
            i18n
            type="button"
            (click)="removeFile($event)">
            Wijzig afbeelding
          </button>
        </div>
      </div>
    } @else if (previewing$ | async) {
      @if (loadingPreview$ | async) {
        <shared-spinner />
      } @else {
        <div class="grid-xp grid-cols-2 right-heavy align-center spread">
          <core-avatar
            [avatarSrc]="avatarUrl"
            [size]="'md'" />
          <div class="button-bar align-right m-block-0">
            <button
              i18n
              type="button"
              (click)="removeFile($event)">
              Verwijderen
            </button>
          </div>
        </div>
      }
    } @else {
      <div class="grid-xp grid-cols-2 right-heavy align-center spread">
        <core-avatar
          [avatarSrc]="null"
          [size]="'md'" />
        <div class="button-bar align-right m-block-0">
          <button
            i18n
            type="button"
            (click)="chooseMedia.click()">
            Afbeelding uploaden
          </button>
          <input
            #chooseMedia
            accept="image/png, image/jpeg"
            id="{{ id }}"
            type="file"
            class="choose-upload-input"
            (change)="onUploadsInputChange($event)"
            [formControl]="control | getAsFormControl" />
        </div>
      </div>
    }
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }">
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{ getErrorMessage() }}</p>
  </div>
</ng-container>
