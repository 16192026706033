<ng-container *ngIf="paymentMethodsData$ | async as paymentMethodsData">
  <div
    id="card-login"
    class="card">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <header>
        <h3 i18n>Betaalmethoden</h3>
      </header>
      @if (paymentMethodsData.paymentMethods.length > 0) {
        <fieldset>
          @for (paymentMethod of paymentMethodsData.paymentMethods; track paymentMethod.id) {
            <div class="grid-xp grid-cols-2 align-center right-heavy mt-std">
              <div>
                <img
                  src="assets/images/payment_method-{{ paymentMethod.paymentCode }}.svg"
                  alt="Logo for {{ paymentMethod.title }} payment method" />
              </div>
              <shared-form-radio
                [styleClass]="'xperience m-block-0 grid-xp'"
                [id]="'paymentmethod-' + paymentMethod.paymentCode"
                [radioName]="'paymentCode'"
                [radioValue]="paymentMethod.paymentCode"
                [submitted]="submitted"
                [label]="paymentMethod.title"
                [formGroup]="formGroup"
                [control]="formGroup | getFormControl: 'paymentMethods.paymentCode'"></shared-form-radio>
            </div>
          }
        </fieldset>
      } @else {
        <div class="grid-xpalign-center justify-center mt-1">
          <shared-spinner />
        </div>
      }
      <footer>
        <div class="button-bar align-left">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_NEXT"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </div>
</ng-container>
