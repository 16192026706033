<ng-container *ngIf="crwPageData$ | async as crwPageData">
  <app-card-account-your *ngIf="crwPageData.isLoggedIn" />
  <app-card-register
    *ngIf="!showCrwLogin() && !crwPageData.isLoggedIn"
    (onShowLogin)="showLogin($event)" />
  <app-card-login
    *ngIf="showCrwLogin() && !crwPageData.isLoggedIn"
    (loginSuccessful)="login($event)"
    (onShowLogin)="showLogin($event)" />
</ng-container>
