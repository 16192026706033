<ng-container *ngIf="crwPageData$ | async as crwPageData">
  <nav>
    <ul class="side-menu justify-center flex gap-x-std-half md:block">
      <li
        class="md:mb-vertical-base-half"
        [ngClass]="{ 'is-active': crwPageData.crwFlowSection === crwSection.Account }">
        <a
          i18n
          routerLinkActive="is-active"
          routerLink="/crw/account">
          Account
        </a>
      </li>
      <li
        class="md:mb-vertical-base-half"
        [ngClass]="{ 'is-active': crwPageData.crwFlowSection === crwSection.Subscription }">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/crw/subscription">
          Abonnement
        </a>
      </li>
      <li
        class="md:mb-vertical-base-half"
        *ngIf="crwPageData.subscription"
        [ngClass]="{ 'is-active': crwPageData.crwFlowSection === crwSection.Payment }">
        <a
          i18n
          routerLinkActive="is-active"
          routerLink="/crw/payment">
          Betaling
        </a>
      </li>
      <li
        class="md:mb-vertical-base-half"
        *ngIf="crwPageData.subscription && crwPageData.crwPaymentMethod"
        [ngClass]="{ 'is-active': crwPageData.crwFlowSection === crwSection.Overview }">
        <a
          i18n
          routerLinkActive="is-active"
          routerLink="/crw/overview">
          Overzicht
        </a>
      </li>
    </ul>
  </nav>
</ng-container>
