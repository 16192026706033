import { Component } from '@angular/core';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    standalone: true
})
export class SearchPageComponent {

}
