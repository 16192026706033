import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class BackgroundImageStoreService {

  private backgroundImageStore = new BehaviorSubject<string>('');
  backgroundImage$: Observable<string> = this.backgroundImageStore.asObservable();

  constructor() {

  }

  setBackgroundImage(image: string) {
    const backgroundImage = "assets/images/" + image;
    this.backgroundImageStore.next(backgroundImage);
  }
}
