import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FrontendLocalStorageService } from '../../../services/storage/frontend-local-storage.service';
import { LocalStorageKeys } from '../../../services/application.service';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../modal/modal.component';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'shared-cookie-disclaimer',
  standalone: true,
  imports: [CommonModule, ModalComponent, RouterLink],
  templateUrl: './cookie-disclaimer.component.html',
  styleUrl: './cookie-disclaimer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieDisclaimerComponent {
  localStorageService = inject(FrontendLocalStorageService);
  router = inject(Router);

  public createOpen = true;

  ngOnInit(): void {
    if (this.router.url.includes('/info/') || this.router.url.includes('/help/')) this.createOpen = false;
  }

  cookieDisclaimerClosed() {
    this.createOpen = false;
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '2');
  }

  cookieDisclaimerAgreed(e: Event) {
    this.createOpen = false;
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '1');
  }
}
