import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, of, take } from 'rxjs';
import { AccountInterface } from '../models/account.model';
import { SubscriptionInterface } from '../models/subscription.model';
import { LanguageInterface } from '../models/language.model';
import { SubscriptionStoreService } from './subscription-store.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UserAccountStoreService {
  private subscriptionsStore = inject(SubscriptionStoreService);

  isLoggedIn: WritableSignal<boolean> = signal(false);
  isLoggedInComputed = computed(() => {
    return this.isLoggedIn();
  });

  userAccountLanguage: WritableSignal<LanguageInterface | null> = signal(null);

  private userAccountStore = new BehaviorSubject<AccountInterface | null>(null);
  userAccount$: Observable<AccountInterface | null> = this.userAccountStore.asObservable();

  private userAccountSubscriptionsStore = new BehaviorSubject<SubscriptionInterface[]>([]);
  userAccountSubscriptions$: Observable<SubscriptionInterface[]> = this.userAccountSubscriptionsStore.asObservable();

  private isLoggedInStore = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedInStore.asObservable();

  private createAccountDataStore = new BehaviorSubject<FormGroup>(null);
  createAccountData$: Observable<FormGroup> = this.createAccountDataStore.asObservable();

  validSubscriptions$: Observable<SubscriptionInterface[]> = this.userAccountSubscriptions$.pipe(concatMap((subscriptions) => of(this.subscriptionsStore.getValidSubscriptions(subscriptions))));

  invalidSubscriptions$: Observable<SubscriptionInterface[]> = this.userAccountSubscriptions$.pipe(concatMap((subscriptions) => of(this.subscriptionsStore.getInvalidSubscriptions(subscriptions))));

  currentSubscription$: Observable<SubscriptionInterface | null> = this.validSubscriptions$.pipe(concatMap((subscriptions) => of(this.subscriptionsStore.getCurrentSubscription(subscriptions))));

  constructor() {}

  setUserAccount(userAccount: AccountInterface | null): void {
    this.userAccountStore.next(userAccount);
  }

  setUserAccountSubscriptions(subscriptions: SubscriptionInterface[]): void {
    this.userAccountSubscriptionsStore.next(subscriptions);
  }

  /**
   * Still caught between transition from BehaviorSubjects to Signals...patience...
   * @param setting
   */
  setIsLoggedIn(setting: boolean): void {
    this.isLoggedInStore.next(setting);
    this.isLoggedIn.set(false);
  }

  updateUserAccount(userAccount: AccountInterface): void {
    this.userAccount$.pipe(take(1)).subscribe((currentUserAccount) => {
      const updatedUserAccount = { ...currentUserAccount, ...userAccount };
      this.userAccountStore.next(updatedUserAccount);
    });
  }

  setCreateAccountData(createAccountData: FormGroup) {
    this.createAccountDataStore.next(createAccountData);
  }
}
