import { inject, Pipe, PipeTransform } from "@angular/core";
import { EpgService } from "../services/epg.service";
import { TimelineLeftOffsetPipe } from "./timeline-left-offset.pipe";

@Pipe({
  name: "timelineLeftOffsetChannel",
  standalone: true
})
export class TimelineLeftOffsetChannelPipe extends TimelineLeftOffsetPipe implements PipeTransform {

  private epgService = inject(EpgService);

  override transform(liveDate: Date, ...args: any[]): string {
    const liveProgram = args[0];
    const liveProgramEpgDivOrderIndex = args[1];
    const liveProgramLengthInHours = this.epgService.getItemDurationHours(liveProgram);
    const liveProgramHourUnitMultiplier = this.hourUnitInRems / liveProgramLengthInHours;
    const startHour: number = new Date(liveProgram.startDate).getHours();
    const liveHour: number = liveDate.getHours();
    const liveProgramLengthMultiplier = liveProgramHourUnitMultiplier * (liveHour - startHour);
    // console.log(
    //   startHour, liveHour, liveProgramLengthInHours, liveProgramHourUnitMultiplier, liveProgramLengthMultiplier);
    return (((
      liveProgramLengthMultiplier +
      (liveProgramHourUnitMultiplier / 60 * liveDate.getMinutes()) +
      ((liveProgramHourUnitMultiplier / 60) / 60 * liveDate.getSeconds())
    ))) +
      (liveProgramEpgDivOrderIndex * this.hourUnitInRems) +
      -2.5 + "rem";
  }
}
