<nav>
  <ul class="side-menu justify-center flex gap-x-std-half md:block">
    <li class="md:mb-vertical-base-half">
      <a
        i18n
        [routerLinkActive]="'is-active'"
        [routerLink]="['/account/info']">
        Account
      </a>
    </li>
    <li class="md:mb-vertical-base-half">
      <a
        i18n
        [routerLinkActive]="'is-active'"
        [routerLink]="['/account/subscription']">
        Abonnement & Facturering
      </a>
    </li>
    <li class="md:mb-vertical-base-half">
      <a
        i18n
        [routerLinkActive]="'is-active'"
        [routerLink]="['/profiles']">
        Profielen
      </a>
    </li>
    <li class="md:mb-vertical-base-half">
      <a
        id="logout"
        i18n
        [ngClass]="{ 'is-active': loggingOut() }"
        (click)="logout($event)">
        Uitloggen
        <shared-spinner
          *ngIf="loggingOut()"
          [size]="'x-sm'" />
      </a>
    </li>
  </ul>
</nav>
