import {Q90ResponseData} from "../interfaces/q90-response";
import {AuthenticateInterface} from "../models/authenticate.model";
import {catchError, map, Observable} from "rxjs";
import {inject} from "@angular/core";
import {ApplicationService} from "../services/application.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApplicationStoreService} from "../stores/application-store.service";
import {environment} from "../../environments/environment";


export function refresh(
  token: string,
  refreshToken: string
): Observable<Q90ResponseData<AuthenticateInterface>> {
  const applicationStore = inject(ApplicationStoreService);
  const http = inject(HttpClient);
  applicationStore.setRefreshingToken(true);
  return http
    .post<Q90ResponseData<AuthenticateInterface>>(
      environment.apiEndpointRoot +
      environment.apiEndpointVersion +
      '/authentication/refreshtoken',
      {
        token: token,
        refreshToken: refreshToken,
      },
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
        responseType: 'json',
      }
    )
    .pipe(
      map((res) => {
        applicationStore.setRefreshingToken(false);
        return res;
      }),
      catchError((err) => {
        applicationStore.setRefreshingToken(false);
        throw err;
      })
    );
}
