<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <div class="grid-xp grid-cols-2 spread left-heavy align-center">
        <h3 i18n>Toegangscode vergeten</h3>
        <p>
          <a
            i18n
            routerLink="/profiles"
            >Terug naar profielen</a
          >
        </p>
      </div>
      <p i18n>
        Bent u de toegangscode kwijt voor profiel <br />
        <strong> {{ profilesStore.getpinResetProfile()?.value?.profileName }} </strong>? Vraag dan hieronder een nieuwe aan en wij sturen u een e-mail met een herstel link.
      </p>
    </header>
    <footer>
      <div class="button-bar align-left">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_RESET_PINCODE"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [submitting]="submitting()" />
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
