<div class="dates">
  @if ((currentDate | dateAsIsoString) !== (dates[0] | dateAsIsoString)) {
    <button
      type="button"
      class="is-light-indigo"
      (click)="previousDay()">
      <span class="mci-arrowhead-left"></span>
    </button>
  } @else {
    <button
      type="button"
      class="is-disabled">
      <span class="mci-arrowhead-left"></span>
    </button>
  }
  <div class="entries">
    <h4
      *ngFor="let date of dates"
      [ngClass]="{ 'is-active': (date | dateAsIsoString) === (currentDate | dateAsIsoString) }"
      class="date">
      {{ date | beautifyDate }}
    </h4>
  </div>
  @if ((currentDate | dateAsIsoString) !== (dates[dates.length - 1] | dateAsIsoString)) {
    <button
      type="button"
      class="is-light-indigo"
      (click)="nextDay()">
      <span class="mci-arrowhead-right"></span>
    </button>
  } @else {
    <button
      type="button"
      class="is-disabled">
      <span class="mci-arrowhead-right"></span>
    </button>
  }
</div>
