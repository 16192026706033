import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { CardAccountDeleteComponent } from '../../cards/card-account-delete/card-account-delete.component';
import { CardAccountComponent } from '../../cards/card-account/card-account.component';
import { CardEmailComponent } from '../../cards/card-email/card-email.component';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { CardPasswordComponent } from '../../cards/card-password/card-password.component';
import { SideMenuExtraComponent } from '../../components/side-menu-extra/side-menu-extra.component';
import { SideMenuHelpComponent } from '../../components/side-menu-help/side-menu-help.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccountInterface } from '../../models/account.model';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { ContentTemplateInterface } from '../../models/content-template.model';

interface ColophonPageData {
    isLoggedIn: boolean;
    userAccount: AccountInterface;
    content: ContentTemplateInterface | null;
}
@Component({
    selector: 'app-colophon-page',
    templateUrl: './colophon-page.component.html',
    styleUrls: ['./colophon-page.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf, SideMenuComponent, CardAccountComponent, CardPairingComponent, CardPasswordComponent, CardEmailComponent,
        AsyncPipe, SpinnerComponent,
        CardAccountDeleteComponent,
        SideMenuHelpComponent,
        SideMenuExtraComponent
    ]
})
export class ColophonPageComponent implements OnInit {

    colophonPageData$: Observable<ColophonPageData>;

    private generalStore = inject(GeneralStoreService);
    private userAccountStore = inject(UserAccountStoreService);

    constructor() {
    }

    ngOnInit(): void {
        this.colophonPageData$ = combineLatest([
            this.userAccountStore.isLoggedIn$,
            this.userAccountStore.userAccount$,
            this.generalStore.getContentByCode('COLOPHON')
        ]).pipe(
            map(
                ([isLoggedIn, userAccount, content]) => {
                    return {
                        isLoggedIn, userAccount, content
                    };
                }
            )
        );
    }

}
