<ng-container *ngIf="itemCardData$ | async as itemCardData">
  <div
    class="item"
    *ngIf="item && !itemLoading && !epgLoading">
    <div class="card">
      <div class="left">
        <div class="info">
          <h2>{{ item.title }}</h2>
          <p class="my-0">{{ item.startDate | beautifyDate }}, {{ item.startDate | date: "HH:mm" }} - {{ item.endDate | date: "HH:mm" }}</p>
          <p
            class="description"
            *ngIf="item.description != ''">
            {{ item.description }}
          </p>
          <div class="button-bar align-left">
            <button
              (click)="cb()"
              i18n
              class="is-colored"
              *ngIf="(itemCardData.liveDate | dateBetweenDates: item.startDate : item.endDate) && channelId != 0 && channelStreamId != 0"
              routerLink="/watch/{{ channelId }}/{{ channelStreamId }}/live">
              <span class="mci-tv-solid"></span>
              Kijk live
            </button>
            <button
              (click)="cb()"
              i18n
              class="is-transparent"
              *ngIf="item.hasRestart && (item.startDate | dateEpgitemIsLive: item.endDate) && channelId != 0 && channelStreamId != 0"
              routerLink="/watch/{{ channelId }}/{{ channelStreamId }}/restart{{ startDateParam }}">
              <span class="mci-rotate-left-solid is-pri"></span>
              Kijk vanaf begin
            </button>
            <button
              (click)="cb()"
              i18n
              class="is-transparent"
              *ngIf="item.hasReplay && (item.endDate | dateEpgitemIsPast) && channelId != 0 && item.mediaReplayId != 0"
              routerLink="/watch/{{ channelId }}/{{ item.mediaReplayId }}/{{ startDateParam }}">
              <span class="mci-play-circle-solid is-pri"></span>
              Opnieuw afspelen
            </button>
            <button
              (click)="cb()"
              i18n
              class="is-transparent"
              *ngIf="item.hasPromo"
              [routerLink]="['/promo']">
              <span class="mci-video-solid is-pri"></span>
              Promo
            </button>
            <button
              (click)="cb()"
              i18n
              *ngIf="channelId && item.mediaReplayId"
              [ngClass]="{ 'is-transparent': true }"
              [routerLink]="['/item', channelId, item.mainProductId, item.startDate]">
              <span class="mci-information-circle-solid"></span>
              Meer info
            </button>
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="poster"
          [ngStyle]="{ 'background-image': 'url(' + item.largeFrontCover + ')' }"></div>
      </div>
    </div>
  </div>
</ng-container>
