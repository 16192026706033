<div class="card login-card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header *ngIf="currentLoginRoute() === 'login'">
      <h3 i18n>Inloggen</h3>
      <p class="text-right">
        <a
          i18n
          routerLink="/"
          >Rondkijken</a
        >
      </p>
    </header>
    <header *ngIf="currentLoginRoute() === 'pairing-code'">
      <div>
        <h3 i18n>Inloggen</h3>
        <p i18n>U moet eerst inloggen voor u de koppelcode kunt invoeren.</p>
      </div>
    </header>
    <header *ngIf="currentLoginRoute() === 'account'">
      <div class="grid-xp grid-cols-2 spread align-center">
        <h3 i18n>Inloggen</h3>
        <button
          i18n
          *ngIf="!isActivated"
          type="button"
          class="is-transparent justify-right"
          (click)="onShowLogin.emit(false)">
          Nog geen account?
        </button>
      </div>
    </header>
    <fieldset formGroupName="login">
      <shared-form-username
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_USERNAME"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'login.username'"
        [shouldValidate]="false">
        <p
          afterControlLabel
          *ngIf="currentLoginRoute() === 'login'">
          <a
            i18n
            routerLink="/crw/account"
            >Nog geen account?</a
          >
        </p>
      </shared-form-username>
      <shared-form-password
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_PASSWORD"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'login.password'"
        [shouldValidate]="false">
        <p
          afterControlField
          *ngIf="currentLoginRoute() !== 'pairing-code'">
          <a
            i18n
            routerLink="/forgot-password">
            Wachtwoord vergeten?
          </a>
        </p>
      </shared-form-password>
    </fieldset>
    <footer>
      <div class="button-bar spread">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_LOGIN"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
        <a
          i18n
          routerLink="/tv"
          class="button is-transparent">
          Annuleren
        </a>
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
