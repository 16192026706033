import { Component, inject, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Q90ErrorResponseTypes } from "../../interfaces/q90-response";
import { ErrorService } from "../../services/error.service";
import { CommonModule } from "@angular/common";
import { BackgroundImageStoreService } from "../../stores/background-image-store.service";
import { RouterLink } from "@angular/router";

interface ErrorData {
  error: Q90ErrorResponseTypes | null;
}

@Component({
  selector: "app-error-page",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./error-page.component.html",
  styleUrl: "./error-page.component.scss"
})
export class ErrorPageComponent implements OnInit {

  private backgroundImageStore = inject(BackgroundImageStoreService);
  private errorService = inject(ErrorService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }

  errorData$!: Observable<ErrorData>;

  ngOnInit(): void {
    this.errorData$ = combineLatest(
      [
        this.errorService.error$
      ]
    ).pipe(
      map((
        [
          error
        ]
      ) => {
        return {
          error
        };
      })
    );
  }
}


