import { Injectable } from "@angular/core";
import { BehaviorSubject, iif, Observable, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LiveTimeStoreService {
  // scale: number = 60;
  hoursAsSecondsScale: number[] = [];

  private liveTimeSecondsStore = new BehaviorSubject<number | null>(null);
  liveTimeSeconds$: Observable<number | null> = this.liveTimeSecondsStore.asObservable();

  onTheHour$: Observable<number | null> = this.liveTimeSecondsStore.asObservable()
    .pipe(switchMap(
      (seconds) => iif(
        () => this.hoursAsSecondsScale.includes(seconds),
        of(seconds),
        of(null)
      )));

  private liveDateStore = new BehaviorSubject<Date | null>(null);
  liveDate$: Observable<Date | null> = this.liveDateStore.asObservable();

  constructor() {
    this.hoursAsSecondsScale = Array.from({ length: 24 }, (v, i) => i * (60 * 60));
  }

  setLiveTimeSeconds(liveTimeSeconds: number): void {
    this.liveTimeSecondsStore.next(liveTimeSeconds);
  }

  setLiveDate(liveDate: Date): void {
    this.liveDateStore.next(liveDate);
  }
}
