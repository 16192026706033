import { CommonModule, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { SubscriptionInterface } from '../../models/subscription.model';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { CrwStoreService } from '../../stores/crw-store.service';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { BeautifyDatePipe } from "../../pipes/beautify-date.pipe";

interface CardSubscriptionsData {
  crwFlowSection: CrwFlowSection;
  subscriptions: SubscriptionInterface[];
  userSubscriptions: SubscriptionInterface[];
  validSubscriptions: SubscriptionInterface[];
  invalidSubscriptions: SubscriptionInterface[];
}

@Component({
  selector: 'app-card-subscription-past',
  standalone: true,
  templateUrl: './card-subscription-past.component.html',
  styleUrl: './card-subscription-past.component.scss',
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgIf, RouterLink, NgClass, AsyncPipe, ReactiveFormsModule, SubmitComponent, ErrorMessagesComponent, BeautifyDatePipe]
})
export class CardSubscriptionPastComponent implements OnInit {
  cancelMode: boolean = false;
  subscriptionId: number = 0;

  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private crwStore = inject(CrwStoreService);

  cardSubscriptionsData$: Observable<CardSubscriptionsData>;

  constructor() {
  }

  ngOnInit(): void {
    this.cardSubscriptionsData$ = combineLatest(
      [
        this.crwStore.crwSection$,
        this.generalStore.subscriptions$,
        this.userAccountStore.userAccountSubscriptions$,
        this.userAccountStore.validSubscriptions$,
        this.userAccountStore.invalidSubscriptions$,
      ]
    ).pipe(
      map(([crwFlowSection, subscriptions, userSubscriptions, validSubscriptions, invalidSubscriptions]) => {
        return {
          crwFlowSection,
          subscriptions,
          userSubscriptions,
          validSubscriptions,
          invalidSubscriptions
        };
      }),
    );
  }

  setCancelMode(id: number) {
    this.cancelMode = id !== 0;
    this.subscriptionId = id;
  }
}
