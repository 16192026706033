import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Observable, combineLatest, map, take } from 'rxjs';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormInputHiddenComponent } from '../../components/forms/components/control/form-input-hidden/form-input-hidden.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { AccountInterface } from '../../models/account.model';
import { Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { OptionCollection } from '../../shared/interfaces/option-collection';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { FormTextareaComponent } from '../../components/forms/components/control/form-textarea/form-textarea.component';
import { GeneralService } from '../../services/general.service';
import { FormEmailComponent } from '../../components/forms/components/control/form-email/form-email.component';
import { EmailContactInterface } from '../../interfaces/email-contact.interface';

interface CardContactData {
  userAccount: AccountInterface;
}

@Component({
  selector: 'app-card-contact',
  standalone: true,
  templateUrl: './card-contact.component.html',
  styleUrl: './card-contact.component.scss',
  imports: [
    NgIf, NgClass, NgFor, AsyncPipe, ErrorMessagesComponent, FormInputComponent,
    GetFormControlPipe, ReactiveFormsModule, RouterLink, SubmitComponent,
    FormInputHiddenComponent, FormTextareaComponent, FormEmailComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardContactComponent extends FormComponent implements OnInit {

  private userAccountStore = inject(UserAccountStoreService);
  private generalService = inject(GeneralService);

  cardContactData$: Observable<CardContactData>;

  override formGroup = this.formBuilder.group({
    contact: this.formBuilder.group({
      subject: this.formBuilder.control<string>("", [Validators.required]),
      firstName: this.formBuilder.control<string>("", [Validators.required]),
      lastName: this.formBuilder.control<string>("", [Validators.required]),
      email: this.formBuilder.control<string>("", [Validators.required]),
      phone: this.formBuilder.control<string>("", [Validators.required]),
      message: this.formBuilder.control<string>("", [Validators.required])
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.cardContactData$ = combineLatest([
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([userAccount]) => {
          return {
            userAccount
          };
        }
      )
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const contactControls = this.formGroup.get("contact") as FormGroup;
    if (this.submittable(contactControls)) {
      this.submitting.set(true);
      this.generalService.sendContact(contactControls.value as EmailContactInterface).pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.setFormMessage(this.translations.getTranslation(this.translations.FORM_EMAIL_IS_SENT), Q90ResponseLevels.Success);
            this.formGroup.reset();
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
      });
    }
  }
}
