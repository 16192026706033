<ng-container *ngIf="contactPageData$ | async as contactPageData">
  <div class="grid grid-cols-6 gap-x-vertical-base grow">
    <section class="col-span-6 md:col-span-1">
      <app-side-menu-help />
    </section>
    <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
      <section class="col-span-6">
        <h2 i18n>Contact</h2>
        <app-card-contact />
      </section>
    </div>
  </div>
</ng-container>
