<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <div class="grid-xp grid-cols-2 spread left-heavy align-center">
        <h3 i18n>Wachtwoord Vergeten</h3>
        <p>
          <a
            i18n
            routerLink="/login"
            >Terug naar Inloggen</a
          >
        </p>
      </div>
      <p i18n>Weet u uw wachtwoord niet meer? Vul uw e-mailadres in en wij sturen u een e-mail met een herstel link.</p>
    </header>
    <fieldset formGroupName="forgotPassword">
      <shared-form-email
        [styleClass]="'xperience'"
        [submitted]="submitted"
        [label]="LABEL_EMAIL"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'forgotPassword.emailAddress'">
      </shared-form-email>
    </fieldset>
    <footer>
      <div class="button-bar align-left">
        <shared-form-submit
          [buttonLabel]="LABEL_BUTTON_RESET_PASSWORD"
          [buttonClass]="'is-colored'"
          [spinnerColorMode]="'light'"
          [showLabelOnSubmit]="false"
          [pristine]="formGroup.pristine"
          [submitting]="submitting()" />
      </div>
      <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
    </footer>
  </form>
</div>
