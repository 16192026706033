import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { CardAccountDeleteComponent } from '../../cards/card-account-delete/card-account-delete.component';
import { CardAccountComponent } from '../../cards/card-account/card-account.component';
import { CardEmailComponent } from '../../cards/card-email/card-email.component';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { CardPasswordComponent } from '../../cards/card-password/card-password.component';
import { SideMenuHelpComponent } from '../../components/side-menu-help/side-menu-help.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccountInterface } from '../../models/account.model';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { SideMenuExtraComponent } from "../../components/side-menu-extra/side-menu-extra.component";
import { GeneralStoreService } from '../../stores/general-store.service';
import { ContentTemplateInterface } from '../../models/content-template.model';
import { SpinnerComponent } from '../../components/spinner/spinner.component';

interface AboutPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
  content: ContentTemplateInterface | null;
}

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf, SideMenuComponent, CardAccountComponent, CardPairingComponent, CardPasswordComponent, CardEmailComponent,
    AsyncPipe, SpinnerComponent,
    CardAccountDeleteComponent,
    SideMenuHelpComponent,
    SideMenuExtraComponent
  ]
})
export class AboutPageComponent implements OnInit {

  aboutPageData$: Observable<AboutPageData>;

  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  constructor() {
  }

  ngOnInit(): void {
    this.aboutPageData$ = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$,
      this.generalStore.getContentByCode('ABOUT_US')
    ]).pipe(
      map(
        ([isLoggedIn, userAccount, content]) => {
          return {
            isLoggedIn, userAccount, content
          };
        }
      )
    );
  }
}
