import { inject, Pipe, PipeTransform } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ErrorService } from "../../../services/error.service";

@Pipe({
  name: "getFormControl",
  standalone: true
})
export class GetFormControlPipe implements PipeTransform {

  private errorService = inject(ErrorService);

  transform(formGroup: FormGroup, ...args: unknown[]): FormControl {
    const path = args[0] as string;
    const result = formGroup.get(path) as FormControl;
    if (!result) {
      this.errorService.setError(new Error("Form field " + path + " was not found in this form."));
    }
    return result;
  }
}
