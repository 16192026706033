<ng-container *ngIf="cardYourSubscriptionData$ | async as cardYourSubscriptionData">
  <div class="card">
    <header>
      <h3 i18n>Uw abonnement</h3>
    </header>

    <ng-container *ngIf="cardYourSubscriptionData.validSubscriptions.length < 1 && !cancelMode">
      <p
        i18n
        class="subtitle">
        U heeft nog geen abonnementen.
      </p>
      <div class="button-bar align-left">
        <button
          i18n
          class="is-colored"
          routerLink="/crw/subscription">
          Bekijk abonnementen
        </button>
      </div>
    </ng-container>

    <section
      class="sub"
      *ngFor="let sub of cardYourSubscriptionData.validSubscriptions">
      <ng-container *ngIf="sub && !cancelMode">
        <p i18n>U heeft momenteel een geldig abonnement. Als u een nieuw abonnement wilt, moet u eerst uw huidige abonnement annuleren.</p>
        <div class="details">
          <h5>
            {{ sub.title }}
          </h5>
          <div class="detail">
            <p i18n>Start datum: {{ sub.startDate | beautifyDate }} | Eind datum: {{ sub.endDate | beautifyDate }}</p>
          </div>
          <div class="detail">
            <h5 i18n>Prijs:</h5>
            <p *ngIf="sub.costs">
              € {{ sub.costs }} /
              {{ sub.periodUnit }}
            </p>
            <p
              *ngIf="!sub.costs"
              i18n>
              Gratis
            </p>
          </div>
          <div class="detail">
            <h5 i18n>automatisch</h5>
            <p>
              {{ sub.isRunning ? "yes" : "no" }}
            </p>
          </div>

          <div class="button-bar align-left">
            <button
              [disabled]="!sub.isRunning"
              i18n
              (click)="cancelMode = true"
              class="is-colored">
              Huidge abonnement annuleren
            </button>
            <p
              class="info-block"
              i18n
              *ngIf="!sub.isRunning">
              Eenmalige abonnementen kunnen niet geanuleerd worden
            </p>
          </div>
        </div>
      </ng-container>
    </section>

    <ng-container *ngIf="cancelMode">
      <form
        (click)="formClicked($event)"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit($event)">
        <shared-form-input-hidden
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="'Product Id'"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'productId'" />
        <div class="button-bar align-left">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_CANCEL_SUBSCRIPTION"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [submitting]="submitting()" />
          <button
            i18n
            type="button"
            class="is-transparent"
            (click)="setCancelMode(0)">
            Nee, terug
          </button>
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </form>
    </ng-container>
  </div>
</ng-container>
