import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Router, RouterOutlet } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { ApplicationService } from "../../services/application.service";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { GeneralService } from "../../services/general.service";
import { GeneralStoreService } from "../../stores/general-store.service";
import { LiveTimeService } from "../../services/live-time.service";
import { ProfilesService } from "../../services/profiles.service";
import { UserAccountService } from "../../services/user-account.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { ErrorService } from "../../services/error.service";
import { TopbarComponent } from "../../components/topbar/topbar.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { AsyncPipe, NgIf } from "@angular/common";

interface LayoutAccountData {
  darkMode: boolean;
  isLoggedIn: boolean;
}

@Component({
  selector: "app-layout-account",
  standalone: true,
  imports: [
    TopbarComponent,
    RouterOutlet,
    LoaderComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: "./layout-account.component.html",
  styleUrl: "./layout-account.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutAccountComponent implements OnInit {

  layoutAccountData$!: Observable<LayoutAccountData>;
  public router = inject(Router);

  private authenticationService = inject(AuthenticationService);
  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private generalService = inject(GeneralService);
  private generalStore = inject(GeneralStoreService);
  private liveTimeService = inject(LiveTimeService);
  private profilesService = inject(ProfilesService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);

  constructor() {
  }

  ngOnInit(): void {
    this.layoutAccountData$ = combineLatest([
      this.generalStore.darkMode$,
      this.userAccountStore.isLoggedIn$,
    ]).pipe(
      map(
        ([
          darkMode,
          isLoggedIn,
        ]) => {
          return {
            darkMode,
            isLoggedIn,
          };
        }
      )
    );
  }

}
