import { NgIf, AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { CardAccountDeleteComponent } from '../../cards/card-account-delete/card-account-delete.component';
import { CardAccountComponent } from '../../cards/card-account/card-account.component';
import { CardEmailComponent } from '../../cards/card-email/card-email.component';
import { CardPairingComponent } from '../../cards/card-pairing/card-pairing.component';
import { CardPasswordComponent } from '../../cards/card-password/card-password.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccountInterface } from '../../models/account.model';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { SideMenuHelpComponent } from "../../components/side-menu-help/side-menu-help.component";
import { CardContactComponent } from '../../cards/card-contact/card-contact.component';
import { GeneralStoreService } from '../../stores/general-store.service';
import { FaqInterface } from '../../models/faq.model';
import { SpinnerComponent } from '../../components/spinner/spinner.component';

interface FaqPageData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
  faqs: FaqInterface[]
}
@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf, SideMenuComponent, CardAccountComponent, CardPairingComponent, CardPasswordComponent, CardEmailComponent,
    AsyncPipe, CommonModule, SpinnerComponent,
    CardAccountDeleteComponent,
    SideMenuHelpComponent, CardContactComponent
  ]
})
export class FaqPageComponent implements OnInit {

  faqPageData$: Observable<FaqPageData>;

  showDeleteAccountCard: WritableSignal<boolean> = signal(false);

  activeFaq: WritableSignal<FaqInterface | null> = signal(null);

  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);

  constructor() {
  }

  ngOnInit(): void {
    this.faqPageData$ = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$,
      this.generalStore.faqs$
    ]).pipe(
      map(
        ([isLoggedIn, userAccount, faqs]) => {
          return {
            isLoggedIn, userAccount, faqs
          };
        }
      )
    );
  }

  setActiveFaq(e: Event, faq: FaqInterface): void {
    if (this.activeFaq()?.id === faq.id) {
      this.activeFaq.set(null);
    } else {
      this.activeFaq.set(faq);
    }
  }
}
