import { Component, inject, OnInit } from '@angular/core';
import { combineLatest, map, Observable, pipe, take } from 'rxjs';
import { CrwStoreService } from '../../stores/crw-store.service';
import { PurchaseData, SubscriptionInterface } from '../../models/subscription.model';
import { BeautifyDurationPipe as BeautifyDurationPipe_1 } from '../../pipes/beautify-duration.pipe';
import { CentsToPricePipe as CentsToPricePipe_1 } from '../../pipes/cents-to-price.pipe';
import { Router, RouterLink } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { SubscriptionService } from '../../services/subscription.service';
import { PaymentMethodInterface } from '../../models/payment-method.model';

interface CardOverviewData {
  crwFlowSection: CrwFlowSection;
  subscription: SubscriptionInterface;
  paymentMethod: PaymentMethodInterface;
  purchaseData: PurchaseData;
}

@Component({
  selector: 'app-card-overview',
  templateUrl: './card-overview.component.html',
  styleUrls: ['./card-overview.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, AsyncPipe, CentsToPricePipe_1, BeautifyDurationPipe_1],
})
export class CardOverviewComponent implements OnInit {
  private subscriptionService = inject(SubscriptionService);
  private crwStore = inject(CrwStoreService);
  private router = inject(Router);

  modalOpen: boolean = true;

  cardOverviewData$: Observable<CardOverviewData>;
  purchaseData$: Observable<PurchaseData>;

  constructor() { }

  ngOnInit() {
    this.cardOverviewData$ = combineLatest([this.crwStore.crwSection$, this.crwStore.crwSubCart$, this.crwStore.crwPaymentMethod$, this.crwStore.crwPaymentResult$]).pipe(
      map(([crwFlowSection, subscriptions, paymentMethod, purchaseData]) => {
        const subscription = subscriptions[0];
        if (!subscription) this.router.navigate(['/crw/subscription']);
        return {
          crwFlowSection,
          subscription,
          paymentMethod,
          purchaseData,
        };
      }),
    );

    const pst = localStorage.getItem('pst');
    if (pst) {
      this.checkPsToken();
    }
  }

  order() {
    this.cardOverviewData$.pipe(take(1)).subscribe((data) => {
      this.subscriptionService.getPaymentUrl(data.subscription.productId, data.paymentMethod.paymentCode, 'https://google.com', '/crw/payment').subscribe((res) => {
        localStorage.setItem('pst', res.data.paymentSession);
        // window.open(res.data.url, '_blank');
        window.location.href = res.data.url;
      });
    });
  }
  checkPsToken() {
    this.subscriptionService.getPaymentSession(localStorage.getItem('pst')).subscribe((res) => {
      console.log(res.data);
      this.crwStore.setCrwPaymentResult(res.data);
      localStorage.setItem('pst', '');
    });
  }
  checkStatus(status: string) {
    this.modalOpen = false;
    this.crwStore.setCrwPaymentResult(null);
    if (status === 'PaymentSuccess') {
      this.crwStore.clearCart()
      // this.crwStore.removeFromCrwSubCart(0);
      this.crwStore.setCrwPaymentMethod(null);
      this.crwStore.setCrwShadowSub(null);
      this.crwStore.setSubscription(null);
      this.router.navigate(['/account/subscription']);
    }
  }
}
