<ng-container *ngIf="crwFlowData$ | async as crwFlowData">
  <div class="card">
    @if (accountActivated()) {
      <header>
        <h3 i18n>Account Geactiveerd</h3>
        <p i18n>Uw account is geactiveerd. U kan nu <a [routerLink]="['/login']">Inloggen.</a></p>
      </header>
    } @else {
      <form
        (click)="formClicked($event)"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit($event)">
        <header>
          <!-- <button
            i18n
            type="button"
            class="is-transparent"
            (click)="goBack()">
            <span class="mci-arrowhead-left"></span>
            Terug
          </button> -->
          <h3 i18n>Account aangemaakt</h3>
          <p i18n>
            Uw account is aangemaakt. Controleer uw inbox op een verificatiemail. Door op de link in de e-mail te klikken activeert u uw account. U kunt ook de activatie code uit de e-mail in het onderstaande formulier invullen als de link
            niet werkt. Na activatie van uw account kunt u doorgaan met uw aankoop.
          </p>
        </header>
        <fieldset formGroupName="activateAccount">
          <shared-form-input
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_ACTIVATION_KEY"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'activateAccount.activationKey'" />
        </fieldset>
        <footer>
          <div class="button-bar align-left">
            <shared-form-submit
              [buttonLabel]="LABEL_BUTTON_ENTER_ACTIVATION_KEY"
              [buttonClass]="'is-colored'"
              [spinnerColorMode]="'light'"
              [showLabelOnSubmit]="false"
              [pristine]="formGroup.pristine"
              [submitting]="submitting()" />
            <button
              i18n
              type="button"
              class="is-transparent"
              (click)="goBack()">
              Terug
            </button>
          </div>
          <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
        </footer>
      </form>
    }
  </div>
</ng-container>
