import { APP_INITIALIZER, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { XperienceRoutes } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LogoPipe } from './app/pipes/logo.pipe';
import { DatePipe } from '@angular/common';
import { CentsToPricePipe } from './app/pipes/cents-to-price.pipe';
import { BeautifyDurationPipe } from './app/pipes/beautify-duration.pipe';
import { provideRouter } from "@angular/router";
import { GeneralService } from './app/services/general.service';
import { Observable, catchError, concatMap, of, take, tap } from 'rxjs';
import { GeneralStoreService } from './app/stores/general-store.service';
import { BrandInterface } from './app/models/brand.model';
import { AuthenticationInterceptor } from './app/functions/authentication-interceptor';
import { languageHeaderInterceptor } from './app/functions/language-header.interceptor';
import { UserAccountService } from './app/services/user-account.service';
import { ProfilesService } from './app/services/profiles.service';
import { BackgroundImageStoreService } from './app/stores/background-image-store.service';
import { loadTranslations } from '@angular/localize';
import { LocaleService, getTranslations } from '@soluling/angular';
import '@angular/localize/init';
import { UserAccountStoreService } from './app/stores/user-account-store.service';
import { ProfilesStoreService } from './app/stores/profiles-store.service';
import { provideServiceWorker } from '@angular/service-worker';

/**
 * Initializes language and route guard related stores (see below)
 *
 * Initializing default brand, application settings (using default brand id) and
 * languages stores before priming, is the idea. ;) Calling these apis sets a signal
 * in LanguageHeaderService used for adding x-language to call headers via the HttpInterceptor
 * function languageHeaderInterceptor...
 * 
 * Looks for current language in the following order with later entreis taking priority:
 * 1) 'language' code in cookie.
 * 2) Default language from Xperience Admin
 * 3) Language setting authenticated user account
 * 
 * Reference to other stores, has to do with with preloading stores that are referenced
 * in route guards. Instead of an API call to tha backend each time -- the guards are used quite
 * a bit -- this guards check store values. This should cut down on calls to backend and
 * hopefully load (or not load) routes quicker.
 *
 * @param http
 * @param generalService
 * @param generalStore
 * @returns
 */
function initializeApp(
  userAccountService: UserAccountService,
  userAccountStore: UserAccountStoreService,
  profilesService: ProfilesService,
  profilesStore: ProfilesStoreService,
  generalService: GeneralService,
  generalStore: GeneralStoreService
): () => Observable<any> {
  return () => generalService.getLanguages().pipe(
    tap(languages => generalStore.setLanguages(languages.data)),
    concatMap(languages => generalService.getDefaultBrand().pipe(
      tap(defaultBrand => generalStore.setDefaultBrand(defaultBrand.data as BrandInterface)),
      concatMap(defaultBrand => generalService.getApplicationSettings(defaultBrand.data.id).pipe(
        tap(settings => generalStore.setApplicationSettings(settings.data))
      ))
    )),
    concatMap(defaultBrand => userAccountService.isLoggedIn().pipe(
      tap(isLoggedIn => {
        userAccountStore.setIsLoggedIn(isLoggedIn.data as boolean);
      }),
      catchError((err) => of(false))
    )),
    concatMap(isLoggedIn => userAccountService.getUserAccount().pipe(
      tap(userAccount => {
        userAccountStore.setUserAccount(userAccount.data);
      }),
      catchError((err) => of(false))
    )),
    concatMap(userAccount => userAccountService.getUserSubscriptions().pipe(
      tap(userSubscriptions => {
        userAccountStore.setUserAccountSubscriptions(userSubscriptions.data);
      }),
      catchError((err) => of(false))
    )),
    concatMap(userSubscriptions => profilesService.getProfiles().pipe(
      tap((userProfiles) => {
        profilesStore.setUserProfiles(userProfiles.data);
      }),
      catchError((err) => of(false))
    )),
    concatMap(userProfiles => profilesService.getActiveProfile().pipe(
      tap((activeProfile) => {
        profilesStore.setUserActiveProfile(activeProfile.data);
      }),
      catchError((err) => of(false))
    )),
    catchError((err) => { throw err; }),
    take(1),
  );
}

getTranslations('assets/i18n', false, true, true, null, null, 'language').then(translations => {
  if (translations)
    loadTranslations(translations);
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    importProvidersFrom(NoopAnimationsModule),
    provideRouter(XperienceRoutes),
    provideHttpClient(withInterceptors([
        AuthenticationInterceptor,
        languageHeaderInterceptor
    ])),
    BeautifyDurationPipe,
    CentsToPricePipe,
    DatePipe,
    LogoPipe,
    BackgroundImageStoreService,
    LocaleService,
    { provide: LOCALE_ID, deps: [LocaleService], useFactory: (service: LocaleService) => service.localeId },
    {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: initializeApp,
        deps: [
            UserAccountService,
            UserAccountStoreService,
            ProfilesService,
            ProfilesStoreService,
            GeneralService,
            GeneralStoreService
        ]
    },
    // provideServiceWorker('ngsw-worker.js', {
    //     enabled: true,
    //     // enabled: !isDevMode(),
    //     registrationStrategy: 'registerWhenStable:30000'
    // }),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
]
}).catch(err => console.error(err));

