<ng-container *ngIf="accountData$ | async as accountData">
  <div class="grid grid-cols-6 gap-x-vertical-base grow">
    <section class="col-span-6 md:col-span-1">
      <app-side-menu />
    </section>
    <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
      <section class="col-span-6 md:col-span-3">
        <h2 i18n>Account</h2>
        <app-card-account
          (deleteModeRequested)="showDeleteCard($event)"
          *ngIf="!showDeleteAccountCard()" />
        <app-card-account-delete
          (cancelDeleteMode)="showDeleteCard($event)"
          *ngIf="showDeleteAccountCard()" />
        <app-card-pairing />
      </section>
      <section class="col-span-6 md:col-span-2">
        <app-card-email />
        <app-card-password />
      </section>
    </div>
  </div>
</ng-container>
