import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { GetFormControlPipe } from '../../../pipes/get-form-control.pipe';
import { FormControlComponent } from '../form-control.component';

@Component({
  selector: 'shared-form-radio',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetFormControlPipe, GetAsFormControlPipe],
  templateUrl: './form-radio.component.html',
  styleUrl: './form-radio.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRadioComponent extends FormControlComponent implements OnInit {

  @Input() radioName: string | null = null;
  @Input() radioValue: string | number | null = null;
  @Input() radioChecked: boolean = false;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

}
