import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { take } from "rxjs";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { ApplicationEvent } from "../../services/application.service";
import { SpinnerComponent } from "../spinner/spinner.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, SpinnerComponent, NgIf, NgClass]
})

export class SideMenuComponent {

  private applicationStore = inject(ApplicationStoreService);
  private authenticationService = inject(AuthenticationService);

  loggingOut: WritableSignal<boolean> = signal(false);

  logout(e: Event): void {
    e.preventDefault();
    this.loggingOut.set(true);
    this.authenticationService.userLogout().pipe(
      take(1)
    ).subscribe({
      next: (logout) => {
        this.applicationStore.setApplicationEvent(ApplicationEvent.Logout);
      }
    });
  }
}
