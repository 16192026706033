import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { ProfileInterface } from '../../models/profile.model';
import { LogoPipe } from '../../pipes/logo.pipe';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { GeneralStoreService } from '../../stores/general-store.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { LanguageHeaderService } from '../../services/language-header.service';
import { LogoComponent } from '../logo/logo.component';
import { LocaleService } from '@soluling/angular';
import { SubscriptionInterface } from '../../models/subscription.model';

interface TopBarData {
  isLoggedIn: boolean;
  darkMode: boolean;
  userActiveProfile: ProfileInterface;
  currentSubscription: SubscriptionInterface;
}

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, LogoComponent, NgIf, RouterLink, NgFor, RouterLinkActive, NgStyle, AsyncPipe, LogoPipe, NgClass, AvatarComponent],
})
export class TopbarComponent implements OnInit {
  topBarData$: Observable<TopBarData>;
  showHamburgerMenu: WritableSignal<boolean> = signal(false);

  public languageHeaderService = inject(LanguageHeaderService);
  public router = inject(Router);
  private profilesStore = inject(ProfilesStoreService);
  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private localeService = inject(LocaleService);

  constructor() {}

  ngOnInit(): void {
    this.topBarData$ = combineLatest([this.userAccountStore.isLoggedIn$, this.generalStore.darkMode$, this.profilesStore.userActiveProfile$, this.userAccountStore.currentSubscription$]).pipe(
      map(([isLoggedIn, darkMode, userActiveProfile, currentSubscription]) => {
        return {
          isLoggedIn,
          darkMode,
          userActiveProfile,
          currentSubscription,
        };
      }),
    );
  }

  toggleHamburgerMenu(): void {
    const setting = this.showHamburgerMenu();
    this.showHamburgerMenu.set(!setting);
  }
}
