import { Component, OnInit } from '@angular/core';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'shared-form-pincode-confirm',
  standalone: true,
  imports: [GetAsFormControlPipe, ReactiveFormsModule, NgClass, NgIf],
  templateUrl: './form-pincode-confirm.component.html',
  styleUrl: './form-pincode-confirm.component.scss',
})
export class FormPincodeConfirmComponent extends FormControlComponent implements OnInit {
  showPassword: boolean = false;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.control.addValidators([
      // Validators.required,
      Validators.pattern(/^\d{4}$/i),
      RxwebValidators.compare({ fieldName: 'accessCode' }),
    ]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('pattern')) {
      return 'Field should contain four numeric characters.';
    }
    if (this.control.hasError('compare')) {
      return 'The values for both codes do not match.';
    }
    return super.getErrorMessage();
  }
}
