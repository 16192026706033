import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormCheckboxComponent } from '../../components/forms/components/control/form-checkbox/form-checkbox.component';
import { FormInputHiddenComponent } from '../../components/forms/components/control/form-input-hidden/form-input-hidden.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { FormSelectComponent } from '../../components/forms/components/control/form-select/form-select.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { AccountInterface } from '../../models/account.model';
import { LanguageIdToNamePipe } from '../../pipes/language-id-to-name.pipe';
import { OptionCollection, toOptionCollection } from '../../shared/interfaces/option-collection';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { Observable, combineLatest, map, take } from 'rxjs';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
import { UserAccountService } from '../../services/user-account.service';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { FormEmailComponent } from '../../components/forms/components/control/form-email/form-email.component';
import { XperienceResponse } from '../../models/xperience-response';
import { ApplicationEvent } from '../../services/application.service';

interface CardAccountData {
  userAccount: AccountInterface;
}

@Component({
  selector: 'app-card-account-delete',
  standalone: true,
  templateUrl: './card-account-delete.component.html',
  styleUrl: './card-account-delete.component.scss',
  imports: [
    NgIf, NgClass, NgFor, AsyncPipe, LanguageIdToNamePipe, ErrorMessagesComponent, FormInputComponent,
    FormSelectComponent, FormsModule, GetFormControlPipe, ReactiveFormsModule, RouterLink, SubmitComponent,
    FormCheckboxComponent, FormInputHiddenComponent, FormEmailComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAccountDeleteComponent extends FormComponent implements OnInit {

  @Output() cancelDeleteMode = new EventEmitter<boolean>();
  readyToConfirmAndDelete: WritableSignal<boolean> = signal(false);

  private applicationStore = inject(ApplicationStoreService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private router = inject(Router);

  cardAccountData$: Observable<CardAccountData>;

  private userAccount: AccountInterface;

  override formGroup = this.formBuilder.group({
    deleteAccount: this.formBuilder.group({
      confirm: this.formBuilder.control<string>('', [Validators.required]),
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.cardAccountData$ = combineLatest([
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([userAccount]) => {
          this.userAccount = userAccount;
          return {
            userAccount
          };
        }
      )
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const accountControls = this.formGroup.get("deleteAccount") as FormGroup;
    const emailConfirmed = (accountControls.value.confirm === this.userAccount.emailAddress);
    if (this.submittable(accountControls) && emailConfirmed) {
      this.submitting.set(true);
      this.userAccountService.deleteAccount().pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            // this.applicationStore.setApplicationEvent(ApplicationEvent.Logout);
            this.router.navigate(['/account-deleted']);
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
        complete: () => {
          // this.formGroup.markAsPristine();
        }
      });
    }
    if (!emailConfirmed) {
      this.setFormMessage(this.translations.getTranslation(this.translations.FORM_EMAIL_NOT_CONFIRMED), Q90ResponseLevels.Warning);
    }
  }
}

