import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { AutocompleteOffDirective } from '../../components/forms/directives/autocomplete-off.directive';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormEmailComponent } from '../../components/forms/components/control/form-email/form-email.component';
import { FormEmailConfirmComponent } from '../../components/forms/components/control/form-email-confirm/form-email-confirm.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { take } from 'rxjs';
import { Q90ResponseLevels } from '../../models/q90-response';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { ProfilesService } from '../../services/profiles.service';
import { XperienceResponse } from '../../models/xperience-response';

@Component({
  selector: 'app-card-forgot-pincode',
  templateUrl: './card-forgot-pincode.component.html',
  styleUrls: ['./card-forgot-pincode.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, AutocompleteOffDirective, ErrorMessagesComponent, FormEmailComponent, FormEmailConfirmComponent, GetFormControlPipe, ReactiveFormsModule, ReactiveFormsModule, SubmitComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardForgotPincodeComponent extends FormComponent implements OnInit {
  private profilesService = inject(ProfilesService);
  profilesStore = inject(ProfilesStoreService);

  router = inject(Router);

  override formGroup = this.formBuilder.group({});

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (!this.profilesStore.getpinResetProfile().value) this.router.navigate(['/profiles']);
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    this.submitting.set(true);
    this.profilesService
      .requestPincodeReset(this.profilesStore.getpinResetProfile()?.value?.profileId)
      .pipe(take(1))
      .subscribe((res) => {
        this.submitting.set(false);
        if (XperienceResponse.isSuccess(res)) {
          this.setFormMessage(this.translations.getTranslation(this.translations.FORM_PINCODE_RESET_SENT), Q90ResponseLevels.Success);
        } else {
          const error = new XperienceResponse().deserialize(res);
          error.setLevel(Q90ResponseLevels.Danger);
          this.errorMessagesStore.setErrorMessage(error);
        }
      });
  }
}
