import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { RouterLink } from '@angular/router';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { SubscriptionService } from '../../services/subscription.service';
import { Observable, combineLatest, map, take } from 'rxjs';
import { SubscriptionInterface } from '../../models/subscription.model';
import { CrwStoreService } from '../../stores/crw-store.service';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';

interface CrwVoucherData {
  crwFlowSection: CrwFlowSection;
  crwSubCart: SubscriptionInterface[];
  crwSub: SubscriptionInterface;
}

@Component({
  selector: 'app-card-voucher',
  templateUrl: './card-voucher.component.html',
  styleUrls: ['./card-voucher.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass, ErrorMessagesComponent, FormInputComponent, GetFormControlPipe, PaginatorModule, ReactiveFormsModule, RouterLink, SubmitComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardVoucherComponent extends FormComponent implements OnInit {
  private SubscriptionStore = inject(SubscriptionService);
  private crwStore = inject(CrwStoreService);

  productId: number = 0;
  crwVoucherData$!: Observable<CrwVoucherData>;
  currentSub: SubscriptionInterface;

  override formGroup = this.formBuilder.group({
    voucher: this.formBuilder.group({
      code: this.formBuilder.control<string>('', [Validators.required]),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.crwVoucherData$ = combineLatest([this.crwStore.crwSection$, this.crwStore.crwSubCart$, this.crwStore.crwSub$]).pipe(
      map(([crwFlowSection, crwSubCart, crwSub]) => {
        this.productId = crwSubCart[0]?.productId;
        this.currentSub = crwSub;
        return {
          crwFlowSection,
          crwSubCart,
          crwSub,
        };
      }),
    );
  }

  override onSubmit() {
    const voucherControls = this.formGroup.get('voucher') as FormGroup;
    if (this.submittable(voucherControls)) {
      this.submitting.set(true);
      this.SubscriptionStore.redeemVoucher(this.productId, voucherControls.controls['code'].value, this.currentSub.channelId, this.currentSub.mainProductId)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.submitting.set(false);
            if (XperienceResponse.isSuccess(res)) {
              const returnedSub = res.data.paymentTable.products.filter((obj) => {
                return obj.id === this.currentSub.productId;
              });

              returnedSub[0].costs = returnedSub[0].cost;
              returnedSub[0].productId = returnedSub[0].id;

              this.crwStore.setSubscription({ ...this.currentSub, ...returnedSub[0] });
              this.crwStore.setCrwShadowSub(this.currentSub);

              this.setFormMessage(`Voucher ${voucherControls.controls['code'].value} added`, Q90ResponseLevels.Success);
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            voucherControls.reset();
            this.formGroup.markAsPristine();
          },
        });
    }
  }
}
