import { Component, EventEmitter, inject, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { DateBetweenDatesPipe } from '../../pipes/date-between-dates.pipe';
import { RouterLink } from '@angular/router';
import { AsyncPipe, DatePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { LiveTimeStoreService } from '../../stores/live-time-store.service';
import { combineLatest, map, Observable } from 'rxjs';
import { EpgItemInterface } from '../../models/epg-item';
import { EpgService } from '../../services/epg.service';
interface EpgGridItemData {
  liveDate: Date;
}
@Component({
  selector: 'epg-grid-item',
  templateUrl: './epg-grid-item.component.html',
  styleUrls: ['./epg-grid-item.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, RouterLink, DatePipe, DateBetweenDatesPipe, NgIf, AsyncPipe],
})
export class EpgGridItemComponent implements OnInit {
  private epgService = inject(EpgService);
  private liveTimeStore = inject(LiveTimeStoreService);

  @Input({ required: true }) item: EpgItemInterface;
  @Input({ required: true }) channelId: number;

  @Output() setNewItem = new EventEmitter<any>();

  epgGridItemData$: Observable<EpgGridItemData>;
  width: WritableSignal<number> = signal(0);

  constructor() {}

  setItem(channelId,productId:number, startDate:string) {
    this.setNewItem.emit({ channelId, productId, startDate });
  }

  ngOnInit() {
    this.width.set(this.epgService.getItemDurationHours(this.item));

    this.epgGridItemData$ = combineLatest([this.liveTimeStore.liveDate$]).pipe(
      map(([liveDate]) => {
        return {
          liveDate,
        };
      }),
    );
  }
}
