import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Router, RouterOutlet } from "@angular/router";
import { ApplicationService } from "../../services/application.service";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { GeneralStoreService } from "../../stores/general-store.service";
import { ProfilesStoreService } from "../../stores/profiles-store.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { ErrorService } from "../../services/error.service";
import { ProfileInterface } from "../../models/profile.model";
import { LogoComponent } from "../../components/logo/logo.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { Q90ErrorResponseTypes } from "../../interfaces/q90-response";
import { LoaderComponent } from "../../components/loader/loader.component";
import { TopbarComponent } from "../../components/topbar/topbar.component";

interface LayoutCrwData {
  darkMode: boolean;
  isLoggedIn: boolean;
  xperienceMessage: Q90ErrorResponseTypes | null;
  userProfiles: ProfileInterface[];
}

@Component({
  selector: "app-layout-crw",
  standalone: true,
  imports: [
    RouterOutlet,
    LogoComponent,
    AsyncPipe,
    NgIf,
    LogoComponent,
    LoaderComponent,
    TopbarComponent
  ],
  templateUrl: "./layout-crw.component.html",
  styleUrl: "./layout-crw.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutCrwComponent implements OnInit {

  layoutCrwData$!: Observable<LayoutCrwData>;
  public router = inject(Router);

  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private generalStore = inject(GeneralStoreService);
  protected profilesStore = inject(ProfilesStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);

  constructor() {
  }

  ngOnInit(): void {
    this.layoutCrwData$ = combineLatest([
      this.generalStore.darkMode$,
      this.userAccountStore.isLoggedIn$,
      this.errorService.error$,
      this.profilesStore.userProfiles$
    ]).pipe(
      map(
        ([
          darkMode,
          isLoggedIn,
          xperienceMessage,
          userProfiles
        ]) => {
          return {
            darkMode,
            isLoggedIn,
            xperienceMessage,
            userProfiles
          };
        }
      )
    );
  }

}
