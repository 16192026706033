<ng-container *ngIf="crwPageData$ | async as crwPageData">
  <div class="grid grid-cols-6 gap-vertical-base grow">
    <header class="col-span-6 flex grow items-center">
      <div class="flex grow">
        <button
          i18n
          type="button"
          (click)="toPreviousPage()"
          class="button">
          <span class="mci-arrowhead-left"></span>
          Annuleren
        </button>
      </div>
      <div>
        <app-logo [forceLightMode]="true" />
      </div>
    </header>
    <section class="col-span-6 md:col-span-1">
      <app-side-menu-crw />
    </section>
    <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
      <section class="col-span-6 md:col-span-3">
        <router-outlet />
      </section>
      <section class="col-span-6 md:col-span-2">
        <app-card-shopping-cart
          [items]="shoppingCartItems"
          chdjhv>
        </app-card-shopping-cart>
        <app-card-voucher></app-card-voucher>
      </section>
    </div>
  </div>
</ng-container>
