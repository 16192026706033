import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { CrwFlowSection } from 'src/app/pages/crw-page/crw-page.component';
import { CrwStoreService } from '../../stores/crw-store.service';
import { PricesToTotalPipe } from '../../pipes/prices-to-total.pipe';
import { CentsToPricePipe } from '../../pipes/cents-to-price.pipe';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SubscriptionInterface } from '../../models/subscription.model';

interface CrwFlowData {
  crwFlowSection: CrwFlowSection;
  crwSubCart: SubscriptionInterface[];
  crwShadowSub: SubscriptionInterface;
}

@Component({
  selector: 'app-card-shopping-cart',
  templateUrl: './card-shopping-cart.component.html',
  styleUrls: ['./card-shopping-cart.component.scss'],
  standalone: true,
  imports: [CommonModule, NgIf, NgFor, NgClass, CentsToPricePipe, PricesToTotalPipe],
})
export class CardShoppingCartComponent implements OnInit {
  private crwStore = inject(CrwStoreService);

  crwFlowData$!: Observable<CrwFlowData>;

  total: string = '';

  @Input() items: Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.crwFlowData$ = combineLatest([this.crwStore.crwSection$, this.crwStore.crwSubCart$, this.crwStore.crwShadowSub$]).pipe(
      map(([crwFlowSection, crwSubCart, crwShadowSub]) => {
        this.setTotal(crwSubCart);
        return {
          crwFlowSection,
          crwSubCart,
          crwShadowSub,
        };
      }),
    );
  }

  remove(item: SubscriptionInterface) {
    this.crwStore.removeFromCrwSubCart(item);
  }

  setTotal(items: SubscriptionInterface[]) {
    let total = 0;
    items.forEach((item) => {
      if (item && item.costs) total += Number(item.costs.replace(/,/g, '.'));
    });

    let negative = false;

    if (total < 0) {
      negative = true;
      total = total * -1;
    }
    this.total = (negative ? '- € ' : '€ ') + total.toFixed(2);
  }

  pay() {
    // let baseUrl = window.location.origin;
    //
    // localStorage.setItem("crwFlow", JSON.stringify(this.crwFlow));
    //
    // this.api.getPaymentUrl(
    //   this.crwFlow.subscription.selectedProductId,
    //   this.crwFlow.payment.selectedPaymentMethodCode,
    //   baseUrl + "/crw/overview",
    //   baseUrl + "/crw/payment"
    // ).subscribe(res => {
    //   localStorage.setItem("crwSession", res.data.paymentSession);
    //   location.href = res.data.url;
    // });
  }
}
