import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { FormControlComponent } from '../form-control.component';

@Component({
  selector: 'shared-form-pairing-code',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, GetAsFormControlPipe, NgIf],
  templateUrl: './form-pairing-code.component.html',
  styleUrl: './form-pairing-code.component.scss',
})
export class FormPairingCodeComponent extends FormControlComponent implements OnInit {
  showPassword: boolean = false;
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.control.addValidators([Validators.pattern(/^\d{6}$/i)]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('pattern')) {
      return 'Pairing code should contain six numeric characters.';
    }
    return super.getErrorMessage();
  }
}
