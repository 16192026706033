import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { AccountInterface } from '../../models/account.model';
import { UserAccountService } from '../../services/user-account.service';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { CommonModule } from '@angular/common';

interface CardYourAccountData {
  userAccount: AccountInterface;
}
@Component({
  selector: 'app-card-account-your',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-account-your.component.html',
  styleUrl: './card-account-your.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAccountYourComponent implements OnInit {

  @Output() deleteModeRequested = new EventEmitter<boolean>();

  editMode: WritableSignal<boolean> = signal(false);

  private applicationStore = inject(ApplicationStoreService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private generalStore = inject(GeneralStoreService);

  cardYourAccountData$: Observable<CardYourAccountData>;

  private userAccount: AccountInterface;

  constructor() {
  }

  ngOnInit(): void {
    this.cardYourAccountData$ = combineLatest([
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([userAccount]) => {
          return {
            userAccount
          };
        }
      )
    );
  }

}
