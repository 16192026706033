import { Routes } from '@angular/router';
import { pageTvGuard } from './guards/page-tv.guard';
import { userHasSubscriptionGuard } from './guards/user-has-subscription.guard';
import { userIsLoggedInGuard } from './guards/user-is-logged-in.guard';
import { userIsNotLoggedInGuard } from './guards/user-is-not-logged-in.guard';
import { LayoutAccountComponent } from './layouts/layout-account/layout-account.component';
import { LayoutCrwComponent } from './layouts/layout-crw/layout-crw.component';
import { LayoutExtraComponent } from './layouts/layout-extra/layout-extra.component';
import { LayoutHelpComponent } from './layouts/layout-help/layout-help.component';
import { LayoutPlayerComponent } from './layouts/layout-player/layout-player.component';
import { LayoutProfilesComponent } from './layouts/layout-profiles/layout-profiles.component';
import { LayoutTopbarComponent } from './layouts/layout-topbar/layout-topbar.component';
import { LayoutWithBackgroundComponent } from './layouts/layout-with-background/layout-with-background.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AccountDeletedPageComponent } from './pages/account-deleted-page/account-deleted-page.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { ActivatePageComponent } from './pages/activate-page/activate-page.component';
import { ChangePincodePageComponent } from './pages/change-pincode-page/change-pincode-page.component';
import { ChannelPageComponent } from './pages/channel-page/channel-page.component';
import { ColophonPageComponent } from './pages/colophon-page/colophon-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { CookiePolicyPageComponent } from './pages/cookie-policy-page/cookie-policy-page.component';
import { CreateProfilePageComponent } from './pages/create-profile-page/create-profile-page.component';
import { CrwAccountComponent } from './pages/crw-account/crw-account.component';
import { CrwOverviewComponent } from './pages/crw-overview/crw-overview.component';
import { CrwPageComponent } from './pages/crw-page/crw-page.component';
import { CrwPaymentComponent } from './pages/crw-payment/crw-payment.component';
import { CrwSelectionComponent } from './pages/crw-selection/crw-selection.component';
import { CrwSubscriptionComponent } from './pages/crw-subscription/crw-subscription.component';
import { EditProfilePageComponent } from './pages/edit-profile-page/edit-profile-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { FeedbackPageComponent } from './pages/feedback-page/feedback-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ItemPageComponent } from './pages/item-page/item-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { PairingCodePageComponent } from './pages/pairing-code-page/pairing-code-page.component';
import { PlayerPageComponent } from './pages/player-page/player-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { ProfilesPageComponent } from './pages/profiles-page/profiles-page.component';
import { ReleasesPageComponent } from './pages/releases-page/releases-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { SubscriptionPageComponent } from './pages/subscription-page/subscription-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { TvPageComponent } from './pages/tv-page/tv-page.component';
import { LayoutTopbarWithBackgroundComponent } from './layouts/layout-topbar-with-background/layout-topbar-with-background.component';
import { ForgotPincodePageComponent } from './pages/forgot-pincode-page/forgot-pincode-page.component';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';

export const XperienceRoutes: Routes = [
  {
    path: '',
    title: '192TV',
    component: LayoutTopbarComponent,
    children: [
      {
        path: '',
        component: TvPageComponent,
        pathMatch: 'full',
        canActivate: [pageTvGuard],
      },
      {
        path: 'tv',
        pathMatch: 'full',
        redirectTo: '',
      },
      {
        path: 'home',
        pathMatch: 'full',
        redirectTo: '',
      },
      {
        path: 'tv/channel/:channelId',
        component: ChannelPageComponent,
        pathMatch: 'full',
        canActivate: [userHasSubscriptionGuard],
      },
      {
        path: 'item/:channelId/:itemId/:startDate',
        component: ItemPageComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutPlayerComponent,
    children: [
      {
        path: 'watch/:channelId/:itemId/:startDate',
        component: PlayerPageComponent,
        pathMatch: 'full',
        canActivate: [userHasSubscriptionGuard],
      },
      {
        path: 'promo',
        // path: 'promo/:promoType',
        component: PlayerPageComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutAccountComponent,
    children: [
      {
        path: 'account',
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full',
          },
          {
            path: 'info',
            component: AccountPageComponent,
            pathMatch: 'full',
            canActivate: [userIsLoggedInGuard],
          },
          {
            path: 'subscription',
            pathMatch: 'full',
            component: SubscriptionPageComponent,
            canActivate: [userIsLoggedInGuard],
          },
        ],
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutHelpComponent,
    children: [
      {
        path: 'help',
        children: [
          {
            path: '',
            redirectTo: 'faq',
            pathMatch: 'full',
          },
          {
            path: 'contact',
            component: ContactPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'feedback',
            component: FeedbackPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'faq',
            component: FaqPageComponent,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutExtraComponent,
    children: [
      {
        path: 'info',
        children: [
          {
            path: '',
            redirectTo: 'about',
            pathMatch: 'full',
          },
          {
            path: 'about',
            component: AboutPageComponent,
          },
          {
            path: 'colophon',
            component: ColophonPageComponent,
          },
          {
            path: 'terms-and-conditions',
            component: TermsAndConditionsPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'privacy-policy',
            component: PrivacyPolicyPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'cookie-policy',
            component: CookiePolicyPageComponent,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutProfilesComponent,
    children: [
      {
        path: 'profiles',
        component: ProfilesPageComponent,
        pathMatch: 'full',
        canActivate: [userIsLoggedInGuard],
      },
      {
        path: 'profiles/create',
        component: CreateProfilePageComponent,
        pathMatch: 'full',
        canActivate: [userIsLoggedInGuard],
      },
      {
        path: 'profiles/profile/:profileId/edit',
        component: EditProfilePageComponent,
        pathMatch: 'full',
        canActivate: [userIsLoggedInGuard],
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutTopbarWithBackgroundComponent,
    children: [
      {
        path: 'pairing-code',
        component: PairingCodePageComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutWithBackgroundComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        pathMatch: 'full',
        canActivate: [userIsNotLoggedInGuard],
      },
      {
        path: 'activate',
        component: ActivatePageComponent,
        pathMatch: 'full',
        canActivate: [userIsNotLoggedInGuard],
      },
      {
        path: 'logout',
        component: LogoutPageComponent,
        pathMatch: 'full',
        canActivate: [userIsLoggedInGuard],
      },
      {
        path: 'account-deleted',
        component: AccountDeletedPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'login/password-change',
        component: ChangePasswordPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'forgot-pincode',
        component: ForgotPincodePageComponent,
        pathMatch: 'full',
        canActivate: [userIsLoggedInGuard],
      },
      {
        path: 'pincode-change',
        component: ChangePincodePageComponent,
        pathMatch: 'full',
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'page-not-found',
        component: ErrorPageComponent,
      },
    ],
  },
  {
    path: '',
    title: '192TV',
    component: LayoutCrwComponent,
    children: [
      {
        path: 'crw',
        component: CrwPageComponent,
        children: [
          {
            path: 'account',
            component: CrwAccountComponent,
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: CrwOverviewComponent,
            pathMatch: 'full',
          },
          {
            path: 'payment',
            component: CrwPaymentComponent,
            pathMatch: 'full',
          },
          {
            path: 'selection',
            component: CrwSelectionComponent,
          },
          {
            path: 'subscription',
            component: CrwSubscriptionComponent,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'search',
    title: '192TV',
    component: SearchPageComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    title: '192TV',
    redirectTo: 'page-not-found',
  },
];
