<div class="card">
  <form
    (click)="formClicked($event)"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit($event)">
    <header>
      <div class="grid-xp grid-cols-2 spread align-center">
        <h3 i18n>Nieuwe Toegangscode Instellen</h3>
        <p>
          <a
            i18n
            routerLink="/login"
            >Terug naar profielen</a
          >
        </p>
      </div>
    </header>
    <div *ngIf="!changeSuccess()">
      <fieldset formGroupName="changePincode">
        <shared-form-pincode
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_NEW_PINCODE"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'changePincode.accessCode'" />
        <shared-form-pincode-confirm
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_NEW_PINCODE_CONFIRM"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'changePincode.accessCodeConfirm'" />
      </fieldset>
      <footer>
        <div class="button-bar align-left">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_RESET_PINCODE"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </div>
    <div *ngIf="changeSuccess()">
      <p>{{ translations.FORM_PINCODE_RESET_SUCCESS }}</p>
      <div class="button-bar">
        <a
          i18n
          routerLink="/profiles"
          class="button is-colored"
          >Terug naar profielen</a
        >
      </div>
    </div>
  </form>
</div>
