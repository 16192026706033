import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';
import { GetFormControlPipe } from '../../../pipes/get-form-control.pipe';

@Component({
  selector: 'shared-form-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetFormControlPipe, GetAsFormControlPipe],
  templateUrl: './form-checkbox.component.html',
  styleUrl: './form-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCheckboxComponent extends FormControlComponent implements OnInit {

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
