import { AuthenticationStoreService } from "../stores/authentication-store.service";
import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { concatMap, Observable, take, tap } from "rxjs";
import { inject } from "@angular/core";
import { ApplicationService } from "../services/application.service";
import { ErrorService } from "../services/error.service";
import { environment } from "../../environments/environment";
import { ApplicationStoreService } from "../stores/application-store.service";
import { isTimeToRefreshToken } from "./authentication-is-time-to-refresh";
import { refresh } from "./authentication-refresh";
import { Q90ResponseData } from "../interfaces/q90-response";
import { XperienceResponse } from "../models/xperience-response";
import { AuthenticateInterface } from "../models/authenticate.model";

export function AuthenticationInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<any>> {

  const applicationService = inject(ApplicationService);
  const applicationStore = inject(ApplicationStoreService);
  const errorService = inject(ErrorService);
  const authenticationStore = inject(AuthenticationStoreService);

  // Let's not check for refresh when we're already refreshing...
  const requestIsRefresh = (request.url === environment.apiEndpointRoot + environment.apiEndpointVersion + '/authentication/refreshtoken');
  if (requestIsRefresh) {
    return next(request);
  }

  if (!isTimeToRefreshToken()) {
      return next(request);
  }

  if (applicationStore.getRefreshingTokenStore().getValue()) {
    applicationService.debug("HttpInterceptor: (already) refreshing...");
    return next(request);
  }

  applicationStore.getRefreshingTokenStore().next(true);
  applicationService.debug("HttpInterceptor: calling refresh...");

  return refresh(authenticationStore.getToken(), authenticationStore.getRefreshToken())
    .pipe(
      take(1),
      tap(refreshing => {

        if (XperienceResponse.isSuccess(refreshing))
        {
          authenticationStore.setAuthenticatedUser(<AuthenticateInterface>refreshing.data);
        }
        else
        {
          errorService.setError(new XperienceResponse().deserialize(refreshing));
        }

        applicationStore.getRefreshingTokenStore().next(false);
      }),
      concatMap(refreshing => next(request))
    );
}

