import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timelineLeftOffset",
  standalone: true
})
/**
 * Returns left offset from viewport in rems of current time in timeline on EPG pages.
 */
export class TimelineLeftOffsetPipe implements PipeTransform {

  /**
   * 18.813 is 18.75rem (300px or $px-300) + EPG grid gap of 0.063rem (not 0.094rem) (1px (not 1.5px) or $px-01-5)
   *
   * By the way, the -2.5 refers to half of the orange containing div showing the live time. Backs
   * this div up half the width to center the vertical orange pointer progress line over the current time.
   */
  protected hourUnitInRems: number = 18.813;

  transform(liveDate: Date, ...args: unknown[]): string {
    return ((
      (this.hourUnitInRems * liveDate.getHours()) +
      (this.hourUnitInRems / 60 * liveDate.getMinutes()) +
      ((this.hourUnitInRems / 60) / 60 * liveDate.getSeconds()))) +
      // (this.hourUnitInRems * 18) +
      // (this.hourUnitInRems / 60 * 0) +
      // ((this.hourUnitInRems / 60) / 60 * 0))) +
      - 2.5 + "rem";
  }

}
