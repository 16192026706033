import { inject, Injectable, OnDestroy } from "@angular/core";
import { LiveTimeStoreService } from "../stores/live-time-store.service";
import { PrimeableService } from "./primeable.service";
import { interval, Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LiveTimeService extends PrimeableService implements OnDestroy {
  private liveTimeStore = inject(LiveTimeStoreService);
  private readonly liveSubscription!: Subscription;

  constructor() {
    super();
    const live$ = interval(1000);
    this.liveSubscription = live$.subscribe((increment) => {
      const now = new Date();
      const secondsToday = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
      this.liveTimeStore.setLiveTimeSeconds(secondsToday);
      this.liveTimeStore.setLiveDate(now);
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.liveSubscription) {
      this.liveSubscription.unsubscribe();
    }
  }

  protected prime() {
  }

  protected authenticated(): void {
  }

  protected refresh(): void {
  }

  protected logout(): void {
  }
}
