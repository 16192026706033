import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { CrwStoreService } from '../../stores/crw-store.service';
import { AsyncPipe, CommonModule, NgFor, NgIf, NgStyle } from '@angular/common';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { GeneralStoreService } from '../../stores/general-store.service';
import { OptionCollection, toOptionCollection } from '../../shared/interfaces/option-collection';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { Router, RouterLink } from '@angular/router';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { FormCheckboxGroupComponent } from '../../components/forms/components/control/form-checkbox-group/form-checkbox-group.component';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { FormRadioComponent } from '../../components/forms/components/control/form-radio/form-radio.component';
import { SpinnerComponent } from "../../components/spinner/spinner.component";
import { PaymentMethodInterface } from '../../models/payment-method.model';

interface PaymentMethodsData {
  paymentMethods: PaymentMethodInterface[];
  // paymentMethodsColl: OptionCollection[];
  crwFlowSection: CrwFlowSection;
  crwPaymentMethod: PaymentMethodInterface;
}

@Component({
  selector: 'app-card-payment-methods',
  templateUrl: './card-payment-methods.component.html',
  styleUrls: ['./card-payment-methods.component.scss'],
  standalone: true,
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormRadioComponent, CommonModule, NgIf, NgFor, NgStyle, ErrorMessagesComponent, FormCheckboxGroupComponent, FormsModule, GetFormControlPipe, ReactiveFormsModule, RouterLink, SubmitComponent, AsyncPipe, SpinnerComponent]
})
export class CardPaymentMethodsComponent extends FormComponent implements OnInit {
  private crwStore = inject(CrwStoreService);
  private generalStore = inject(GeneralStoreService);
  private router = inject(Router);

  paymentMethodsData$: Observable<PaymentMethodsData>;

  paymentMethods: WritableSignal<PaymentMethodInterface[]> = signal([]);
  chosenPaymentMethod: WritableSignal<PaymentMethodInterface | null> = signal(null);

  override formGroup = this.formBuilder.group({
    paymentMethods: this.formBuilder.group({
      paymentCode: this.formBuilder.control<number>(0, [Validators.required])
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.paymentMethodsData$ = combineLatest(
      [
        this.generalStore.paymentMethods$,
        this.crwStore.crwSection$,
        this.crwStore.crwPaymentMethod$,
        this.crwStore.crwSubCart$
      ]
    ).pipe(
      map(([paymentMethods, crwFlowSection, crwPaymentMethod, subscriptions]) => {
        this.paymentMethods.set(paymentMethods);
        // const paymentMethodsColl = toOptionCollection(this.paymentMethods(), 'name', 'id');
        const subscription = subscriptions[0];
        if (!subscription) {
          this.router.navigate(['/crw/subscription']);
        }
        return {
          paymentMethods,
          // paymentMethodsColl,
          crwFlowSection,
          crwPaymentMethod,
        };
      }),
    );
  }

  // get paymentMethods() {
  //   return this.formGroup.get('paymentMethods.method') as FormArray;
  // }

  // setPaymentMethod(paymentCode: string) {
  //   this.crwStore.setCrwPaymentMethod(paymentCode);
  // }

  override onSubmit(e: Event): void {
    super.onSubmit(e);
    const paymentControls = this.formGroup.get("paymentMethods") as FormGroup;
    const boo = this.paymentMethods().find(s => s.paymentCode === paymentControls.value.paymentCode);
    // console.log(this.paymentMethods(), paymentControls.value, boo);
    if (this.submittable(paymentControls) && boo) {
      this.chosenPaymentMethod.set(boo);
      this.crwStore.setCrwPaymentMethod(boo);
      this.formGroup.markAsPristine();
      this.router.navigate(['/crw/overview']);
    }
  }
}
