<ng-container *ngIf="cardAccountData$ | async as cardAccountData">
  <div class="card">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <header>
        <h3 i18n>Account Verwijderen</h3>
        <p
          i18n
          class="subtitle"
          *ngIf="!readyToConfirmAndDelete()">
          Weet u zeker dat u uw account wil verwijderen? Uw account kan niet meer hersteld worden als deze verwijderd is.
        </p>
        <p
          i18n
          class="subtitle"
          *ngIf="readyToConfirmAndDelete()">
          Vul uw e-mailadres in en bevestig dat u uw account wil verwijderen. Als u op <em>Ja, Verwijderen</em> klikt is het definitief. Uw account kan niet meer hersteld worden. 
        </p>
      </header>
      <fieldset
        formGroupName="deleteAccount"
        *ngIf="readyToConfirmAndDelete()">
        <shared-form-email
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_ACCOUNT_EMAIL"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'deleteAccount.confirm'" />
      </fieldset>
      <footer>
        <div class="button-bar align-left">
          <!-- <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_YES_DELETE"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" /> -->
          <button
            i18n
            *ngIf="!readyToConfirmAndDelete()"
            type="button"
            class="is-colored"
            (click)="readyToConfirmAndDelete.set(true)">
            Ja, verwijderen
          </button>
          <shared-form-submit
            *ngIf="readyToConfirmAndDelete()"
            [buttonLabel]="LABEL_BUTTON_DELETE_ACCOUNT"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
          <button
            i18n
            *ngIf="!submitting()"
            type="button"
            class="is-transparent"
            (click)="cancelDeleteMode.emit(false)">
            Nee, annuleren
          </button>
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </div>
</ng-container>
