<div
  id="xperience-player"
  class="mcp"
  [ngClass]="{
    'is-loading': isLoading,
    'is-live': isLive(),
    'is-playing': !isPaused,
    'is-paused': isPaused
  }"
  (mousemove)="uiVisible = true; setMouseMoveTimer()"
  (mouseleave)="uiVisible = false">
  <div
    id="player"
    [ngClass]="{ 'ui-visible': uiVisible, 'is-promo': isPromo }"
    data-player-id="07490e7a-b377-4ba3-8fd5-5c87cc2a00e3"></div>

  <div class="mcp-loader"></div>
  <div
    class="mcp-ui"
    [ngClass]="{ 'ui-visible': uiVisible }"
    *ngIf="hls">
    <div class="mcp-header">
      <div
        class="mcp-return"
        routerLink="/tv">
        <div class="mci mci-arrowhead-left"></div>
        <h5 i18n>Terug</h5>
      </div>
      <div class="mcp-header-controls">
        <div
          class="mcp-header-control mcp-quality"
          *ngIf="hls.levels && hls.levels.length > 1">
          <div
            class="mcp-header-control-icon mci mci-setting-solid"
            (click)="menuQualityActive = !menuQualityActive"></div>
          <div
            class="mcp-menu mcp-menu-quality"
            [ngClass]="{ 'is-active': menuQualityActive }">
            <h5 class="mcp-menu-label">Quality</h5>
            <div class="mcp-menu-options">
              <p
                i18n
                class="mcp-menu-option"
                (click)="setQuality(-1)"
                [ngClass]="{ 'is-selected': hls.autoLevelEnabled }">
                Auto
              </p>
              <p
                class="mcp-menu-option"
                (click)="setQuality(i)"
                *ngFor="let level of hls.levels; let i = index"
                [ngClass]="{
                  'is-selected': hls.currentLevel == i && !hls.autoLevelEnabled
                }">
                {{ level.height }}p
              </p>
            </div>
          </div>
          <div class="mcp-header-control-label">Quality</div>
        </div>
        <!-- <div
          class="mcp-header-control mcp-subtitles"
          *ngIf="hls.subtitleTracks.length > 0"
        >
          <div class="mcp-header-control-icon mci mci-subtitles-solid"></div>
          <div class="mcp-header-control-label">Subtitles</div>
        </div> -->
      </div>
    </div>
    <div
      *ngIf="!isPromo"
      class="mcp-footer channel-active">
      <div class="mcp-channel-wrapper">
        <app-channel-epg [playerIsReplaying]="playerIsReplaying()"></app-channel-epg>
      </div>
    </div>
  </div>
  <div
    *ngIf="loadError"
    class="error">
    <h5 i18n>Er is iets fout gegaan</h5>
    <p *ngIf="errorMsg">{{ errorMsg }}</p>
    <p>
      <button
        i18n
        (click)="goBack()"
        class="is-colored">
        Terug
      </button>
    </p>
  </div>
</div>
