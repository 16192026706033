import { ChangeDetectionStrategy, Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { combineLatest, map, Observable } from "rxjs";
import { Q90ErrorResponseTypes } from "../../interfaces/q90-response";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { Q90Response, Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";


interface FormErrorMessagesData {
  message: Q90ErrorResponseTypes | null;
}

@Component({
  selector: "shared-error-messages",
  standalone: true,
  imports: [CommonModule],
  providers: [{ provide: ErrorMessagesStoreService }],

  templateUrl: "./error-messages.component.html",
  styleUrls: ["./error-messages.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessagesComponent implements OnInit {

  data$!: Observable<FormErrorMessagesData>;

  @Input() errorMessagesStore!: ErrorMessagesStoreService;
  @Input() showClose: boolean = true;
  @Input() styleClass!: string;

  level: WritableSignal<string> = signal(Q90ResponseLevels.Info);

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.errorMessagesStore.formMessages$
    ]).pipe(
      map(([message]) => {
        if (message instanceof XperienceResponse || message instanceof Q90Response) {
          this.level.set(message.level);
        }
        return {
          message
        };
      })
    );
  }

  closeMessage(e: Event) {
    this.errorMessagesStore.setErrorMessage(null);
  }
}
