<ng-container *ngIf="cardSubscriptionsData$ | async as cardSubscriptionsData">
  <div class="card">
    <header>
      <h3 i18n>Eerdere abonnementen</h3>
    </header>
    @if (cardSubscriptionsData.invalidSubscriptions.length > 0) {
      <div *ngFor="let sub of cardSubscriptionsData.invalidSubscriptions">
        <h5>
          {{ sub.title }}
        </h5>
        <div class="detail">
          <p i18n>Begin datum: {{ sub.startDate | beautifyDate }} | Eind datum: {{ sub.endDate | beautifyDate }}</p>
        </div>
      </div>
    } @else {
      <p i18n>U heeft geen eerdere abonnementen.</p>
    }
  </div>
</ng-container>
