import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { combineLatest, map, Observable } from 'rxjs';
import { GeneralStoreService } from '../../stores/general-store.service';
import { ProfilesStoreService } from '../../stores/profiles-store.service';
import { ProfileInterface } from '../../models/profile.model';
import { LogoPipe } from '../../pipes/logo.pipe';
import { CardProfileComponent } from '../../cards/card-profile/card-profile.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { LogoComponent } from '../../components/logo/logo.component';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../../services/error.service';
import { CardProfileAddComponent } from '../../cards/card-profile-add/card-profile-add.component';

interface ProfilesData {
  userProfiles: ProfileInterface[];
  userActiveProfile: ProfileInterface | null;
  darkMode: boolean;
}

@Component({
  selector: 'app-profiles-page',
  templateUrl: './profiles-page.component.html',
  styleUrls: ['./profiles-page.component.scss'],
  standalone: true,
  imports: [LogoComponent, NgIf, NgClass, NgFor, CardProfileComponent, RouterLink, AsyncPipe, LogoPipe, CardProfileAddComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilesPageComponent implements OnInit {
  maxNumberOfProfiles: number = environment.maxNumberOfProfiles;

  private generalStore = inject(GeneralStoreService);
  private profilesStore = inject(ProfilesStoreService);
  private errorService = inject(ErrorService);
  private userAccountStore = inject(UserAccountStoreService);
  private router = inject(Router);

  editMode: WritableSignal<ProfileInterface | null> = signal(null);
  accessMode: WritableSignal<ProfileInterface | null> = signal(null);

  profilesData$: Observable<ProfilesData>;

  constructor() {}

  ngOnInit(): void {
    if (localStorage.getItem('resetActive')) {
      localStorage.removeItem('resetActive');
      this.router.navigate(['/pincode-change']);
    }

    this.profilesData$ = combineLatest([this.profilesStore.userProfiles$, this.profilesStore.userActiveProfile$, this.generalStore.darkMode$]).pipe(
      map(([userProfiles, userActiveProfile, darkMode]) => {
        return {
          userProfiles,
          userActiveProfile,
          darkMode,
        };
      }),
    );
  }

  changeMode(profile: ProfileInterface, activeProfile: ProfileInterface | null): void {
    if (activeProfile?.profileId === profile.profileId) {
      this.editMode.set(profile);
    } else {
      this.accessMode.set(profile);
    }
  }

  resetMode(e: boolean): void {
    this.editMode.set(null);
    this.accessMode.set(null);
  }

  setProfileLogin(profile: ProfileInterface | null) {
    this.accessMode.set(profile);
  }

  changeAccessMode(e: ProfileInterface | null): void {
    this.accessMode.set(null);
  }

  toAddProfile(e: boolean): void {
    this.router
      .navigate(['profiles/create'])
      .then((result) => {
        // router navigate returns a promise, success with navigation...
      })
      .catch((error) => {
        this.errorService.setError(new Error('Unable to add profile'));
      });
  }

  toProfiles(e: Event): void {
    this.editMode.set(null);
    this.accessMode.set(null);
  }

  toAccount(e: Event): void {
    this.router
      .navigate(['account/info'])
      .then((result) => {
        // router navigate returns a promise, success with navigation...
      })
      .catch((error) => {
        this.errorService.setError(new Error('Unable to add profile'));
      });
  }
}
