import { Component } from '@angular/core';

@Component({
    selector: 'app-feedback-page',
    templateUrl: './feedback-page.component.html',
    styleUrls: ['./feedback-page.component.scss'],
    standalone: true
})
export class FeedbackPageComponent {

}
