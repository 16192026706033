<ng-container *ngIf="helpPageData$ | async as helpPageData">
  <nav>
    <ul class="side-menu justify-center flex gap-x-std-half md:block">
      <li class="md:mb-vertical-base-half">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/info/about">
          Over 192TV
        </a>
      </li>
      <li class="md:mb-vertical-base-half">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/info/cookie-policy">
          Cookie Beleid
        </a>
      </li>
      <li class="md:mb-vertical-base-half">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/info/privacy-policy">
          Privacy Beleid
        </a>
      </li>
      <li class="md:mb-vertical-base-half">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/info/terms-and-conditions">
          Algemene voorwaarden
        </a>
      </li>
      <li class="md:mb-vertical-base-half">
        <a
          i18n
          [routerLinkActive]="'is-active'"
          routerLink="/info/colophon">
          Colofon
        </a>
      </li>
    </ul>
  </nav>
</ng-container>
