import { Component, EventEmitter, Output } from "@angular/core";
import { AvatarComponent } from "../../components/avatar/avatar.component";

@Component({
  selector: "app-card-profile-add",
  standalone: true,
  imports: [
    AvatarComponent
  ],
  templateUrl: "./card-profile-add.component.html",
  styleUrl: "./card-profile-add.component.scss"
})
export class CardProfileAddComponent {

  @Output() onProfileAdd = new EventEmitter<boolean>();
}
