import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-textarea',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetAsFormControlPipe
  ],
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTextareaComponent extends FormControlComponent {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.control.addValidators([
      Validators.maxLength(300)
    ]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('maxlength')) {
      return 'Text is limited to 300 characters.';
    }
    return super.getErrorMessage();
  }

}
