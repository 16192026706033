import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { LogoPipe } from "../../pipes/logo.pipe";
import { CardForgotPasswordComponent } from "../../cards/card-forgot-password/card-forgot-password.component";
import { NgStyle } from "@angular/common";
import { BackgroundImageStoreService } from "../../stores/background-image-store.service";

@Component({
  selector: "app-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"],
  standalone: true,
  imports: [NgStyle, CardForgotPasswordComponent, LogoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordPageComponent {

  private backgroundImageStore = inject(BackgroundImageStoreService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }
}
