<ng-container *ngIf="itemPageData$ | async as itemPageData">
  <div
    class="item"
    *ngIf="item">
    <div
      class="background"
      [ngStyle]="{ 'background-image': 'url(' + item.largeVisual + ')' }"></div>
    <div class="card grid gap-y-std gap-x-0 md:gap-std md:grid-cols-[2fr_1fr]">
      <div class="header order-2 md:order-1 col-span-1 md:col-span-2">
        <button
          i18n
          class="is-transparent"
          [routerLink]="itemPageData.validSubscriptions.length ? ['/tv/channel', channelId] : ['/tv']">
          <span class="mci-arrow-left is-pri"></span>
          Terug naar de gids
        </button>
      </div>
      <div class="text-left order-3 md:order-2">
        <div class="info">
          <h2>{{ item.title }}</h2>
          <p class="my-0">{{ item.startDate | beautifyDate }}, {{ item.startDate | date: "HH:mm" }} - {{ item.endDate | date: "HH:mm" }}</p>
          <p
            class="description"
            *ngIf="item.description != ''">
            {{ item.description }}
          </p>
          <div class="button-bar align-left">
            <button
              i18n
              class="is-colored"
              *ngIf="itemPageData.liveDate | dateBetweenDates: item.startDate : item.endDate"
              routerLink="/watch/{{ channelId }}/{{ channelStreamId }}/live">
              <span class="mci-tv-solid"></span>
              Kijk live
            </button>
            <button
              i18n
              class="is-transparent"
              *ngIf="item.hasRestart && (item.startDate | dateEpgitemIsLive: item.endDate)"
              routerLink="/watch/{{ channelId }}/{{ channelStreamId }}/restart{{ startDateParam }}">
              <span class="mci-rotate-left-solid is-pri"></span>
              Kijk vanaf begin
            </button>
            <button
              i18n
              class="is-transparent"
              *ngIf="item.hasReplay && (item.endDate | dateEpgitemIsPast)"
              routerLink="/watch/{{ channelId }}/{{ item.mediaReplayId }}/{{ startDateParam }}">
              <span class="mci-play-circle-solid is-pri"></span>
              Opnieuw afspelen
            </button>
            <button
              i18n
              class="is-transparent"
              *ngIf="item.hasPromo"
              [routerLink]="['/promo']">
              <span class="mci-video-solid is-pri"></span>
              Promo
            </button>
          </div>
        </div>

        <div
          *ngIf="itemPageData.validSubscriptions.length"
          class="broadcasts">
          <h4 i18n>Uitzendingen</h4>

          <div class="list">
            <div class="tabs">
              <h5
                i18n
                class="tab"
                [ngClass]="{ 'is-selected': broadcastsPrevious }"
                (click)="broadcastsPrevious = true">
                Vorige
              </h5>
              <h5
                i18n
                class="tab"
                [ngClass]="{ 'is-selected': !broadcastsPrevious }"
                (click)="broadcastsPrevious = false">
                Binnenkort
              </h5>
            </div>
            <div class="rows">
              <ng-container *ngIf="broadcastsPrevious">
                <div
                  class="row"
                  [ngClass]="{ 'is-loading': loadingRows() }"
                  *ngFor="let previous of previousItems()">
                  <h5 class="my-0">{{ previous.startTime | beautifyDate }}, {{ previous.startTime | date: "HH:mm" }} - {{ previous.endTime | date: "HH:mm" }}</h5>
                  <button
                    i18n
                    [ngClass]="{ 'is-transparent': true }"
                    [routerLink]="['/item', channelId, item.mainProductId, previous.startTime]">
                    <span class="mci-information-circle-solid"></span>
                    Meer info
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="!broadcastsPrevious">
                <div
                  class="row"
                  [ngClass]="{ 'is-loading': loadingRows() }"
                  *ngFor="let upcoming of upcomingItems()">
                  <h5 class="my-0">{{ upcoming.startTime | beautifyDate }}, {{ upcoming.startTime | date: "HH:mm" }} - {{ upcoming.endTime | date: "HH:mm" }}</h5>
                  <button
                    i18n
                    [ngClass]="{ 'is-transparent': true }"
                    [routerLink]="['/item', channelId, item.mainProductId, upcoming.startTime]">
                    <span class="mci-information-circle-solid"></span>
                    Meer info
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="right order-2 md:order-3">
        <div
          class="poster"
          [ngStyle]="{ 'background-image': 'url(' + item.largeFrontCover + ')' }"></div>
      </div>
    </div>
  </div>
</ng-container>
