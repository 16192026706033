import { computed, Injectable, signal, WritableSignal } from "@angular/core";
import { BehaviorSubject, combineLatest, EMPTY, filter, iif, map, Observable, of, switchMap, take } from "rxjs";
import {
  ApplicationSettingInterface,
  ApplicationSettingName
} from "../models/application-setting.model";
import { ContentTemplateInterface } from "../models/content-template.model";
import { FaqCategoryInterface } from "../models/faq-category.model";
import { FaqInterface } from "../models/faq.model";
import { LanguageInterface } from "../models/language.model";
import { PaymentMethodInterface } from "../models/payment-method.model";
import { SubscriptionDurationInterface, SubscriptionInterface } from "../models/subscription.model";
import { BrandInterface } from "../models/brand.model";

@Injectable({
  providedIn: "root"
})
export class GeneralStoreService {

  languages: WritableSignal<LanguageInterface[]> = signal([]);

  defaultLanguage = computed(() => {
    const result = this.languages().find((b) => b.isDefault === true);
    return result
  });

  private brandsStore = new BehaviorSubject<BrandInterface[]>([]);
  brands$: Observable<BrandInterface[]> = this.brandsStore.asObservable();

  private defaultBrandStore = new BehaviorSubject<BrandInterface | null>(null);
  defaultBrand$: Observable<BrandInterface | null> = this.defaultBrandStore.asObservable();

  private currentBrandStore = new BehaviorSubject<BrandInterface | null>(null);
  currentBrand$: Observable<BrandInterface | null> = this.currentBrandStore.asObservable().pipe(
    switchMap(
      (currentBrand) => iif(
        () => currentBrand === null,
        this.defaultBrand$,
        of(currentBrand)
      ))
  );

  private faqsStore = new BehaviorSubject<FaqInterface[]>([]);
  faqs$: Observable<FaqInterface[]> = this.faqsStore.asObservable();

  private faqCategoriesStore = new BehaviorSubject<FaqCategoryInterface[]>([]);
  faqCategories$: Observable<FaqCategoryInterface[]> = this.faqCategoriesStore.asObservable();

  private contentTemplatesStore = new BehaviorSubject<ContentTemplateInterface[]>([]);
  contentTemplates$: Observable<ContentTemplateInterface[]> = this.contentTemplatesStore.asObservable();

  private subscriptionsStore = new BehaviorSubject<SubscriptionInterface[]>([]);
  subscriptions$: Observable<SubscriptionInterface[]> = this.subscriptionsStore.asObservable();
  subscriptionDurations$: Observable<SubscriptionDurationInterface[]> = this.subscriptions$.pipe(
    map(subscriptions => subscriptions.map(sub => <SubscriptionDurationInterface>{ period: sub.period, periodUnit: sub.periodUnit }))
  );

  private paymentMethodsStore = new BehaviorSubject<PaymentMethodInterface[]>([]);
  paymentMethods$: Observable<PaymentMethodInterface[]> = this.paymentMethodsStore.asObservable();

  private applicationSettingsStore = new BehaviorSubject<ApplicationSettingInterface[]>([]);
  applicationSettings$: Observable<ApplicationSettingInterface[]> = this.applicationSettingsStore.asObservable();

  private darkModeStore = new BehaviorSubject<boolean>(false);
  darkMode$: Observable<boolean> = combineLatest([
    this.applicationSettings$,
    this.getApplicationSetting(ApplicationSettingName.StyleDarkMode),
    this.getApplicationSetting(ApplicationSettingName.StyleDarkModeForced)
  ]).pipe(
    filter(([settings, darkMode, forcedDarkMode]) => settings.length > 0),
    map(([settings, darkMode, forcedDarkMode]) => {
      // Returning hard-coded dark-mode false here since CSS for alternatives is not yet ready...
      return false;
      // return (darkMode?.value.toLowerCase() == "true" || forcedDarkMode.value.toLowerCase() == "true");
    })
  );

  constructor() {
  }

  /**
   * Just using a signal here, no BehaviorSubject store...
   * 
   * @param languages 
   */
  setLanguages(languages: LanguageInterface[]): void {
    this.languages.set(languages);
  }

  getLanguageById(languageId: number): LanguageInterface {
    const result = this.languages().find((b) => +b.id === +languageId);
    if (result) {
      return result;
    } else {
      return this.defaultLanguage();
    }
  }

  getLanguageByCode(code: string): LanguageInterface {
    const result = this.languages().find((b) => b.ietfCode === code);
    if (result) {
      return result;
    } else {
      return this.defaultLanguage();
    }
  }

  getContentByCode(code: string): Observable<ContentTemplateInterface> {
    return this.contentTemplates$.pipe(
      filter(templates => templates.length > 0),
      switchMap(
        (templates) => iif(
          () => templates.find(t => t.code === code) !== undefined,
          of(templates.find(t => t.code === code)),
          of(null)
        ))
    );
  }

  setBrands(brands: BrandInterface[]): void {
    this.brandsStore.next(brands);
  }

  setDefaultBrand(brand: BrandInterface): void {
    this.defaultBrandStore.next(brand);
  }

  setApplicationSettings(settings: ApplicationSettingInterface[]): void {
    this.applicationSettingsStore.next(settings);
  }

  setFaqs(faqs: FaqInterface[]): void {
    this.faqsStore.next(faqs);
  }

  setFaqCategories(categories: FaqCategoryInterface[]): void {
    this.faqCategoriesStore.next(categories);
  }

  setContentTemplates(templates: ContentTemplateInterface[]): void {
    this.contentTemplatesStore.next(templates);
  }

  setSubscriptions(subscriptions: SubscriptionInterface[]): void {
    this.subscriptionsStore.next(subscriptions);
  }

  setPaymentMethods(methods: PaymentMethodInterface[]): void {
    this.paymentMethodsStore.next(methods);
  }

  setDarkMode(setting: boolean): void {
    this.darkModeStore.next(setting);
  }

  getApplicationSetting(settingName: ApplicationSettingName): Observable<ApplicationSettingInterface> {
    return this.applicationSettings$.pipe(
      filter(applicationSettings => applicationSettings.length > 0),
      map(applicationSettings => applicationSettings.find(setting => setting.name === settingName))
    );
  }
}
