import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { GeneralStoreService } from '../../../stores/general-store.service';

interface NavigationHeaderData {
  darkMode: boolean;
}

@Component({
  selector: 'shared-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {

  private generalStore = inject(GeneralStoreService);
  navigationHeaderData$!: Observable<NavigationHeaderData>;

  @Input() withCloseButton = true;
  @Input() centerVertically = false;
  @Output() closeModal = new EventEmitter<boolean>();

  closing: boolean = false;

  ngOnInit(): void {
    this.navigationHeaderData$ = combineLatest([
      this.generalStore.darkMode$
    ]).pipe(
      map(([darkMode]) => {
        return {
          darkMode,
        };
      })
    );
  }

  closeModalOnClick(event: Event) {
    this.closing = true;
    this.closeModal.emit(true);
  }

  closeModalOnEscape(event: Event) {
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Escape') {
        this.closing = true;
        this.closeModal.emit(true);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Your row selection code
      this.closing = true;
      this.closeModal.emit(true);
    }
  }
}
