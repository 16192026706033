import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'shared-form-control',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormControlComponent implements OnInit, OnDestroy {
  protected additionalValidators: ValidatorFn[] = [];

  @Input({ required: true }) formGroup!: FormGroup;
  @Input() control!: AbstractControl<any, any>;
  @Input({ required: true }) label!: string;
  @Input() id: string | null = null;
  @Input() controlName: string | null = null;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string | null = null;
  @Input() showPlaceholder: boolean = true;
  @Input() showErrorHints: boolean = true;
  @Input() styleClass: string | null = null;
  @Input() additionalStyleClasses: string | null = null;
  @Input() isDisabled: boolean = false;
  @Input() submitted: boolean = false;
  @Input() info = '';
  @Input() shouldValidate = true;

  fieldIsRequired = $localize`:@@fieldIsRequired:Dit veld is verplicht.`;
  fieldHasTooManyCharacters = $localize`:@@fieldHasTooManyCharacters:De waarde bevat te veel karakters.`
  fieldHasErrors = $localize`:@@fieldHasErrors:Dit veld voldoet niet.`;
  ngOnInit(): void {
    if (!this.id) {
      this.id = this.label;
    }
    if (this.showPlaceholder && !this.placeholder) {
      this.placeholder = this.label;
    }
    if (this.control instanceof FormControl && this.isDisabled) {
      this.control.disable();
    }
    this.additionalValidators.forEach((f) => {
      this.control.addValidators(this.additionalValidators);
    });
  }

  ngOnDestroy(): void {
    this.removeAdditionalValidators();
  }

  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return this.fieldIsRequired;
    }
    if (this.control.hasError('maxLength')) {
      return this.fieldHasTooManyCharacters;
    }
    return this.fieldHasErrors;
  }

  protected removeAdditionalValidators(): void {
    this.additionalValidators.forEach((f) => {
      this.control.removeValidators(f);
    });
  }
}
