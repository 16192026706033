import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, take } from "rxjs";
import { Q90ResponseData } from "../interfaces/q90-response";
import { AuthenticateInterface } from "../models/authenticate.model";
import { environment } from "../../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { XperienceResponse } from "../models/xperience-response";
import { PrimeableService } from "./primeable.service";
import { FrontendCookieStorageService } from "./storage/frontend-cookie-storage.service";
import { ApplicationEvent } from "./application.service";
import { refresh } from "../functions/authentication-refresh";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService extends PrimeableService {

  cookieStorageService = inject(FrontendCookieStorageService);

  private authenticatingStore = new BehaviorSubject<boolean>(false);
  authenticating$: Observable<boolean> = this.authenticatingStore.asObservable();

  private isRefreshing = false;

  constructor() {
    super();
    this.userAccountStore.isLoggedIn$
      .pipe(
        take(1)
      )
      .subscribe((userAccountLoggedIn) => {
        if (!userAccountLoggedIn) this.loginFromFrontendStorage();
      });
  }

  setAuthenticating(setting: boolean): void {
    this.authenticatingStore.next(setting);
  }

  userLogin(
    username: string,
    password: string
  ): Observable<Q90ResponseData<AuthenticateInterface>> {
    return this.http.post<Q90ResponseData<AuthenticateInterface>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/authentication/authenticate`,
      {
        username: username,
        password: password
      },
      {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  userLogout(): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/authentication/logout`,
      {},
      {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  tfaLogin(
    pinCode: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/authentication/twofactor/authenticate`,
      {
        pinCode: pinCode
      },
      {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  tfaLoginWithCode(
    pinCode: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/authentication/twofactor/authenticate/recovery`,
      {
        pinCode: pinCode
      },
      {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  protected prime(): void {
  }

  protected authenticated(): void {
  }

  protected refresh(): void {
  }

  protected logout() {
    this.authenticationStore.clearStore();
    this.authenticationStore.setAuthenticatedUser(null);
  }

  // Required for profile bug. Y? just because okay? okay. :)
  // dont touch.
  // if touch increase counter: 0
  private loginFromFrontendStorage() {
    this.applicationService.debug("Looking in frontend storage...");
    this.setQueryingPrimeableService(true);
    const token = this.cookieStorageService.getItem("token");
    const refreshToken = this.cookieStorageService.getItem("refreshToken");
    if (token && refreshToken && !this.isRefreshing) {
      this.isRefreshing = true;

      refresh(token, refreshToken)
        .pipe(take(1))
        .subscribe({
          next: (response: Q90ResponseData<AuthenticateInterface>) => {
            this.isRefreshing = false;
            this.applicationStore.setRefreshingToken(false);
            this.authenticationStore.setAuthenticatedUser(response.data);
            this.applicationStore.setApplicationEvent(ApplicationEvent.RefreshToken); // Also used for priming the services and pulling in profiles
          },
          error: (error) => {
            this.isRefreshing = false;
            this.errorService.setError(error);
          },
          complete: () => {
            this.setQueryingPrimeableService(false);
          }
        });
    }
  }
}
