import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { FormControlComponent } from '../form-control.component';
import { GetFormControlPipe } from '../../../pipes/get-form-control.pipe';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe,
  ],
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent extends FormControlComponent implements OnInit {

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.control.addValidators([
      Validators.pattern(/^[a-zA-Z0-9@ _.\-?,]+$/i),
      Validators.maxLength(100)
    ]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('maxlength')) {
      return 'Text is limited to 100 characters.';
    }
    if (this.control.hasError('pattern')) {
      return 'Only alpha-numeric and the following characters are allowed: [@ _.-?,]';
    }
    return super.getErrorMessage();
  }
}
