<ng-container *ngIf="cardYourAccountData$ | async as cardAccountData">
  <div class="card">
    <header>
      <h3 i18n>Uw Account</h3>
    </header>
    <section class="grid-xp grid-cols-2 mt-1">
      <div>
        <h5 i18n>Naam</h5>
        <p>
          {{ cardAccountData.userAccount.firstName }}
          {{ cardAccountData.userAccount.lastName }}
        </p>
      </div>

      <div>
        <h5 i18n>E-mail adres</h5>
        <p>{{ cardAccountData.userAccount.emailAddress }}</p>
      </div>
    </section>
  </div>
</ng-container>
