import { Component, inject, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Router, RouterOutlet } from "@angular/router";
import { GeneralStoreService } from "../../stores/general-store.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { ErrorService } from "../../services/error.service";
import { ProfileInterface } from "../../models/profile.model";
import { ProfilesStoreService } from "../../stores/profiles-store.service";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { LogoComponent } from "../../components/logo/logo.component";
import { CookieDisclaimerService } from "../../services/cookie-disclaimer.service";
import { CookieDisclaimerComponent } from "../../shared/components/cookie-disclaimer/cookie-disclaimer.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { LanguageHeaderService } from "../../services/language-header.service";

interface LayoutProfilesData {
  darkMode: boolean;
  isLoggedIn: boolean;
  userProfiles: ProfileInterface[];
}

@Component({
  selector: "app-layout-profiles",
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    LogoComponent,
    RouterOutlet,
    CookieDisclaimerComponent,
    LoaderComponent
  ],
  templateUrl: "./layout-profiles.component.html",
  styleUrl: "./layout-profiles.component.scss"
})
export class LayoutProfilesComponent implements OnInit {

  layoutProfilesData$!: Observable<LayoutProfilesData>;
  public router = inject(Router);

  public languageHeaderService = inject(LanguageHeaderService);
  private generalStore = inject(GeneralStoreService);
  protected profilesStore = inject(ProfilesStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);
  cookieDisclaimerService = inject(CookieDisclaimerService);

  constructor() {
  }

  ngOnInit(): void {
    this.layoutProfilesData$ = combineLatest([
      this.generalStore.darkMode$,
      this.userAccountStore.isLoggedIn$,
      this.profilesStore.userProfiles$,
    ]).pipe(
      map(
        ([
          darkMode,
          isLoggedIn,
          userProfiles,
        ]) => {
          return {
            darkMode,
            isLoggedIn,
            userProfiles,
          };
        }
      )
    );
  }
}
