import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { Q90ErrorResponseTypes } from "../interfaces/q90-response";
import { Q90ResponseLevels } from "../models/q90-response";

export type MessageInterface = {
  message: string;
  level: Q90ResponseLevels
}

@Injectable({
  providedIn: "root"
})
export class ErrorService {

  private router = inject(Router);

  private errorStore = new BehaviorSubject<Q90ErrorResponseTypes | null>(null);
  error$: Observable<Q90ErrorResponseTypes | null> = this.errorStore.asObservable();

  private xperienceMessageStore = new BehaviorSubject<MessageInterface | null>(null);
  xperienceMessage$: Observable<MessageInterface | null> = this.xperienceMessageStore.asObservable();

  setError(err: Q90ErrorResponseTypes) {
    this.errorStore.next(err);
    this.router.navigate(["error"]);
  }

  setXperienceMessage(err: MessageInterface | null) {
    this.xperienceMessageStore.next(err);
  }
}
