import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { LanguageHeaderService } from "../services/language-header.service";

export const languageHeaderInterceptor: HttpInterceptorFn = (request, next) => {
  const languageHeaderService = inject(LanguageHeaderService);
  if (languageHeaderService.uiLanguage()) {
    const clonedRequest = request.clone({
      headers: request.headers.set('x-language', languageHeaderService.getLanguageHeader()),
    });
    return next(clonedRequest);
  } else {
    return next(request);
  }
}
