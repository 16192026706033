import { Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { EpgService } from "../../services/epg.service";
import { DateBetweenDatesPipe } from "../../pipes/date-between-dates.pipe";
import { BeautifyDatePipe } from "../../pipes/beautify-date.pipe";
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { combineLatest, map, Observable, take, takeUntil } from "rxjs";
import { LiveTimeStoreService } from "../../stores/live-time-store.service";
import { DateEpgitemIsPastPipe } from "../../pipes/date-epgitem-is-past.pipe";
import { DateEpgitemIsFuturePipe } from "../../pipes/date-epgitem-is-future.pipe";
import { DateEpgitemIsLivePipe } from "../../pipes/date-epgitem-is-live.pipe";
import { GeneralStoreService } from "../../stores/general-store.service";
import { ApplicationSettingInterface, ApplicationSettingName } from "../../models/application-setting.model";
import { EpgItemProgramInterface } from "../../models/epg-item";
import { DestroyService } from "../../services/destroy.service";
import { XperienceResponse } from "../../models/xperience-response";
import { ErrorService } from "../../services/error.service";
import { Q90ResponseLevels } from "../../models/q90-response";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { SubscriptionInterface } from "../../models/subscription.model";

interface ItemPageData {
  liveTimeSeconds: number;
  liveDate: Date;
  epgDaysPast: ApplicationSettingInterface;
  epgDaysFuture: ApplicationSettingInterface;
  isLoggedIn: boolean;
  validSubscriptions: SubscriptionInterface[];
}

@Component({
  selector: "app-item-page",
  templateUrl: "./item-page.component.html",
  styleUrls: ["./item-page.component.scss"],
  standalone: true,
  imports: [
    NgIf, NgStyle, RouterLink, NgClass, NgFor, DatePipe, BeautifyDatePipe, DateBetweenDatesPipe, AsyncPipe,
    DateEpgitemIsPastPipe,
    DateEpgitemIsFuturePipe, DateEpgitemIsLivePipe, RouterLink
  ]
})
export class ItemPageComponent implements OnInit {

  private epgService = inject(EpgService);
  private generalStore = inject(GeneralStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private errorService = inject(ErrorService);
  private liveTimeStore = inject(LiveTimeStoreService);
  private route = inject(ActivatedRoute);
  private destroyed = inject(DestroyService);

  itemPageData$: Observable<ItemPageData>;

  loadingRows: WritableSignal<boolean> = signal(false);
  epgFutureDays: WritableSignal<number> = signal(0);
  epgPastDays: WritableSignal<number> = signal(0);
  previousItems: WritableSignal<EpgItemProgramInterface[]> = signal([]);
  upcomingItems: WritableSignal<EpgItemProgramInterface[]> = signal([]);

  constructor() {
    this.startDate = new Date();
    this.startDate.setMinutes(0);
    this.startDate.setSeconds(0);
    this.startDateParam = this.epgService.getAsIsoString(this.startDate, false)
  }

  channelId: number = 0;
  itemId: number = 0;
  public startDate: Date;
  public startDateParam: string;
  item = null;
  broadcastsPrevious = true;
  channelStreamId: number = 0;

  ngOnInit() {

    this.route.params.pipe(
      takeUntil(this.destroyed)
    ).subscribe({
      next: (params) => {
        this.channelId = params["channelId"];
        this.itemId = params["itemId"];

        if (params['startDate']) {

          const paramStartDate = new Date(params['startDate']);
          const dateInRange = this.epgService.checkDateInCurrentDateRange(paramStartDate);

          if (dateInRange !== -1) {
            this.startDate = paramStartDate;
            this.startDateParam = this.epgService.getAsIsoString(this.startDate, false)
          }
        }
        this.getItem();
        this.getEpg();
      },
    });

    this.itemPageData$ = combineLatest(
      [
        this.liveTimeStore.liveTimeSeconds$,
        this.liveTimeStore.liveDate$,
        this.generalStore.getApplicationSetting(ApplicationSettingName.EpgDaysPast),
        this.generalStore.getApplicationSetting(ApplicationSettingName.EpgDaysFuture),
        this.userAccountStore.isLoggedIn$,
        this.userAccountStore.validSubscriptions$
      ]
    ).pipe(
      map(([liveTimeSeconds, liveDate, epgDaysPast, epgDaysFuture, isLoggedIn, validSubscriptions]) => {
        this.epgFutureDays.set(+epgDaysFuture.value);
        this.epgPastDays.set(+epgDaysPast.value);
        return {
          liveTimeSeconds,
          liveDate, epgDaysPast, epgDaysFuture, isLoggedIn, validSubscriptions
        };
      })
    );
  }

  private getItem() {
    const dateRange = this.epgService.getEpgDateRange(true);
    const currentItemInDateRangeIndex = this.epgService.checkDateInCurrentDateRange(this.startDate);
    // console.log(dateRange, this.startDate, currentItemInDateRangeIndex);
    const futureCount = dateRange.slice((currentItemInDateRangeIndex + 1)).length;
    const pastCount = dateRange.slice(0, currentItemInDateRangeIndex).length;

    this.loadingRows.set(true);
    this.epgService
      .getEpgItem(this.itemId, this.startDateParam).pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.loadingRows.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.item = res.data;
            const previous = res.data.previousPrograms?.reverse().slice(0, pastCount);
            this.previousItems.set(previous);
            const upcoming = res.data.nextPrograms?.slice(0, futureCount);
            this.upcomingItems.set(upcoming);
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorService.setError(error);
          }
        },
        error: (error) => {
          this.errorService.setError(error);
        }
      });
  }

  getEpg() {
    this.epgService.getChannelEpg(this.channelId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (XperienceResponse.isSuccess(res)) {
          this.channelStreamId = res.data.channelId;
        } else {
          const error = new XperienceResponse().deserialize(res);
          error.setLevel(Q90ResponseLevels.Danger);
          this.errorService.setError(error);
        }
      },
      error: (error) => {
        this.errorService.setError(error);
      }
    });
  }
}
