import { Component, inject } from "@angular/core";
import { LogoPipe } from "../../pipes/logo.pipe";
import { CardResetPasswordComponent } from "../../cards/card-reset-password/card-reset-password.component";
import { NgStyle } from "@angular/common";
import { BackgroundImageStoreService } from "../../stores/background-image-store.service";

@Component({
  selector: "app-change-password-page",
  templateUrl: "./change-password-page.component.html",
  styleUrls: ["./change-password-page.component.scss"],
  standalone: true,
  imports: [NgStyle, CardResetPasswordComponent, LogoPipe]
})

export class ChangePasswordPageComponent {

  private backgroundImageStore = inject(BackgroundImageStoreService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }


}
