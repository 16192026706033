import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-input-hidden',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetAsFormControlPipe
  ],
  templateUrl: './form-input-hidden.component.html',
  styleUrls: ['./form-input-hidden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputHiddenComponent extends FormControlComponent implements OnInit {

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

}
