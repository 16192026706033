import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControlComponent } from "../form-control.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GetFormControlPipe } from "../../../pipes/get-form-control.pipe";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";

@Component({
  selector: "shared-form-radio-group",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe
  ],
  templateUrl: "./form-radio-group.component.html",
  styleUrls: ["./form-radio-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRadioGroupComponent extends FormControlComponent {

  @Input() override controlName!: string;
  @Input() options: Record<string | number, any> | null = null;
  @Input() hasDefault = false;
  @Input() chosenOption!: string | boolean;
  @Output() radioControlChange = new EventEmitter<number>();

  constructor() {
    super();
  }

  radioChange($event: Event) {
    this.radioControlChange.emit(this.control.value);
  }

}
