import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Q90ErrorResponseTypes } from "../interfaces/q90-response";

@Injectable()
export class ErrorMessagesStoreService {

  protected formMessagesStore = new BehaviorSubject<Q90ErrorResponseTypes | null>(null);
  formMessages$: Observable<Q90ErrorResponseTypes | null> = this.formMessagesStore.asObservable();

  setErrorMessage(errorMessage: Q90ErrorResponseTypes): void {
    this.formMessagesStore.next(errorMessage);
  }
}
