import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BeautifyDatePipe } from "../../pipes/beautify-date.pipe";
import { EpgService } from "../../services/epg.service";
import { DateAsIsoStringPipe } from "../../pipes/date-to-isostring.pipe";
import { SpinnerComponent } from "../spinner/spinner.component";

@Component({
  selector: "app-epg-dates",
  standalone: true,
  imports: [
    CommonModule,
    BeautifyDatePipe,
    DateAsIsoStringPipe,
    SpinnerComponent
  ],
  providers: [
    EpgService
  ],
  templateUrl: "./epg-dates.component.html",
  styleUrl: "./epg-dates.component.scss"
})
export class EpgDatesComponent {

  @Input({ required: true }) currentDate: Date;
  @Input({ required: true }) loaded: boolean;
  @Output() setPreviousDay = new EventEmitter<Date>();
  @Output() setNextDay = new EventEmitter<Date>();

  private epgService = inject(EpgService);
  public dates: Date[] = [];

  constructor() {
    this.dates = this.epgService.getEpgDateRange();
  }

  previousDay(): void {
    const currentDateIndex = this.dates.findIndex(
      date => this.epgService.getAsIsoString(date) === this.epgService.getAsIsoString(this.currentDate));
    if (currentDateIndex !== -1 && this.dates[currentDateIndex - 1]) {
      const previousDate = this.dates[currentDateIndex - 1];
      this.setPreviousDay.emit(previousDate);
    }
  }

  nextDay(): void {
    const currentDateIndex = this.dates.findIndex(
      date => this.epgService.getAsIsoString(date) === this.epgService.getAsIsoString(this.currentDate));
    if (currentDateIndex !== -1 && this.dates[currentDateIndex + 1]) {
      const nextDate = this.dates[currentDateIndex + 1];
      this.setNextDay.emit(nextDate);
    }
  }
}
