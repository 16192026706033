import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { UserAccountService } from '../../services/user-account.service';
import { NgClass, NgIf } from '@angular/common';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { take } from 'rxjs';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { FormPairingCodeComponent } from '../../components/forms/components/control/form-pairing-code/form-pairing-code.component';

@Component({
  selector: 'app-card-pairing',
  templateUrl: './card-pairing.component.html',
  styleUrls: ['./card-pairing.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ErrorMessagesComponent, FormPairingCodeComponent, GetFormControlPipe, ReactiveFormsModule, RouterLink, SubmitComponent],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPairingComponent extends FormComponent implements OnInit {
  @Input() withBackToLink: boolean = false;

  private userAccountService = inject(UserAccountService);
  activatedRoute = inject(ActivatedRoute);
  pairingSuccess: boolean = false;

  override formGroup = this.formBuilder.group({
    pairing: this.formBuilder.group({
      token: this.formBuilder.control<string>(''),
    }),
  });

  constructor() {
    super();

    this.activatedRoute.queryParams.subscribe((params) => {
      let code = params['code'];
      if (code) {
        const pairingControls = this.formGroup.get('pairing') as FormGroup;
        pairingControls.controls['token'].patchValue(code);
        this.submitPairingCode(code);
      }
    });
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const pairingControls = this.formGroup.get('pairing') as FormGroup;
    if (this.submittable(pairingControls)) {
      this.submitPairingCode(pairingControls.value.token);
    }
  }

  submitPairingCode(code: string) {
    this.submitting.set(true);
    this.userAccountService
      .pairDevice(code)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            this.setFormMessage(this.translations.getTranslation(this.translations.FORM_PAIRING_DEVICE_SUCCESS), Q90ResponseLevels.Success);
            this.pairingSuccess = true;
            this.formGroup.reset();
            this.formGroup.markAsPristine();
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        },
      });
  }
}
