import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { CardSubscriptionComponent } from "../../cards/card-subscription/card-subscription.component";
import { SideMenuComponent } from "../../components/side-menu/side-menu.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { CardAccountComponent } from "../../cards/card-account/card-account.component";
import { CardEmailComponent } from "../../cards/card-email/card-email.component";
import { CardPairingComponent } from "../../cards/card-pairing/card-pairing.component";
import { CardPasswordComponent } from "../../cards/card-password/card-password.component";
import { AccountInterface } from "../../models/account.model";
import { combineLatest, map, Observable } from "rxjs";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import {
  CardPaymentInformationComponent
} from "../../cards/card-payment-information/card-payment-information.component";
import { SubscriptionInterface } from "../../models/subscription.model";
import { CardSubscriptionPastComponent } from "../../cards/card-subscription-past/card-subscription-past.component";

interface SubscriptionsData {
  isLoggedIn: boolean;
  userAccount: AccountInterface;
  userAccountSubscriptions: SubscriptionInterface[];
}

@Component({
  selector: "app-subscription-page",
  templateUrl: "./subscription-page.component.html",
  styleUrls: ["./subscription-page.component.scss"],
  standalone: true,
  imports: [
    CardSubscriptionPastComponent, SideMenuComponent, CardSubscriptionComponent, AsyncPipe, CardAccountComponent, CardEmailComponent,
    CardPairingComponent, CardPasswordComponent, NgIf, CardPaymentInformationComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPageComponent implements OnInit {

  private userAccountStore = inject(UserAccountStoreService);

  subscriptionsData: Observable<SubscriptionsData>;

  constructor() {
  }

  ngOnInit(): void {
    this.subscriptionsData = combineLatest([
      this.userAccountStore.isLoggedIn$,
      this.userAccountStore.userAccount$,
      this.userAccountStore.userAccountSubscriptions$
    ]).pipe(
      map(
        ([isLoggedIn, userAccount, userAccountSubscriptions]) => {
          return {
            isLoggedIn, userAccount, userAccountSubscriptions
          };
        }
      )
    );
  }

}
