<ng-container *ngIf="layoutProfilesData$ | async as layoutProfilesData; else loading">
  <main *ngIf="layoutProfilesData.userProfiles">
    <app-logo [size]="'profiles'" />
    <router-outlet />
  </main>
  <shared-cookie-disclaimer *ngIf="!cookieDisclaimerService.cookieDisclaimerAgreed()" />
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
