<div
  [ngClass]="{
    'is-showing-controls': editMode(),
    'is-deleting': deleteMode(),
    'is-accessing': accessMode(),
    'is-active': activeProfile(),
    pulse: !editMode() && !deleteMode() && !accessMode()
  }"
  class="card">
  <core-avatar
    [avatarSrc]="profile.avatar"
    [size]="'lg'" />
  <h3>{{ profile.profileName }}</h3>

  <div
    *ngIf="editMode() && !deleteMode()"
    class="button-bar align-center m-block-0">
    <button
      class="is-colored is-icon"
      (click)="toEditProfile($event)">
      <span class="mci-pencil-solid"></span>
    </button>
    <button
      (click)="deleteMode.set(true)"
      class="is-icon">
      <span class="mci-trash-solid"></span>
    </button>
  </div>

  <ng-container *ngIf="deleteMode() && editMode()">
    <p i18n>Weet u zeker dat u dit profiel wilt verwijderen?</p>

    <div class="button-bar align-center m-block-0">
      <button
        i18n
        (click)="deleteProfile()"
        class="is-colored">
        Ja, verwijderen
      </button>
      <button
        i18n
        (click)="deleteMode.set(false)"
        class="is-transparent">
        Nee, annuleren
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="accessMode()">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <shared-form-pincode
        *ngIf="this.profile.hasAccessCode"
        [styleClass]="'xperience m-block-0'"
        [submitted]="submitted"
        [label]="LABEL_ACCESS_CODE"
        [showLabel]="false"
        [formGroup]="formGroup"
        [control]="formGroup | getFormControl: 'profileLogin.accessCode'">
      </shared-form-pincode>
      <footer>
        <div class="button-bar align-center">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_LOGIN"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine && this.profile.hasAccessCode"
            [submitting]="submitting()" />
          <button
            i18n
            type="button"
            (click)="resetAccessMode(null)"
            class="is-transparent">
            Annuleren
          </button>

          <button
            type="button"
            (click)="forgotPincode(profile)"
            class="is-transparent"
            i18n>
            Toegangscode vergeten?
          </button>
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </ng-container>
</div>
