import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ApplicationEvent } from '../services/application.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStoreService {
  // Store for (last) Application Event
  private applicationEventStore = new BehaviorSubject<string>(ApplicationEvent.Idle);
  applicationEvent$: Observable<string> = this.applicationEventStore.asObservable();

  // Is the application refreshing the user token?
  private refreshingTokenStore = new BehaviorSubject<boolean>(false);
  refreshingToken$: Observable<boolean> = this.refreshingTokenStore.asObservable();

  // Is a primeable service somewhere priming?
  private primeablePrimingStore = new BehaviorSubject<boolean>(false);
  primeablePriming$: Observable<boolean> = this.primeablePrimingStore.asObservable();

  // Buffers the last 2 urls nexted to this store and replays them on subscribe
  private previousUrlStore = new ReplaySubject<string>(2);
  previousUrl$: Observable<string | null> = this.previousUrlStore.asObservable();

  private loadedChromeCastStore = new BehaviorSubject<boolean>(false);
  loadedChromeCast$: Observable<boolean> = this.loadedChromeCastStore.asObservable();

  constructor() {
    // Pre-populating previous url store with two values of the homepage.
    // See explanation in ApplicationService:returnToPreviousPage
    this.previousUrlStore.next('/');
    this.previousUrlStore.next('/');
  }

  setApplicationEvent(action: ApplicationEvent) {
    this.applicationEventStore.next(action);
  }

  setRefreshingToken(setting: boolean): void {
    this.refreshingTokenStore.next(setting);
  }

  getRefreshingTokenStore(): BehaviorSubject<boolean> {
    return this.refreshingTokenStore;
  }

  setPrimeablePriming(setting: boolean): void {
    this.primeablePrimingStore.next(setting);
  }

  setLastUrl(url: string): void {
    this.previousUrlStore.next(url);
  }

  setLoadedChromeCast(val: boolean): void {
    this.loadedChromeCastStore.next(val);
  }

  getLoadedChromeCast(): BehaviorSubject<boolean> {
    return this.loadedChromeCastStore;
  }
}
