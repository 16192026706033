import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationStoreService } from '../stores/authentication-store.service';
import { UserAccountStoreService } from '../stores/user-account-store.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private userAccountStore = inject(UserAccountStoreService);
  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);

  constructor() {}

  getMediaUrl(mediaId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/media/${mediaId}/src`, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
    });
  }
  getPromoUrl(mediaId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/media/${mediaId}/promo`, {});
  }
  getMediaUrlWithStartTime(mediaId: number, starttime: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/media/${mediaId}/src?startTime=${starttime}&streamType=1`, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
    });
  }

  getRestartUrl(mediaId: number, streamToken: string, offset: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/media/${mediaId}/heartbeat/restart`,
      {
        streamToken: streamToken,
        offset: offset,
      },
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      },
    );
  }
  // getRestartUrl(mediaId: number, starttime): Observable<any> {
  //   return this.http.put<any>(
  //     `${environment.apiEndpointRoot + environment.apiEndpointVersion}/media/${mediaId}/live/restart/${starttime}`,
  //     {},
  //     {
  //       headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
  //     },
  //   );
  // }
}
