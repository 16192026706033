import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { Router, RouterLink } from "@angular/router";
import { take } from "rxjs";
import { ApplicationEvent } from "../../services/application.service";
import { BackgroundImageStoreService } from "../../stores/background-image-store.service";

@Component({
  selector: "app-logout-page",
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: "./logout-page.component.html",
  styleUrl: "./logout-page.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutPageComponent implements OnInit {

  private authenticationService = inject(AuthenticationService);
  private applicationStore = inject(ApplicationStoreService);
  private backgroundImageStore = inject(BackgroundImageStoreService);
  private router = inject(Router);

  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }

  ngOnInit(): void {
    this.authenticationService.userLogout().pipe(
      take(1)
    ).subscribe({
      next: (logout) => {
        this.applicationStore.setApplicationEvent(ApplicationEvent.Logout);
      }
    });
  }
}
