import { Component, OnInit } from "@angular/core";
import { CrwFlowSection, CrwPageComponent } from "../crw-page/crw-page.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { SideMenuCrwComponent } from "../../components/side-menu-crw/side-menu-crw.component";
import { LogoComponent } from "../../components/logo/logo.component";
import { RouterOutlet } from "@angular/router";
import { CardSubscriptionsComponent } from "../../cards/card-subscriptions/card-subscriptions.component";
import { CardSubscriptionYourComponent } from "../../cards/card-subscription-your/card-subscription-your.component";

@Component({
  selector: "app-crw-subscription",
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    SideMenuCrwComponent,
    LogoComponent,
    RouterOutlet,
    CardSubscriptionsComponent,
    CardSubscriptionYourComponent
  ],
  templateUrl: "./crw-subscription.component.html",
  styleUrl: "./crw-subscription.component.scss"
})
export class CrwSubscriptionComponent extends CrwPageComponent implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwStore.setCrwFlow(CrwFlowSection.Subscription);
  }
}
