import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EpgService } from '../../services/epg.service';
import { BeautifyDatePipe } from '../../pipes/beautify-date.pipe';
import { ConvertSecondsPipe } from '../../pipes/convert-seconds.pipe';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { TimelineLeftOffsetPipe } from '../../pipes/timeline-left-offset.pipe';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { LiveTimeStoreService } from '../../stores/live-time-store.service';
import { EpgDatesComponent } from '../epg-dates/epg-dates.component';
import { IntersectionObserverDirective } from '../../directives/intersection-observer.directive';
import { XperienceResponse } from '../../models/xperience-response';
import { ErrorService } from '../../services/error.service';
import { TimelineLeftOffsetChannelPipe } from '../../pipes/timeline-left-offset-channel.pipe';
import { EpgItemInterface } from '../../models/epg-item';
import { DestroyService } from '../../services/destroy.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MarkerReplayPipe } from '../../pipes/marker-replay.pipe';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { ItemCardComponent } from '../item-card/item-card.component';
import { DateEpgitemIsPastPipe } from '../../pipes/date-epgitem-is-past.pipe';
import { Q90ResponseLevels } from '../../models/q90-response';

const intersectionCallback = (entries) => {
  // console.log(entries);
};

interface ChannelEpgPageData {
  liveTimeSeconds: number;
  liveDate: Date;
  onTheHour: number;
}

interface ActiveItem {
  channelId: number;
  productId: number;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-channel-epg',
  templateUrl: './channel-epg.component.html',
  styleUrls: ['./channel-epg.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgStyle,
    RouterLink,
    DatePipe,
    ConvertSecondsPipe,
    BeautifyDatePipe,
    TimelineLeftOffsetPipe,
    AsyncPipe,
    EpgDatesComponent,
    IntersectionObserverDirective,
    TimelineLeftOffsetChannelPipe,
    SpinnerComponent,
    MarkerReplayPipe,
    ModalComponent,
    ItemCardComponent,
  ],
  providers: [DateEpgitemIsPastPipe],
})
export class ChannelEpgComponent implements OnInit, AfterViewChecked {
  @Input({ required: true }) playerIsReplaying: boolean;

  private epgService = inject(EpgService);
  channelEpgPageData$: Observable<ChannelEpgPageData>;

  private liveTimeStore = inject(LiveTimeStoreService);
  private errorService = inject(ErrorService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyed = inject(DestroyService);

  currentDate!: Date;
  todayDate!: Date;
  tomorrowDate!: Date;
  dates: Date[] = [];
  hoursAsSecondsScale!: number[];
  popUpOpen: boolean = false;

  gridLoading: WritableSignal<boolean> = signal(false);
  gridLoaded: WritableSignal<boolean> = signal(false);
  isToday: WritableSignal<boolean> = signal(true);
  liveTimeIndicatorIndex: WritableSignal<number> = signal(0);
  liveTimeIndicatorInitialPositionSet: WritableSignal<boolean> = signal(false);
  wasItNextOrPrevious: WritableSignal<'next' | 'previous' | null> = signal(null);

  channelId: number;
  epgItems: any[];
  liveItem: EpgItemInterface;
  private timelineIntersectionObserver: IntersectionObserver;
  activeItem: ActiveItem;

  @ViewChild('liveTime', { read: ElementRef }) liveTime: ElementRef;

  @ViewChild(IntersectionObserverDirective)
  set appIntersectionObserver(directive: IntersectionObserverDirective) {
    if (directive) this.timelineIntersectionObserver = new IntersectionObserver(intersectionCallback, directive.options);
  }

  constructor(private isPastPipe: DateEpgitemIsPastPipe) {
    this.dates = this.epgService.getEpgDateRange();
    this.hoursAsSecondsScale = this.liveTimeStore.hoursAsSecondsScale;
    this.currentDate = new Date();
    this.todayDate = new Date();
    this.todayDate.setDate(this.currentDate.getDate());
  }

  ngAfterViewChecked() {
    if (this.liveTime?.nativeElement && !this.liveTimeIndicatorInitialPositionSet()) {
      this.scrollCarousel();
      this.liveTimeIndicatorInitialPositionSet.set(true);
      this.timelineIntersectionObserver.observe(this.liveTime.nativeElement);
    }
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe({
      next: (params) => {
        this.channelId = params['channelId'];
      },
      complete: () => {
        this.setCurrentEpgDate(this.todayDate);
      },
    });

    this.liveTimeStore.onTheHour$
      .pipe(
        filter((onTheHour) => onTheHour !== null),
        takeUntil(this.destroyed),
      )
      .subscribe((onTheHour) => {
        this.getItemAtCurrentTime();
        this.scrollToLive();
      });

    this.channelEpgPageData$ = combineLatest([this.liveTimeStore.liveTimeSeconds$, this.liveTimeStore.liveDate$, this.liveTimeStore.onTheHour$]).pipe(
      map(([liveTimeSeconds, liveDate, onTheHour]) => {
        return {
          liveTimeSeconds,
          liveDate,
          onTheHour,
        };
      }),
    );
  }

  previousDay(e: Date): void {
    this.wasItNextOrPrevious.set('previous');
    this.setCurrentEpgDate(e);
  }

  nextDay(e: Date): void {
    this.wasItNextOrPrevious.set('next');
    this.setCurrentEpgDate(e);
  }

  private getEpg() {
    this.gridLoading.set(true);
    this.gridLoaded.set(false);
    this.epgService
      .getChannelEpg(this.channelId, this.epgService.getAsIsoString(this.currentDate), this.epgService.getAsIsoString(this.tomorrowDate))
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.epgItems = res.data.data;
            this.gridLoading.set(false);
            this.gridLoaded.set(true);
          } else {
            this.errorService.setError(new Error('Unable to load channel content at this time.'));
          }
        },
        complete: () => {
          this.getItemAtCurrentTime();
          this.scrollCarousel();
        },
      });
  }

  private setCurrentEpgDate(date: Date) {
    this.currentDate = date;
    this.tomorrowDate = new Date(this.currentDate);
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
    if (this.epgService.getAsIsoString(this.currentDate) === this.epgService.getAsIsoString(this.todayDate)) {
      this.isToday.set(true);
    } else {
      this.isToday.set(false);
    }
    this.getEpg();
  }

  private hasResults(): boolean {
    return this.epgItems.length > 0;
  }

  private scrollToLive() {
    this.liveTime?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  // private scrollToBeginning() {
  //   document.querySelector("div.item.first-item")?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "center"
  //   });
  // }

  private getItemAtCurrentTime() {
    this.liveItem = null;
    if (this.epgItems) {
      let today = new Date();
      this.epgItems.forEach((item, i) => {
        let starttime = new Date(item.startDate);
        let endtime = new Date(item.endDate);
        // Just comparing the time and not the actual day since we want to include live time indicator
        // on all epg days for scrolling to live purposes. If it is not today it is set to invisible.
        if (starttime.getHours() <= today.getHours() && endtime.getHours() > today.getHours()) {
          this.liveItem = item;
          this.liveTimeIndicatorIndex.set(i);
        }
      });
    }
  }

  private scrollCarousel(): void {
    if (this.wasItNextOrPrevious() === 'next') {
      document.querySelector('div.item.first-item')?.scrollIntoView();
    }
    if (this.wasItNextOrPrevious() === 'previous') {
      document.querySelector('div.item.last-item')?.scrollIntoView();
    }
    if (this.hasResults()) {
      this.scrollToLive();
    } else {
      // this.scrollToBeginning();
    }
  }

  closePopUp() {
    this.activeItem = null;
    this.popUpOpen = false;
  }

  setItem(item) {
    const isPast = this.isPastPipe.transform(item.endDate);
    if (isPast) {
      this.epgService
        .getChannelEpg(item.channelId)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            if (XperienceResponse.isSuccess(res)) {
              const id = item.mediaReplayId ? item.mediaReplayId : res.data.channelId;
              this.router.navigate([`/watch/${item.channelId}/${id}/restart${item.startDate}`]);
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorService.setError(error);
            }
          },
          error: (error) => {
            this.errorService.setError(error);
          },
        });
    } else {
      this.activeItem = item;
      this.popUpOpen = true;
    }
  }
}
