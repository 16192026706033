import { Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import flowplayer from '@flowplayer/player';
import AirplayPlugin from '@flowplayer/player/plugins/airplay';
// import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import Hls from 'hls.js';
import { MediaService } from '../../services/media.service';
import { ChannelEpgComponent } from '../../components/channel-epg/channel-epg.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { EpgDatesComponent } from '../../components/epg-dates/epg-dates.component';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { FrontendCookieStorageService } from '../../services/storage/frontend-cookie-storage.service';

@Component({
  selector: 'app-player-page',
  templateUrl: './player-page.component.html',
  styleUrls: ['./player-page.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, RouterLink, NgFor, ChannelEpgComponent, EpgDatesComponent],
})
export class PlayerPageComponent implements OnInit, OnDestroy {
  private applicationStoreService = inject(ApplicationStoreService);
  private mediaService = inject(MediaService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private cookieStorageService = inject(FrontendCookieStorageService);

  channelId = parseInt(this.route.snapshot.paramMap.get('channelId'));
  itemId = parseInt(this.route.snapshot.paramMap.get('itemId'));
  startDate = this.route.snapshot.paramMap.get('startDate');
  player: any;
  playerElm: any;
  engine: any;
  isPromo = true;
  playerIsReplaying: WritableSignal<boolean> = signal(false);
  hls = new Hls();
  streamToken = '';
  loadError = false;
  errorMsg = '';
  isLoading = true;
  isLive: WritableSignal<boolean> = signal(false);
  isPaused = true;
  uiVisible = false;
  mouseMoveTimer = null;
  menuQualityActive = false;
  menuSubtitlesActive = false;

  ngOnInit(): void {
    this.getSource();
  }

  ngOnDestroy(): void {
    this.disconnectChromecastSession();
    this.hls.stopLoad();
    this.hls.destroy();
    for (let i = 0; i < flowplayer.instances.length; i++) {
      flowplayer.instances[i].destroy();
    }
  }

  initializeFlowplayer(src) {
    this.player = flowplayer('#player', {
      token:
        'eyJraWQiOiJkelNpMlZwclhWbUoiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJkelNpMlZwclhWbUpcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.fytqi1Y3g_R-OP6PqXq9QwsbipWdGVsyKIjENcqFjj9fZsnr37oueUff3d0vM_-PknAZtQIDPfGNYF4gREwrcg',
      muted: localStorage.getItem('flowplayer/mute') === 'true',
      live: this.isLive(),
      controls: true,
      seekable: true,
      // chromecast: {
      //   app: (ChromecastPlugin as any).apps.STABLE,
      // },
      src: src,
      seconds_to_dvr: 30,
    });

    this.playerElm = document.getElementById('xperience-player');
    this.engine = <HTMLVideoElement>this.playerElm.querySelector('.fp-engine');
    this.setHls(src, this.engine);
    this.startPlayback();
  }

  setChromeCast() {
    if (!this.applicationStoreService.getLoadedChromeCast().value) {
      flowplayer(AirplayPlugin);
      // flowplayer(ChromecastPlugin, AirplayPlugin);
      this.applicationStoreService.setLoadedChromeCast(true);
    }
  }

  disconnectChromecastSession() {
    const cast = window['cast'];
    if (cast) {
      const castContext = cast.framework.CastContext.getInstance();

      if (castContext.getCastState() !== cast.framework.CastState.NO_DEVICES_AVAILABLE) {
        // End the current session
        castContext.endCurrentSession(true); // `true` will also stop the media on Chromecast
        console.log('Chromecast session disconnected');
      } else {
        console.log('No active Chromecast session to disconnect');
      }
    }
  }

  setPlayerSource(src) {
    this.isLive.set(this.startDate && this.startDate == 'live' ? true : false);

    this.setChromeCast();
    if (this.player) {
      this.updateFlowplayerSource(src);
    } else {
      this.initializeFlowplayer(src);
    }
  }

  updateFlowplayerSource(src) {
    if (this.player) {
      this.player.load({
        sources: src,
      });
      this.player.live = this.isLive();
      this.setHls(src, this.engine);
      this.startPlayback();
    }
  }

  async reloadChromeCastSource(src) {
    const cast = window['cast'];
    if (cast) {
      const castContext = cast.framework.CastContext.getInstance();
      const castSession = castContext.getCurrentSession();
      if (castSession) {
        const mediaInfo = new chrome.cast.media.MediaInfo(src, 'application/vnd.apple.mpegurl');
        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        request.currentTime = 0;
        castSession.loadMedia(request).then(
          () => {
            console.log('Chromecast media successfully updated');
          },
          (error) => {
            console.log('Error updating Chromecast media', error);
          },
        );
      }
    }
  }

  startPlayback() {
    this.addEvents();
    this.isLoading = false;
    if (this.startDate != 'live') {
      this.player.currentTime = 0.1;
    }
    // Only works when user allready interacted with the page, else shows play button
    const playPromise = this.player.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  }

  setHls(src, engine) {
    if (engine.canPlayType('application/vnd.apple.mpegURL') && navigator.userAgent.toLowerCase().indexOf('android') == -1) {
      engine.setAttribute('src', src);
      engine.setAttribute('type', 'application/vnd.apple.mpegurl');
    } else {
      this.hls.detachMedia();
      this.hls.loadSource(src);
      this.hls.attachMedia(engine);
      this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
        this.reloadChromeCastSource(src);
      });
    }
  }

  async getSource() {
    return await this.route.url.subscribe(async (val) => {
      this.channelId = parseInt(this.route.snapshot.paramMap.get('channelId'));
      this.itemId = parseInt(this.route.snapshot.paramMap.get('itemId'));
      this.startDate = this.route.snapshot.paramMap.get('startDate');
      if (val[0].path === 'promo') {
        return await this.getPromo();
      } else {
        this.isPromo = false;
        if ((this.startDate && this.startDate == 'live') || (this.startDate && this.startDate.includes('restart'))) {
          return await this.getLivestream();
        } else {
          this.playerIsReplaying.set(true);
          return await this.getReplayStream();
        }
      }
    });
  }

  async getLivestream() {
    let livestreamUrl = await this.getStreamSource();

    if (this.startDate == 'live') {
      this.setPlayerSource(livestreamUrl);
    } else if (this.startDate.includes('restart')) {
      let restartUrl = await this.getRestartSource();
      this.setPlayerSource(restartUrl);
    }
  }

  async getReplayStream() {
    let streamUrl = await this.getStreamSource();
    this.setPlayerSource(streamUrl);
  }

  async getPromo() {
    // 862268 70s
    // 862266 60s
    // 862264 192tv algemeen eng
    // 862262 192tv algemeen nl

    // Check if promo should be loaded in dutch or english
    let promoId = this.cookieStorageService.getItem('language') === 'nl' ? 862262 : 862264;
    let promoSrc = await this.getPromoSource(promoId);
    this.setPlayerSource(promoSrc);
  }

  getStreamSource(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mediaService.getMediaUrl(this.itemId).subscribe(
        (res) => {
          if (res.statusCode === 200) {
            if (res.data.streamToken) this.streamToken = res.data.streamToken;
            resolve(res.data.url);
            this.loadError = false;
            this.errorMsg = '';
          } else {
            this.loadError = true;
            this.errorMsg = res.resultMessages[0].message;
          }
        },
        (err) => {
          this.loadError = true;
          reject();
        },
      );
    });
  }

  getPromoSource(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mediaService.getPromoUrl(id).subscribe(
        (res) => {
          if (res.statusCode === 200) {
            resolve(res.data.url);
          } else {
            this.loadError = true;
          }
        },
        (err) => {
          this.loadError = true;
          reject();
        },
      );
    });
  }

  getRestartSource(): Promise<any> {
    return new Promise((resolve, reject) => {
      const startDate = this.startDate.replace('restart', '');
      this.mediaService.getMediaUrlWithStartTime(this.itemId, startDate).subscribe(
        (res) => {
          if (res.statusCode === 200) {
            this.streamToken = res.data.streamToken;
            resolve(res.data.url);
            this.loadError = false;
            this.errorMsg = '';
          } else {
            this.loadError = true;
            this.errorMsg = res.resultMessages[0].message;
          }
        },
        (err) => {
          this.loadError = true;
          reject();
        },
      );
    });
  }

  setMouseMoveTimer() {
    if (this.mouseMoveTimer) {
      clearTimeout(this.mouseMoveTimer);
    }
    this.mouseMoveTimer = setTimeout(() => {
      if (!this.menuQualityActive && !this.menuSubtitlesActive) {
        this.uiVisible = false;
      }
    }, 7000);
  }

  addEvents() {
    this.engine.addEventListener('playing', () => {
      this.isPaused = false;
    });

    this.engine.addEventListener('pause', () => {
      this.isPaused = true;
    });
  }

  setQuality(i) {
    this.hls.currentLevel = i;
  }

  goBack() {
    this.router.navigate(['/']);
  }
}
