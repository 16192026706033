<ng-container>
  <div class="card grid-xpmd text-center std-ratio">
    <h4 class="my-0">
      {{ msg() }}
    </h4>
    <p>
      <a i18n [routerLink]="['/login']">Ga verder naar de inlogpagina</a>
    </p>
  </div>
</ng-container>
