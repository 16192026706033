<ng-container *ngIf="cardEmailData$ | async as cardEmailData">
  <div class="card">
    <ng-container *ngIf="editMode()">
      <form
        (click)="formClicked($event)"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit($event)">
        <header>
          <h3 i18n>Mijn e-mailadres</h3>
        </header>
        <fieldset formGroupName="emailChange">
          <shared-form-email
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_EMAIL"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'emailChange.email'">
          </shared-form-email>
          <shared-form-email-confirm
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_EMAIL_CONFIRM"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'emailChange.emailAddressConfirm'">
          </shared-form-email-confirm>
          <shared-form-password
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_PASSWORD"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'emailChange.password'" />
        </fieldset>
        <footer>
          <div class="button-bar align-left">
            <shared-form-submit
              [buttonLabel]="LABEL_BUTTON_SAVE"
              [buttonClass]="'is-colored'"
              [spinnerColorMode]="'light'"
              [showLabelOnSubmit]="false"
              [pristine]="formGroup.pristine"
              [submitting]="submitting()" />
            <button
              i18n
              type="button"
              class="is-transparent"
              (click)="editMode.set(false)">
              Annuleren
            </button>
          </div>
          <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
        </footer>
      </form>
    </ng-container>

    <ng-container *ngIf="!editMode()">
      <section>
        <header>
          <h3 i18n>Mijn e-mailadres</h3>
          <p>{{ cardEmailData.userAccount?.emailAddress }}</p>
        </header>
        <div class="button-bar align-left">
          <button
            i18n
            class="is-colored"
            (click)="editMode.set(true)">
            E-mailadres wijzigen
          </button>
        </div>
      </section>
    </ng-container>
  </div>
</ng-container>
