import {Component, OnInit, inject} from '@angular/core';
import {Observable, combineLatest, map} from 'rxjs';
import {ProfileInterface} from '../../models/profile.model';
import {ProfilesStoreService} from '../../stores/profiles-store.service';
import {LogoPipe} from '../../pipes/logo.pipe';
import {CardEditProfileComponent} from '../../cards/card-edit-profile/card-edit-profile.component';
import {RouterLink} from '@angular/router';
import {NgIf, AsyncPipe} from '@angular/common';
import {LogoComponent} from "../../components/logo/logo.component";

interface EditProfileData {
  profiles: ProfileInterface[];
};

@Component({
  selector: 'app-edit-profile-page',
  templateUrl: './edit-profile-page.component.html',
  styleUrls: ['./edit-profile-page.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, CardEditProfileComponent, AsyncPipe, LogoPipe, LogoComponent]
})
export class EditProfilePageComponent implements OnInit {

  private profilesStore = inject(ProfilesStoreService);

  editProfileData$: Observable<EditProfileData>;

  constructor() {
  }

  ngOnInit() {
    this.editProfileData$ = combineLatest([
      this.profilesStore.userProfiles$
    ]).pipe(
      map(
        ([profiles]) => {
          return {
            profiles
          }
        }
      )
    );

  }

}
