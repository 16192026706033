import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
  WritableSignal
} from "@angular/core";
import { RouterLink } from "@angular/router";
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { combineLatest, interval, map, Observable, Subscription, take, takeUntil } from "rxjs";
import { GeneralStoreService } from "../../stores/general-store.service";
import { BannerSlideInterface } from "../../models/banner-slide";
import { DestroyService } from "../../services/destroy.service";
import { SpinnerComponent } from "../spinner/spinner.component";

interface BannerData {
  darkMode: boolean;
}

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgFor, NgStyle, NgIf, RouterLink, AsyncPipe, SpinnerComponent]
})
export class BannerComponent implements OnInit, OnChanges {

  LABEL_BANNER_NOW_ON_TV: string = $localize`Nu op TV`;

  interval$: Observable<number> = interval(10000);
  intervalSubscription!: Subscription;
  bannerData$: Observable<BannerData>;

  activeSlide: WritableSignal<number> = signal(0);
  updating: WritableSignal<boolean> = signal(false);

  @Input({ required: true }) loading: boolean;
  @Input({ required: true }) bannerSlides: BannerSlideInterface[] = [];
  @Input({ required: true }) epgType: "multi" | "single" = "multi";

  private generalStore = inject(GeneralStoreService);
  private destroyed = inject(DestroyService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bannerSlides']) {
      // console.log('banners changed. Stopping updating...');
      this.stopUpdating();
      if (changes['bannerSlides'].currentValue.length > 1) {
        // console.log('banners, more than 1. Starting updating...');
        this.startUpdating();
      }
    }
  }

  startUpdating(): void {
    this.updating.set(true);
    this.intervalSubscription = this.interval$.pipe(
      takeUntil(this.destroyed)
    ).subscribe((bannerCount) => {
      this.updateActiveSlide();
    });
  }

  stopUpdating(): void {
    this.updating.set(false);
    if (this.intervalSubscription instanceof Subscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.bannerData$ = combineLatest(
      [
        this.generalStore.darkMode$,
      ]).pipe(
        map(([darkMode]) => {
          return {
            darkMode
          };
        })
      );
  }

  updateActiveSlide() {
    if (this.bannerSlides.length > 1) {
      this.activeSlide.update(
        (activeSlide) => (activeSlide === this.bannerSlides.length - 1 ? 0 : activeSlide + 1));
      this.updating.set(false);
      interval(10)
        .pipe(take(1))
        .subscribe({
          next: (emits) => {
            this.updating.set(true);
          }
        });
    } else {
      this.activeSlide.set(0);
    }
  }
}
