import { Injectable, computed, inject } from '@angular/core';
import { GeneralStoreService } from '../stores/general-store.service';
import { ProfilesStoreService } from '../stores/profiles-store.service';
import { UserAccountStoreService } from '../stores/user-account-store.service';
import { FrontendCookieStorageService } from './storage/frontend-cookie-storage.service';

export const defaultLanguageHeader = "en-EN";

@Injectable({
  providedIn: 'root'
})
export class LanguageHeaderService {

  private generalStore = inject(GeneralStoreService);
  private profilesStore = inject(ProfilesStoreService);
  private userAccountStore = inject(UserAccountStoreService);
  private languageCookie = inject(FrontendCookieStorageService)

  /**
   * Removing profile language from resulting language calculation for now.
   * May be desired again later that language can be changed at profile
   * level...
   */
  uiLanguage = computed(() => {
    const defaultLanguage = this.generalStore.defaultLanguage();
    const userAccountLanguage = this.userAccountStore.userAccountLanguage();
    const profileLanguage = this.profilesStore.profileLanguage();
    // const result = (profileLanguage || userAccountLanguage || defaultLanguage);
    const result = (userAccountLanguage || defaultLanguage);
    if (result)
      this.languageCookie.setItem('language', result.isO639_1_Code);
    return result;
  });

  constructor() { }

  getLanguageHeader(): string {
    if (this.uiLanguage()) {
      return this.uiLanguage().ietfCode;
    } else {
      return defaultLanguageHeader;
    }
  }
}
