<shared-modal
  *ngIf="createOpen"
  [withCloseButton]="false"
  (closeModal)="cookieDisclaimerClosed()">
  <section>
    <header>
      <div class="grid-layout grid-cols-2 align-center spread q90-divider">
        <h3 i18n>Cookie Disclaimer</h3>
      </div>
    </header>
    <p i18n>We gebruiken de disclaimertekst voor cookies hier. Accepteer je?</p>
    <footer>
      <div class="button-bar align-left">
        <button
          i18n
          class="is-colored"
          type="button"
          (click)="cookieDisclaimerAgreed($event)">
          Accepteer
        </button>
        <button
          i18n
          type="button"
          class="is-transparent"
          (click)="cookieDisclaimerClosed()">
          Nee, weigeren
        </button>
        <button
          i18n
          type="button"
          class="is-transparent"
          routerLink="/info/cookie-policy">
          Meer informatie
        </button>
      </div>
    </footer>
  </section>
</shared-modal>
<!-- routerLink="/info/cookie-policy" -->
