import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../spinner/spinner.component';

@Component({
  selector: 'shared-form-submit',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitComponent implements OnInit, OnChanges {
  @Input() pristine!: boolean;
  @Input() submitting: boolean = false;
  @Input() buttonLabel: string = 'save';
  @Input() buttonClass: string = '';
  @Input() spinnerColorMode: 'light' | 'dark' | 'default' = 'default';
  @Input() showLabelOnSubmit: boolean = true;
  @Input() isDisabled: boolean = false;

  buttonClassFinal: string = '';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pristine']?.currentValue === false || this.submitting) {
      this.buttonClassFinal = this.buttonClass + ' action';
    } else {
      this.buttonClassFinal = this.buttonClass;
    }
  }
}
