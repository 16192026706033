import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, combineLatest, map, take } from 'rxjs';
import { FormComponent } from '../../components/forms/components/form/form.component';
import { Q90Response, Q90ResponseLevels } from '../../models/q90-response';
import { XperienceResponse } from '../../models/xperience-response';
import { CrwFlowSection } from '../../pages/crw-page/crw-page.component';
import { UserAccountService } from '../../services/user-account.service';
import { GeneralStoreService } from '../../stores/general-store.service';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ErrorMessagesComponent } from '../../components/error-messages/error-messages.component';
import { FormEmailConfirmComponent } from '../../components/forms/components/control/form-email-confirm/form-email-confirm.component';
import { FormEmailComponent } from '../../components/forms/components/control/form-email/form-email.component';
import { FormInputComponent } from '../../components/forms/components/control/form-input/form-input.component';
import { FormPasswordConfirmComponent } from '../../components/forms/components/control/form-password-confirm/form-password-confirm.component';
import { FormPasswordComponent } from '../../components/forms/components/control/form-password/form-password.component';
import { FormSelectComponent } from '../../components/forms/components/control/form-select/form-select.component';
import { FormUsernameComponent } from '../../components/forms/components/control/form-username/form-username.component';
import { SubmitComponent } from '../../components/forms/components/submit/submit.component';
import { AutocompleteOffDirective } from '../../components/forms/directives/autocomplete-off.directive';
import { GetFormControlPipe } from '../../components/forms/pipes/get-form-control.pipe';
import { ErrorMessagesStoreService } from '../../stores/error-messages-store.service';
import { CrwStoreService } from '../../stores/crw-store.service';

interface CrwFlowData {
  crwFlowSection: CrwFlowSection;
}

@Component({
  selector: 'app-card-register-activate',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    AutocompleteOffDirective,
    ErrorMessagesComponent,
    FormInputComponent,
    FormEmailComponent,
    FormEmailConfirmComponent,
    GetFormControlPipe,
    ReactiveFormsModule,
    RouterLink,
    SubmitComponent,
    FormPasswordComponent,
    FormPasswordConfirmComponent,
    FormUsernameComponent,
    FormSelectComponent,
    CardRegisterActivateComponent,
  ],
  providers: [{ provide: ErrorMessagesStoreService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './card-register-activate.component.html',
  styleUrl: './card-register-activate.component.scss',
})
export class CardRegisterActivateComponent extends FormComponent implements OnInit {
  @Output() onShowLogin = new EventEmitter<boolean>();
  @Output() onGoBack = new EventEmitter<boolean>();

  private crwStore = inject(CrwStoreService);
  private router = inject(Router);

  crwFlowData$!: Observable<CrwFlowData>;
  accountActivated: WritableSignal<boolean> = signal(false);

  private userAccountService = inject(UserAccountService);
  private generalStore = inject(GeneralStoreService);

  override formGroup = this.formBuilder.group({
    activateAccount: this.formBuilder.group({
      activationKey: this.formBuilder.control<string>('', [Validators.required]),
    }),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwFlowData$ = combineLatest([this.crwStore.crwSection$]).pipe(
      map(([crwFlowSection]) => {
        return {
          crwFlowSection,
        };
      }),
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const activateControls = this.formGroup.get('activateAccount') as FormGroup;
    if (this.submittable(activateControls)) {
      this.submitting.set(true);
      this.userAccountService
        .activateAccount(activateControls.value.activationKey)
        .pipe(take(1))
        .subscribe((res) => {
          this.submitting.set(false);
          if (XperienceResponse.isSuccess(res)) {
            // this.applicationStore.setApplicationEvent(ApplicationEvent.UserAccountUpdate);
            this.accountActivated.set(true);
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Success);
            this.errorMessagesStore.setErrorMessage(error);
            this.router.navigateByUrl('/login?accountActivated=true');
          } else {
            const error = new XperienceResponse().deserialize(res);
            error.setLevel(Q90ResponseLevels.Danger);
            this.errorMessagesStore.setErrorMessage(error);
          }
        });
    }
  }
  goBack() {
    this.onGoBack.emit(true);
  }
}
