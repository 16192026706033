import { Directive, ElementRef, OnInit, Renderer2, inject } from '@angular/core';

@Directive({
  selector: 'form',
  standalone: true
})
export class AutocompleteOffDirective implements OnInit {

  private renderer = inject(Renderer2);

  constructor(private formElement: ElementRef) {
  }

  ngOnInit(): void {
    this.renderer.setAttribute(this.formElement.nativeElement, 'autocomplete', 'off');
  }

}
