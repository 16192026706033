export enum ApplicationSettingName {
  EpgScaleInMinutes = 'EpgScaleInMinutes',
  EpgDaysFuture = 'EpgDaysFuture',
  EpgDaysPast = 'EpgDaysPast',
  StylePrimaryHex = 'StylePrimaryHex',
  StylePrimaryRgb = 'StylePrimaryRgb',
  StyleSecondaryHex = 'StyleSecondaryHex',
  StyleSecondaryRgb = 'StyleSecondaryRgb',
  styleColorMode = 'styleColorMode',
  StyleDarkMode = 'StyleDarkMode',
  StyleDarkModeForced = 'StyleDarkModeForced',
  StylePosterTv = 'StylePosterTv',
  StyleFontRegularFamily = 'StyleFontRegularFamily',
  StyleFontRegularWeight = 'StyleFontRegularWeight',
  StyleFontHeadingsFamily = 'StyleFontHeadingsFamily',
  StyleFontHeadingsWeight = 'StyleFontHeadingsWeight',
  StyleFontTitlesHeading = 'StyleFontTitlesHeading',
  StyleFontTitlesWeight = 'StyleFontTitlesWeight',
}

export interface ApplicationSettingInterface {
   settingId: number;
   name: string;
   dataType: number;
   value: string;
}
