<div class="card">
  <ng-container *ngIf="editMode()">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <header>
        <h3
          i18n
          class="title">
          Mijn Wachtwoord
        </h3>
        <p
          i18n
          class="subtitle">
          Uw nieuwe wachtwoord moet 8 tot 24 tekens lang zijn bestaande uit minimaal 1 hoofdletter, 1 kleine letter en 1 speciaal/numeriek teken.
        </p>
      </header>
      <fieldset formGroupName="passwordChange">
        <shared-form-password
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_NEW_PASSWORD"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'passwordChange.password'" />
        <shared-form-password-confirm
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_NEW_PASSWORD_CONFIRM"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'passwordChange.passwordConfirm'" />
      </fieldset>
      <footer>
        <div class="button-bar align-left">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_SAVE"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
          <button
            i18n
            type="button"
            class="is-transparent"
            (click)="editMode.set(false)">
            Annuleren
          </button>
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </ng-container>

  <ng-container *ngIf="!editMode()">
    <header>
      <h3 i18n>Mijn Wachtwoord</h3>
    </header>
    <div class="button-bar align-left">
      <button
        i18n
        class="is-colored"
        (click)="editMode.set(true)">
        Verander wachtwoord
      </button>
    </div>
  </ng-container>
</div>
