import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { iif, of, switchMap, tap } from "rxjs";
import { ApplicationService } from '../services/application.service';
import { UserAccountStoreService } from '../stores/user-account-store.service';

export const userIsNotLoggedInGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const userAccountStore = inject(UserAccountStoreService);
  const applicationService = inject(ApplicationService);
  return userAccountStore.isLoggedIn$.pipe(
    tap((profile) => applicationService.debug(`${route} using guard userIsNotLoggedInGuard`)),
    switchMap(isLoggedIn => iif(
      () => !isLoggedIn,
      of(true),
      of(router.createUrlTree(['']))
    )),
  )
};
