import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { UserAccountService } from "../../services/user-account.service";
import { NgClass } from "@angular/common";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AutocompleteOffDirective } from "../../components/forms/directives/autocomplete-off.directive";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { FormPasswordComponent } from "../../components/forms/components/control/form-password/form-password.component";
import {
  FormPasswordConfirmComponent
} from "../../components/forms/components/control/form-password-confirm/form-password-confirm.component";
import { Q90Response, Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";
import { take } from "rxjs";

@Component({
  selector: "app-card-reset-password",
  templateUrl: "./card-reset-password.component.html",
  styleUrls: ["./card-reset-password.component.scss"],
  standalone: true,
  imports: [
    RouterLink, NgClass, AutocompleteOffDirective, ErrorMessagesComponent, FormPasswordComponent,
    FormPasswordConfirmComponent,
    GetFormControlPipe, ReactiveFormsModule, SubmitComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardResetPasswordComponent extends FormComponent implements OnInit {

  private resetKey: string = "";

  private userAccountService = inject(UserAccountService);
  private route = inject(ActivatedRoute);

  override formGroup = this.formBuilder.group({
    changePassword: this.formBuilder.group({
      password: this.formBuilder.control<string>("", [Validators.required]),
      passwordConfirm: this.formBuilder.control<string>("", [Validators.required])
    })
  });

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.route.queryParams.pipe(
      take(1)
    ).subscribe({
      next: (params) => {
        this.resetKey = params["key"];
      }
    });
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const changePasswordControls = this.formGroup.get("changePassword") as FormGroup;
    if (this.submittable(changePasswordControls)) {
      this.submitting.set(true);
      this.userAccountService.resetPassword(this.resetKey, changePasswordControls.value.password)
        .pipe(
          take(1)
        ).subscribe({
          next: (res) => {
            this.submitting.set(false);
            if (XperienceResponse.isSuccess(res)) {
              this.setFormMessage(this.translations.getTranslation(this.translations.FORM_PASSWORD_RESET_SUCCESS), Q90ResponseLevels.Success);
              this.formGroup.reset();
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            this.formGroup.markAsPristine();
          }
        });
    }
  }
}
