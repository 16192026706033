import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  public FORM_HAS_ERRORS = $localize`Het formulier bevat fouten. Corrigeer deze en dien het opnieuw in.`;
  public FORM_HAS_NOT_BEEN_CHANGED = $localize`Het formulier is niet gewijzigd.`;
  public FORM_CHANGES_SAVE_SUCCESS = $localize`Uw wijzigingen zijn opgeslagen.`;
  public FORM_EMAIL_NOT_CONFIRMED = $localize`E-mailadres kon niet worden bevestigd.`;
  public FORM_EMAIL_IS_SENT = $localize`Uw e-mail is succesvol verzonden.`;
  public FORM_PASSWORD_RESET_SENT = $localize`Verzoek om wachtwoord te resetten is verzonden.`;
  public FORM_PINCODE_RESET_SENT = $localize`Verzoek om toegangscode te resetten is verzonden.`;
  public FORM_PAIRING_DEVICE_SUCCESS = $localize`Koppeling is geslaagd.`;
  public FORM_PASSWORD_RESET_SUCCESS = $localize`Het wachtwoord is succesvol gereset.`;
  public FORM_PINCODE_RESET_SUCCESS = $localize`De toegangscode is succesvol gereset.`;
  public FORM_SUBSCRIPTION_CANCEL_SUCCESS = $localize`Abonnement is succesvol opgezegd.`;

  constructor() {}

  getTranslation(translation: string): string {
    return translation;
  }
}
