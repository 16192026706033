import { Component, inject, Input, OnInit } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { LogoPipe } from "../../pipes/logo.pipe";
import { RouterLink } from "@angular/router";
import { GeneralStoreService } from "../../stores/general-store.service";

interface LogoData {
  darkMode: boolean;
}

@Component({
  selector: "app-logo",
  standalone: true,
  imports: [
    AsyncPipe,
    LogoPipe,
    NgIf,
    RouterLink,
    NgClass
  ],
  templateUrl: "./logo.component.html",
  styleUrl: "./logo.component.scss"
})
export class LogoComponent implements OnInit {

  @Input() forceDarkMode: boolean = false;
  @Input() forceLightMode: boolean = false;
  @Input() size: 'topbar' | 'profiles' | 'bg' = 'topbar';

  private generalStore = inject(GeneralStoreService);

  logoData$: Observable<LogoData>;

  constructor() {
  }

  ngOnInit() {
    this.logoData$ = combineLatest([
      this.generalStore.darkMode$
    ]).pipe(
      map(
        ([darkModeTmp]) => {
          const darkMode = this.forceDarkMode || darkModeTmp && !this.forceLightMode;
          return {
            darkMode
          };
        }
      )
    );
  }
}
