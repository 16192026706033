import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Output,
  SecurityContext,
  signal,
  WritableSignal
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormInputFileComponent } from "../form-input-file/form-input-file.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GetFormControlPipe } from "../../../pipes/get-form-control.pipe";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { AvatarComponent } from "../../../../avatar/avatar.component";
import { SpinnerComponent } from "../../../../spinner/spinner.component";

interface FormAvatarData {
  loadingPreview: boolean;
  previewing: boolean;
}

@Component({
  selector: "shared-form-avatar",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe,
    AvatarComponent,
    SpinnerComponent
  ],
  templateUrl: "./form-avatar.component.html",
  styleUrls: ["./form-avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormAvatarComponent extends FormInputFileComponent {

  public avatar!: File;
  public avatarUrl!: string;

  private sanitizer = inject(DomSanitizer);

  formAvatarData$!: Observable<FormAvatarData>;

  private loadingPreviewStore = new BehaviorSubject<boolean>(false);
  loadingPreview$: Observable<boolean> = this.loadingPreviewStore.asObservable();

  private previewingStore = new BehaviorSubject<boolean>(false);
  previewing$: Observable<boolean> = this.previewingStore.asObservable();

  @Output() avatarSelected = new EventEmitter<boolean>();

  person: any = input(null);
  hasAvatar: WritableSignal<boolean> = signal(false);

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.hasAvatar.set(!!this.person().avatar);
    this.formAvatarData$ = combineLatest([
      this.loadingPreview$,
      this.previewing$
    ]).pipe(
      map(([loadingPreview, previewing]) => {
        return {
          loadingPreview, previewing
        };
      })
    );
  }

  override onUploadsInputChange(e: Event): void {
    super.onUploadsInputChange(e);
    this.avatarSelected.emit(true);
    this.previewingStore.next(true);
    this.avatar = this.uploads[0] as File;
    this.formGroup.get("avatar.avatar")?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onloadstart = (evt) => {
      this.loadingPreviewStore.next(true);
    };
    reader.onload = (evt) => {
      const boo = evt.target?.result;
      this.avatarUrl = this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(boo as string)) as string;
    };
    reader.onloadend = (evt) => {
      this.loadingPreviewStore.next(false);
    };
    reader.readAsDataURL(this.avatar);
  }

  override removeFile(e: Event): void {
    super.removeFile(e);
    this.control.patchValue(null);
    this.hasAvatar.set(false);
    this.previewingStore.next(false);
  }

}
