import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressSpinnerModule } from "primeng/progressspinner";

@Component({
  selector: "shared-spinner",
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

  @Input() size: "x-sm" | "sm" | "md" | "lg" = "sm";
  @Input() colorMode: "light" | "dark" | "default" = "default";

}
