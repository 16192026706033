import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateBetweenDates',
    standalone: true
})

export class DateBetweenDatesPipe implements PipeTransform {

  transform(date, dateStart, dateEnd) {

    date      = new Date(date);
    dateStart = new Date(dateStart);
    dateEnd   = new Date(dateEnd);

    let dateT      =      date.getTime();
    let dateStartT = dateStart.getTime();
    let dateEndT   =   dateEnd.getTime();

    return dateT >= dateStartT && dateT <= dateEndT;

  }

}