<ng-container [formGroup]="formGroup">
  <div class="form-control {{styleClass}} {{additionalStyleClasses}}" [ngClass]="{'hide-errors-hints':!(showErrorHints)}">
    <label for="{{id}}" [ngClass]="{'visually-hidden': !showLabel, 'disabled': isDisabled}">{{label}}</label>
    <ng-container *ngIf="maxNumberOfFiles && maxNumberOfFiles > 1; else singleUpload">
      <input id="{{id}}" type="file" multiple (change)="onUploadsInputChange($event)" [formControl]="control | getAsFormControl">
    </ng-container>
    <ng-template #singleUpload>
      <input id="{{id}}" type="file" (change)="onUploadsInputChange($event)" [formControl]="control | getAsFormControl">
    </ng-template>
    <div class="submit-warning" [ngClass]="{'invalid-after-submit': submitted && control.invalid}"><span class='mci-warning'></span></div>
  </div>
  <div class="form-errors {{styleClass}}" *ngIf="showErrorHints && control.invalid && (submitted || control.touched)">
    <p>{{getErrorMessage()}}</p>
  </div>
</ng-container>
