import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { DestroyService } from './destroy.service';
import { FrontendLocalStorageService } from './storage/frontend-local-storage.service';
import { LocalStorageKeys } from './application.service';

@Injectable({
  providedIn: 'root',
})
export class CookieDisclaimerService {
  private cookieDisclaimerAgreedStorage: WritableSignal<number> = signal(0);
  public cookieDisclaimerAgreed: Signal<boolean> = computed(() => (this.cookieDisclaimerAgreedStorage() > 0 ? true : false));

  private destroyed = inject(DestroyService);
  private router = inject(Router);
  private localStorageService = inject(FrontendLocalStorageService);

  constructor() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroyed),
      )
      .subscribe((routerEvent) => {
        const cookieDisclaimerValueInStorage = this.localStorageService.getItem(LocalStorageKeys.CookieDisclaimer);
        this.cookieDisclaimerAgreedStorage.set(+cookieDisclaimerValueInStorage);
      });
  }
}
