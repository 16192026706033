import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { FormControlComponent } from "../form-control.component";
import { GetFormControlPipe } from "../../../pipes/get-form-control.pipe";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";

@Component({
  selector: "shared-form-email-confirm",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe
  ],
  templateUrl: "./form-email-confirm.component.html",
  styleUrls: ["./form-email-confirm.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEmailConfirmComponent extends FormControlComponent implements OnInit {

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.control.addValidators([
      Validators.email,
      RxwebValidators.compare({ fieldName: "email" })
    ]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError("email")) {
      return "This email format is incorreect.";
    }
    if (this.control.hasError("compare")) {
      return "The values for both emails do not match.";
    }
    return super.getErrorMessage();
  }

}
