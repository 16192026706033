<div class="grid grid-cols-6 gap-x-vertical-base grow">
  <section class="col-span-6 md:col-span-1">
    <app-side-menu-extra />
  </section>
  <div class="grid grid-cols-5 col-span-6 md:col-span-5 gap-x-vertical-base content-start">
    <section class="col-span-6">
      <h2 i18n>Cookie Beleid</h2>
      <div class="card">
        <ng-container *ngIf="cookiePageData$ | async as cookiePageData; else spinner">
          @if (cookiePageData.content) {
            <p [innerHTML]="cookiePageData.content?.text"></p>
          } @else {
            <p i18n>Sorry. Er is momenteel geen tekst beschikbaar.</p>
          }
        </ng-container>
      </div>
    </section>
  </div>
</div>
<ng-template #spinner>
  <div class="grid place-items-center">
    <shared-spinner />
  </div>
</ng-template>
