import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { UserAccountService } from "../../services/user-account.service";
import { combineLatest, map, Observable, take } from "rxjs";
import { ApplicationEvent, ApplicationService } from "../../services/application.service";
import { GeneralStoreService } from "../../stores/general-store.service";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { Q90Response, Q90ResponseLevels } from "../../models/q90-response";
import { XperienceResponse } from "../../models/xperience-response";
import { FormPasswordComponent } from "../../components/forms/components/control/form-password/form-password.component";
import { ApplicationStoreService } from "../../stores/application-store.service";
import { AccountInterface } from "../../models/account.model";
import { AutocompleteOffDirective } from "../../components/forms/directives/autocomplete-off.directive";
import { FormEmailComponent } from "../../components/forms/components/control/form-email/form-email.component";
import {
  FormEmailConfirmComponent
} from "../../components/forms/components/control/form-email-confirm/form-email-confirm.component";

interface CardEmailData {
  userAccount: AccountInterface;
}

@Component({
  selector: "app-card-email",
  templateUrl: "./card-email.component.html",
  styleUrls: ["./card-email.component.scss"],
  standalone: true,
  imports: [
    NgIf, NgClass, AsyncPipe, ErrorMessagesComponent,
    GetFormControlPipe, ReactiveFormsModule, SubmitComponent, FormPasswordComponent, AutocompleteOffDirective,
    FormEmailComponent, FormEmailConfirmComponent
  ],
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardEmailComponent extends FormComponent implements OnInit {

  editMode: WritableSignal<boolean> = signal(false);

  private applicationService = inject(ApplicationService);
  private applicationStore = inject(ApplicationStoreService);
  private userAccountService = inject(UserAccountService);
  private userAccountStore = inject(UserAccountStoreService);
  private generalStore = inject(GeneralStoreService);

  cardEmailData$: Observable<CardEmailData>;

  override formGroup = this.formBuilder.group({
    emailChange: this.formBuilder.group({
      email: this.formBuilder.control<string>("", [Validators.required]),
      emailAddressConfirm: this.formBuilder.control<string>("", [Validators.required]),
      password: this.formBuilder.control<string>("", [Validators.required])
    })
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.cardEmailData$ = combineLatest([
      this.userAccountStore.userAccount$
    ]).pipe(
      map(
        ([userAccount]) => {
          this.formGroup.get("emailChange.email").setValue(userAccount?.emailAddress);
          return {
            userAccount
          };
        }
      )
    );
  }

  override onSubmit(e: Event) {
    super.onSubmit(e);
    const emailChangeControls = this.formGroup.get("emailChange") as FormGroup;
    if (this.submittable(emailChangeControls)) {
      this.submitting.set(true);
      this.userAccountService.changeEmail(emailChangeControls.value.email, emailChangeControls.value.password)
        .pipe(
          take(1)
        ).subscribe({
          next: (res) => {
            this.submitting.set(false);
            if (XperienceResponse.isSuccess(res)) {
              // Could probably get away with just a call to updateUserAccount, but the response
              // email comes back in all caps, while the GET user account does not? Just calling
              // an app-wide UserAccountUpdate event instead, for now.
              // this.userAccountStore.updateUserAccount(res.data);
              this.applicationStore.setApplicationEvent(ApplicationEvent.UserAccountUpdate);
              this.setFormMessage(this.translations.getTranslation(this.translations.FORM_CHANGES_SAVE_SUCCESS), Q90ResponseLevels.Success);
            } else {
              const error = new XperienceResponse().deserialize(res);
              error.setLevel(Q90ResponseLevels.Danger);
              this.errorMessagesStore.setErrorMessage(error);
            }
          },
          complete: () => {
            this.formGroup.markAsPristine();
          }
        });
    }
  }
}
