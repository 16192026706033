<ng-container *ngIf="cardOverviewData$ | async as cardOverviewData">
  <div class="card">
    <div class="header">
      <h3
        i18n
        class="title">
        Overzicht
      </h3>
    </div>

    <p
      i18n
      class="subtitle">
      Het overzicht van uw aankoop.
    </p>

    <div class="details is-list">
      <div class="detail">
        <h5 i18n>Abonnement</h5>
        <p>{{ cardOverviewData.subscription?.name }}</p>
      </div>
      <div class="detail">
        <h5 i18n>Campagne</h5>
        <p>{{ cardOverviewData.subscription?.campaigns[0].description }}</p>
      </div>
      <div class="detail">
        <h5 i18n>Betaalmethode</h5>
        <p>{{ cardOverviewData.paymentMethod?.title }}</p>
      </div>
      <div class="detail">
        <h5 i18n>Prijs</h5>
        <p>€{{ cardOverviewData.subscription?.costs }}</p>
      </div>
    </div>

    <div class="buttons">
      <button
        i18n
        (click)="order()"
        class="is-colored">
        Bestelling afronden
      </button>
    </div>
  </div>
  <ng-container *ngIf="cardOverviewData.purchaseData">
    <div class="modal">
      <div class="modal-content card">
        <h5>{{ cardOverviewData.purchaseData.statusDescription }}</h5>
        <p class="subtitle">{{ cardOverviewData.purchaseData.product.name }}</p>
        <div class="buttons">
          <button
            i18n
            class="is-colored"
            (click)="checkStatus(cardOverviewData.purchaseData.status)">
            Doorgaan
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
