import { Component, inject, OnInit } from "@angular/core";
import { LogoPipe } from "../../pipes/logo.pipe";
import { SpinnerComponent } from "../spinner/spinner.component";
import { LogoComponent } from "../logo/logo.component";
import { Observable } from "rxjs";
import { ApplicationService } from "../../services/application.service";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  standalone: true,
  imports: [AsyncPipe, NgIf, NgClass, LogoPipe, SpinnerComponent, LogoComponent]
})

export class LoaderComponent implements OnInit {

  loader: Observable<boolean>;
  private applicationService = inject(ApplicationService);

  constructor() {
  }

  ngOnInit(): void {
    this.loader = this.applicationService.getAppLoadingStatus();
  }
}
