import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { switchMap, iif, of, tap } from "rxjs";
import { UserAccountStoreService } from "../stores/user-account-store.service";
import { ApplicationService } from "../services/application.service";

export const userHasSubscriptionGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const userAccountStore = inject(UserAccountStoreService);
  const applicationService = inject(ApplicationService);
  return userAccountStore.isLoggedIn$.pipe(
    tap((profile) => applicationService.debug(`${route} using guard userHasSubscriptionGuard`)),
    switchMap((isLoggedIn) =>
      iif(
        () => !isLoggedIn,
        of(router.createUrlTree(["/crw/account"])),
        userAccountStore.userAccountSubscriptions$.pipe(
          switchMap(
            (userAccountSubscription) => iif(
              () => userAccountSubscription?.length < 1,
              of(router.createUrlTree(["/account/subscription"])),
              of(true)
            ))
        )
      )
    )
  );
};
