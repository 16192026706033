<ng-container *ngIf="appComponentData$ | async as appComponentData; else loading">
  <app-topbar />
  <main [ngStyle]="{ 'background-image': 'url(' + appComponentData.backgroundImage + ')' }">
    <app-logo [size]="'bg'" />
    <router-outlet />
  </main>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
