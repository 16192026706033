<nav>
  <ul class="side-menu justify-center flex gap-x-std-half md:block">
    <li class="md:mb-vertical-base-half">
      <a
        i18n
        [routerLinkActive]="'is-active'"
        routerLink="/help/faq">
        Veel gestelde vragen
      </a>
    </li>
    <li class="md:mb-vertical-base-half">
      <a
        i18n
        [routerLinkActive]="'is-active'"
        routerLink="/help/contact">
        Contact
      </a>
    </li>
  </ul>
</nav>
