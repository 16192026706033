<ng-container *ngIf="cardContactData$ | async as cardContactData">
  <div class="card">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <header class="header">
        <h3 i18n>Contact</h3>
      </header>
      <fieldset>
        <shared-form-input
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_SUBJECT"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.subject'" />
      </fieldset>
      <fieldset
        class="naw-info"
        formGroupName="contact">
        <shared-form-input
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_FIRST_NAME"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.firstName'">
        </shared-form-input>
        <shared-form-input
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_LAST_NAME"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.lastName'">
        </shared-form-input>
        <shared-form-email
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_EMAIL"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.email'" />
        <shared-form-input
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_TELEPHONE"
          [showLabel]="false"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.phone'">
        </shared-form-input>
      </fieldset>
      <fieldset>
        <shared-form-textarea
          [styleClass]="'xperience'"
          [submitted]="submitted"
          [label]="LABEL_MESSAGE"
          [showLabel]="true"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'contact.message'" />
      </fieldset>
      <footer>
        <div class="button-bar align-left">
          <shared-form-submit
            [buttonLabel]="LABEL_BUTTON_SEND"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
          <!-- <button
            type="button"
            class="is-transparent"
            (click)="editMode.set(false)">
            Cancel
          </button> -->
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </div>
</ng-container>
