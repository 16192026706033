<ng-container [formGroup]="formGroup">
  <div
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }"
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}">
    <div class="form-label flex-layout">
      <label
        [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
        for="{{ id }}"
        >{{ label }}</label
      >
      <ng-content></ng-content>
    </div>
    <input
      [attr.placeholder]="showPlaceholder ? placeholder + '&#8230;' : null"
      [formControl]="control | getAsFormControl"
      autocomplete="one-time-code"
      type="{{ showPassword ? 'text' : 'password' }}" />
    <button
      type="button"
      tabindex="-1"
      class="is-transparent password-toggle"
      (click)="showPassword = !showPassword">
      <span [ngClass]="showPassword ? 'mci-eye-close-solid' : 'mci-eye-open-solid'"></span>
    </button>
    <div
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }"
      class="submit-warning">
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)"
    class="form-errors {{ styleClass }}">
    <p>{{ getErrorMessage() }}</p>
  </div>
</ng-container>
