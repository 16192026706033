import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, combineLatest, map, Observable, take } from "rxjs";
import { environment } from "../../environments/environment";
import { GeneralStoreService } from "../stores/general-store.service";
import { ApplicationSettingName } from "../models/application-setting.model";
import { AuthenticationStoreService } from "../stores/authentication-store.service";
import { Q90ResponseData } from "../interfaces/q90-response";
import { ChannelGridEpgItemsInterface } from "../models/channel-grid-epg-items";
import { EpgItemInterface } from "../models/epg-item";
import { XperienceResponse } from "../models/xperience-response";
import { ErrorService } from "./error.service";
import { ChannelEpgItemsInterface } from "../models/channel-epg-items";

@Injectable({
  providedIn: "root"
})
export class EpgService {

  private generalStore = inject(GeneralStoreService);
  private errorService = inject(ErrorService);
  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);

  constructor() {
  }

  checkDateInCurrentDateRange(checkDate: Date): number {
    const dateRange = this.getEpgDateRange(true);
    const clonedDate = new Date();
    clonedDate.setDate(checkDate.getDate());
    clonedDate.setHours(0, 0, 0, 0);
    const result = (dateRange.findIndex(entry => entry.getDate() === clonedDate.getDate()));
    return result;

  }

  /**
   * Format that appears to be needed for calls to back-end...
   *
   * @param date
   * @param withZeroTime
   * @param withMilliseconds
   */
  getAsIsoString(date: Date, withZeroTime: boolean = true, withMilliseconds: boolean = false): string {
    let tmp: Date = new Date(date.getTime());
    if (withZeroTime) {
      tmp.setHours(0, 0, 0, 0);
    }
    const offset = tmp.getTimezoneOffset();
    const tmpFinal = new Date(tmp.getTime() - (offset * 60 * 1000));
    if (withMilliseconds) {
      return tmpFinal.toISOString();
    } else {
      return tmpFinal.toISOString().split(".")[0];
    }
  }

  getItemDurationHours(item: EpgItemInterface): number {
    const start = new Date(item.startDate);
    const end = new Date(item.endDate);
    return (((end.getTime() - start.getTime()) / 1000) / (60 * 60));
  }

  getSecondsTotal(date: Date): number {
    return (date.getHours() * 3600) + (date.getMinutes() * 60) + date.getSeconds();
  }

  /**
   * Returns the array of Date objects containing today and the past
   * and future dates as set in the admin via application settings.
   *
   * @returns Date[]
   */
  getEpgDateRange(zeroTime: boolean = false): Date[] {
    let result: Date[] = [];
    combineLatest([
      this.generalStore.getApplicationSetting(ApplicationSettingName.EpgDaysPast),
      this.generalStore.getApplicationSetting(ApplicationSettingName.EpgDaysFuture)
    ]).pipe(
      take(1)
    ).subscribe(
      ([epgDaysPast, epgDaysFuture]) => {
        const now: Date = new Date();
        const past: Date = new Date();
        const future: Date = new Date();
        past.setDate(now.getDate() - Number(epgDaysPast.value));
        future.setDate(now.getDate() + Number(epgDaysFuture.value));
        result = this.getDateRange(past, future, zeroTime);
      }
    );
    return result;
  }

  private getDateRange(currentDate: Date, endDate: Date, zeroTime: boolean = false): Date[] {
    const dates: Date[] = [];
    do {
      // Need to clone the currentDate to fake immutability...
      const tmp = new Date(currentDate.getTime());
      if (zeroTime) {
        tmp.setHours(0, 0, 0, 0);
      }
      dates.push(tmp);
      currentDate.setDate(currentDate.getDate() + 1);
    } while (currentDate.getTime() <= endDate.getTime());
    return dates;
  }

  getChannelEpg(channelId: number, startDate: string = "", endDate: string = ""): Observable<Q90ResponseData<ChannelEpgItemsInterface>> {
    return this.http.get<Q90ResponseData<ChannelEpgItemsInterface>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/epg/channel/${channelId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: new HttpHeaders().set(
          "Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  getChannelGridEpg(startDate: string, endDate: string): Observable<Q90ResponseData<ChannelGridEpgItemsInterface[]>> {
    return this.http.get<Q90ResponseData<ChannelGridEpgItemsInterface[]>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/epg/grid?startDate=${startDate}&endDate=${endDate}&channelType=OTTTVChannels%2C%20OTTRadioChannels`,
      {
        headers: new HttpHeaders().set(
          "Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  getEpgItem(mainProductId: number, startDate: string): Observable<Q90ResponseData<EpgItemInterface>> {
    return this.http.get<Q90ResponseData<EpgItemInterface>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/epg/${mainProductId}/start/${startDate}`,
      {
        headers: new HttpHeaders().set(
          "Authorization", "Bearer " + this.authenticationStore.getToken())
      }
    ).pipe(
      map(res => {
        if (!XperienceResponse.isSuccess(res)) {
          this.errorService.setError(new XperienceResponse().deserialize(res));
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }
}
