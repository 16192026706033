import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ApplicationEvent } from '../../services/application.service';
import { ApplicationStoreService } from '../../stores/application-store.service';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-account-deleted-page',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './account-deleted-page.component.html',
  styleUrl: './account-deleted-page.component.scss'
})
export class AccountDeletedPageComponent implements OnInit {

  private applicationStore = inject(ApplicationStoreService);
  private backgroundImageStore = inject(BackgroundImageStoreService);

  constructor() {
    this.backgroundImageStore.setBackgroundImage("background-192tv.png");
  }

  ngOnInit(): void {
    this.applicationStore.setApplicationEvent(ApplicationEvent.Logout);
  }
}
