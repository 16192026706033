import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormComponent } from "../../components/forms/components/form/form.component";
import { ErrorMessagesStoreService } from "../../stores/error-messages-store.service";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { ErrorMessagesComponent } from "../../components/error-messages/error-messages.component";
import { FormPasswordComponent } from "../../components/forms/components/control/form-password/form-password.component";
import { FormUsernameComponent } from "../../components/forms/components/control/form-username/form-username.component";
import { GetFormControlPipe } from "../../components/forms/pipes/get-form-control.pipe";
import { PaginatorModule } from "primeng/paginator";
import { RouterLink } from "@angular/router";
import { SubmitComponent } from "../../components/forms/components/submit/submit.component";
import { NgClass } from "@angular/common";
import {
  FormRadioGroupComponent
} from "../../components/forms/components/control/form-radio-group/form-radio-group.component";

@Component({
  selector: "app-card-selection",
  templateUrl: "./card-selection.component.html",
  styleUrls: ["./card-selection.component.scss"],
  standalone: true,
  providers: [
    { provide: ErrorMessagesStoreService }
  ],
  imports: [
    ErrorMessagesComponent,
    FormPasswordComponent,
    FormUsernameComponent,
    GetFormControlPipe,
    PaginatorModule,
    ReactiveFormsModule,
    RouterLink,
    SubmitComponent,
    NgClass,
    FormRadioGroupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardSelectionComponent extends FormComponent implements OnInit {

  override formGroup = this.formBuilder.group({
    selection: this.formBuilder.group({
      option: this.formBuilder.control<number>(0, [Validators.required])
    })
  });

  constructor() {
    super();
  }

  get options() {
    return [{ "register": "Register" }, { "tvod": "Buy or Rent" }, { "svod": "Subscription" }];
  }

  setSelection() {

  }

}
