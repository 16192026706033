import { Component, OnInit } from "@angular/core";
import { CrwFlowSection, CrwPageComponent } from "../crw-page/crw-page.component";
import { CardPaymentMethodsComponent } from "../../cards/card-payment-methods/card-payment-methods.component";

@Component({
  selector: "app-crw-payment",
  standalone: true,
  imports: [
    CardPaymentMethodsComponent
  ],
  templateUrl: "./crw-payment.component.html",
  styleUrl: "./crw-payment.component.scss"
})
export class CrwPaymentComponent extends CrwPageComponent implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
    this.crwStore.setCrwFlow(CrwFlowSection.Payment);
  }

}
