import { Injectable } from '@angular/core';
import { PrimeableService } from './primeable.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends PrimeableService {
  constructor() {
    super();
  }

  prime(): void { }

  authenticated(): void { }

  refresh(): void { }

  logout(): void { }

  redeemVoucher(productId: number, actionCode: string, channelIdOfSubscription: number, mainProductIdOfProduct: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/transactions/voucher`,
      {
        productId: productId,
        actionCode: actionCode,
        channelIdOfSubscription: channelIdOfSubscription,
      },
      {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + this.authenticationStore.getToken())

          .set('x-language', 'nl-NL'),
      },
    );
  }

  getPaymentUrl(productId: number, method: string, returnUrl: string, cancelUrl: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/transactions/payment/url`,
      {
        productId: productId,
        method: method,
        returnUrl: returnUrl,
        cancelUrl: cancelUrl,
      },
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      },
    );
  }

  getPaymentSession(session: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/transactions/payment/${session}`, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
    });
  }

  endSubscription(productId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/subscriptions/${productId}/end`,
      { productId: productId },
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationStore.getToken()),
      },
    );
  }
}
