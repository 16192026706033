<ng-container *ngIf="crwFlowData$ | async as crwFlowData">
  @if (accountCreated()) {
    <app-card-register-activate (onGoBack)="accountCreated.set(false)" />
  } @else {
    <div class="card">
      <form
        (click)="formClicked($event)"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit($event)">
        <header>
          <div class="grid-xp grid-cols-2 spread align-center">
            <h3 i18n>Account aanmaken</h3>
            <button
              i18n
              type="button"
              class="is-transparent justify-right"
              (click)="onShowLogin.emit(true)">
              Heeft u al een account?
            </button>
          </div>
        </header>
        <fieldset formGroupName="register">
          <shared-form-username
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_USERNAME"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.username'" />
          <shared-form-email
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_EMAIL"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.email'" />
          <shared-form-email-confirm
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_NEW_EMAIL_CONFIRM"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.emailAddressConfirm'" />
          <shared-form-password
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_PASSWORD"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.password'" />
          <shared-form-password-confirm
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_PASSWORD_CONFIRM"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.passwordConfirm'" />
          <shared-form-input
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_FIRST_NAME"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.firstName'" />
          <shared-form-input
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_LAST_NAME"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.lastName'" />
          <shared-form-select
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_LANGUAGE"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'register.languageId'"
            [options]="languages">
          </shared-form-select>
          <div
            class="button-bar align-left"
            afterControlField>
            <button
              i18n
              afterControlField
              class="is-transparent"
              type="button"
              (click)="accountCreated.set(true)">
              Heeft u al een activatie code?
            </button>
          </div>
        </fieldset>
        <footer>
          <div class="button-bar align-left">
            <shared-form-submit
              [buttonLabel]="LABEL_BUTTON_CREATE_ACCOUNT"
              [buttonClass]="'is-colored'"
              [spinnerColorMode]="'light'"
              [showLabelOnSubmit]="false"
              [pristine]="formGroup.pristine"
              [submitting]="submitting()" />
          </div>
          <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
        </footer>
      </form>
    </div>
  }
</ng-container>
