<ng-container *ngIf="cardSubscriptionsData$ | async as cardSubscriptionsData">
  <div class="card">
    <form
      (click)="formClicked($event)"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)">
      <header>
        <h3 i18n>Abonnementen</h3>
      </header>
      @if (cardSubscriptionsData.subscriptions.length) {
        <div class="button-bar align-left">
          <button
            type="button"
            *ngFor="let option of cardSubscriptionsData.durations"
            (click)="changeSubscriptionDuration(option)"
            [ngClass]="{
              'is-active': chosenSubscriptionDuration()?.periodUnit === option.periodUnit
            }">
            {{ option | beautifyDuration }}
          </button>
        </div>
        <fieldset formGroupName="subscription">
          @for (subscription of cardSubscriptionsData.subscriptions; track subscription.productId) {
            @if (subscription.periodUnit === chosenSubscriptionDuration().periodUnit) {
              <shared-form-radio
                (click)="subscriptionChosen($event, subscription)"
                [styleClass]="'xperience grid-xp'"
                [id]="'subscription-' + subscription.productId"
                [radioName]="'productId'"
                [radioValue]="subscription.productId"
                [radioChecked]="(formGroup | getFormControl: 'subscription.productId').value == subscription.productId"
                [submitted]="submitted"
                [label]="subscription.name + ' (&euro; ' + subscription.costs + ')'"
                [formGroup]="formGroup"
                [showErrorHints]="false"
                [control]="formGroup | getFormControl: 'subscription.productId'"></shared-form-radio>
              <ul class="subscription-features">
                <li
                  class="usp"
                  [ngClass]="{ 'crossed-out': usp.value.toString() === 'false' }"
                  *ngFor="let usp of subscription.usps">
                  - {{ usp.name }} {{ sanitizeUspVal(usp.value) }}
                </li>
              </ul>
              <p
                class="my-0 smaller"
                *ngIf="subscription.description">
                *{{ subscription.description }}
              </p>
            }
          }
        </fieldset>
      } @else {
        <div class="grid justify-center mt-std">
          <shared-spinner />
        </div>
      }
      <footer>
        <div class="button-bar align-left">
          <shared-form-submit
            *ngIf="chosenSubscriptionInCart()?.length < 1"
            [buttonLabel]="LABEL_BUTTON_ADD_TO_CART"
            [buttonClass]="'is-colored'"
            [spinnerColorMode]="'light'"
            [showLabelOnSubmit]="false"
            [pristine]="formGroup.pristine"
            [submitting]="submitting()" />
          <button
            i18n
            *ngIf="chosenSubscriptionInCart()?.length > 0"
            type="button"
            class="is-colored"
            (click)="next(cardSubscriptionsData.crwSubCart)">
            Volgende
          </button>
        </div>
        <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
      </footer>
    </form>
  </div>
</ng-container>
