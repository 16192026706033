import { Component, WritableSignal, inject, signal } from '@angular/core';
import { CardLoginComponent } from '../../cards/card-login/card-login.component';
import { CommonModule } from '@angular/common';
import { LogoPipe } from '../../pipes/logo.pipe';
import { BackgroundImageStoreService } from '../../stores/background-image-store.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserAccountService } from '../../services/user-account.service';
import { take } from 'rxjs';
import { XperienceResponse } from '../../models/xperience-response';
import { Q90ResponseLevels } from '../../models/q90-response';
@Component({
  selector: 'app-activate-page',
  standalone: true,
  imports: [CommonModule, CardLoginComponent, LogoPipe, RouterLink],
  templateUrl: './activate-page.component.html',
  styleUrls: ['./activate-page.component.scss'],
})
export class ActivatePageComponent {
  private backgroundImageStore = inject(BackgroundImageStoreService);
  private route = inject(ActivatedRoute);
  protected userAccountService = inject(UserAccountService);
  private router = inject(Router);

  msg: WritableSignal<string> = signal('');

  constructor() {
    this.backgroundImageStore.setBackgroundImage('background-192tv.png');
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParamMap: any) => {
      const key = queryParamMap.params.key;
      this.userAccountService
        .activateAccount(key)
        .pipe(take(1))
        .subscribe((res) => {
          if (XperienceResponse.isSuccess(res)) {
            this.msg.set('Your account has been activated.');
            this.router.navigateByUrl('/login?accountActivated=true');
          } else {
            this.msg.set(res.resultMessages[0].message);
            // const error = new XperienceResponse().deserialize(res);
            // error.setLevel(Q90ResponseLevels.Danger);
            // this.errorMessagesStore.setErrorMessage(error);
          }
        });
    });
  }
}
