<ng-container *ngIf="bannerData$ | async as bannerData">
  <div
    [ngClass]="{ 'is-single': bannerSlides.length === 1, 'is-updating': updating(), 'dark-mode': bannerData.darkMode }"
    class="banner">
    @if (loading) {
      <div id="loader">
        <shared-spinner [colorMode]="'dark'" />
      </div>
    } @else {
      @if (bannerSlides.length < 1) {
        <h3
          id="no-programs"
          i18n>
          Er is op dit moment geen informatie beschikbaar in de gids
        </h3>
      } @else {
        <div class="slides">
          <div
            *ngFor="let slide of bannerSlides; let i = index"
            [ngClass]="{ 'is-active': i === activeSlide() }"
            [ngStyle]="{ 'background-image': slide.epgItem.largeVisual ? 'url(' + slide.epgItem.largeVisual + ')' : 'none' }"
            class="slide">
            <div
              class="info"
              [ngClass]="{ 'dark-mode': !bannerData.darkMode }"
              *ngIf="slide.image">
              <div class="thumbnail">
                <img
                  *ngIf="slide.image"
                  alt=""
                  class="logo"
                  src="{{ slide.image }}" />
              </div>
              <h6 *ngIf="LABEL_BANNER_NOW_ON_TV">{{ LABEL_BANNER_NOW_ON_TV }}</h6>
              <h1>{{ slide.epgItem.title }}</h1>
              <p *ngIf="slide.epgItem.description != ''">
                {{ slide.epgItem.description }}
              </p>

              <div class="button-bar align-left">
                <button
                  i18n
                  *ngIf="epgType === 'multi'"
                  [ngClass]="{ 'is-colored': true }"
                  [routerLink]="['/watch', slide.channel.mainProductId, slide.channel.channelId, 'live']">
                  <span class="mci-tv-solid"></span>
                  Kijken
                </button>
                <button
                  i18n
                  *ngIf="slide.epgItem.hasRestart && epgType === 'multi'"
                  [ngClass]="{ 'is-transparent': true }"
                  [routerLink]="['/watch', slide.channel.mainProductId, slide.channel.channelId, 'restart' + slide.epgItem.startDate]">
                  <span class="mci-rotate-left-solid"></span>
                  Kijk vanaf begin
                </button>
                <button
                  i18n
                  *ngIf="epgType === 'single'"
                  [ngClass]="{ 'is-colored': true }"
                  [routerLink]="['/watch', slide.channelId, slide.streamTokenOrChannelStreamId, 'live']">
                  <span class="mci-tv-solid"></span>
                  Kijken
                </button>
                <button
                  i18n
                  *ngIf="slide.epgItem.hasRestart && epgType === 'single'"
                  [ngClass]="{ 'is-transparent': true }"
                  [routerLink]="['/watch', slide.channelId, slide.streamTokenOrChannelStreamId, 'restart' + slide.epgItem.startDate]">
                  <span class="mci-rotate-left-solid"></span>
                  Kijk vanaf begin
                </button>
                <button
                  i18n
                  *ngIf="slide.epgItem.hasPromo"
                  [ngClass]="{ 'is-transparent': true }"
                  [routerLink]="['/promo']">
                  <span class="mci-video-solid"></span>
                  Promo
                </button>
                <button
                  i18n
                  [ngClass]="{ 'is-transparent': true }"
                  [routerLink]="['/item', slide.channelId, slide.epgItem.mainProductId, slide.epgItem.startDate]">
                  <span class="mci-information-circle-solid"></span>
                  Meer info
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    }
  </div>
</ng-container>
