<ng-container *ngIf="cardAccountData$ | async as cardAccountData">
  <div class="card">
    <ng-container *ngIf="editMode()">
      <form
        (click)="formClicked($event)"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit($event)">
        <header class="header">
          <h3 i18n>Mijn Account</h3>
        </header>
        <fieldset formGroupName="account">
          <shared-form-input
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_FIRST_NAME"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'account.firstName'">
          </shared-form-input>
          <shared-form-input
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_LAST_NAME"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'account.lastName'">
          </shared-form-input>
          <shared-form-select
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_LANGUAGE"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'account.languageId'"
            [options]="languages" />
          <shared-form-input-hidden
            [styleClass]="'xperience'"
            [submitted]="submitted"
            [label]="LABEL_NEWSLETTER"
            [showLabel]="false"
            [formGroup]="formGroup"
            [control]="formGroup | getFormControl: 'account.newsLetter'" />
        </fieldset>
        <footer>
          <div class="button-bar align-left">
            <shared-form-submit
              [buttonLabel]="LABEL_BUTTON_SAVE"
              [buttonClass]="'is-colored'"
              [spinnerColorMode]="'light'"
              [showLabelOnSubmit]="false"
              [pristine]="formGroup.pristine"
              [submitting]="submitting()" />
            <button
              i18n
              type="button"
              class="is-transparent"
              (click)="editMode.set(false)">
              Annuleren
            </button>
          </div>
          <shared-error-messages [errorMessagesStore]="errorMessagesStore"></shared-error-messages>
        </footer>
      </form>
    </ng-container>

    <ng-container *ngIf="!editMode() && cardAccountData.userAccount">
      <header>
        <h3 i18n>Mijn Account</h3>
      </header>
      <section class="grid-xp grid-cols-2 mt-1">
        <div>
          <h5 i18n>Naam</h5>
          <p>
            {{ cardAccountData.userAccount.firstName }}
            {{ cardAccountData.userAccount.lastName }}
          </p>
        </div>

        <div>
          <h5 i18n>Taal</h5>
          <p>{{ cardAccountData.userAccount.languageId | languageIdToName }}</p>
        </div>
      </section>

      <div class="button-bar align-left">
        <button
          i18n
          type="button"
          class="is-colored"
          (click)="editMode.set(true)">
          Informatie bewerken
        </button>
        <button
          i18n
          type="button"
          class="is-transparent"
          (click)="deleteModeRequested.emit(true)">
          Account verwijderen
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
