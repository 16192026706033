<ng-container *ngIf="submitting; else submitButton">
  <button
    class="submitting"
    type="button"
    [ngClass]="buttonClassFinal"
    disabled>
    <shared-spinner
      [size]="'x-sm'"
      [colorMode]="spinnerColorMode"></shared-spinner>
    <span *ngIf="showLabelOnSubmit">{{ buttonLabel | titlecase }}</span>
  </button>
</ng-container>
<ng-template #submitButton>
  <button
    i18n
    type="submit"
    [ngClass]="buttonClassFinal"
    [disabled]="isDisabled || this.pristine">
    {{ buttonLabel | titlecase }}
  </button>
</ng-template>
